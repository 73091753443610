<template>
  <div id="app">
        <div id="top-bar">
            <div class="container-fluid">
            <div class="row">
                <div class="col-4 col-md-3">
                <div class="logo-top"><h1><a href="#"><img src="../assets/img/homePage/logo.png" alt="logo"></a></h1></div>
                </div>
                <div class="col-8 col-md-9 menu-top">
                <div class="row">
                    <nav class="menu-des col-6 col-md-8">
                        <ul>
                            <li><a href="javascript:void(0)" @click="goto('main-home')">{{$t('home.menu.home')}}</a></li>
                            <!-- <li><a href="javascript:void(0)" @click="goto('main-home')">{{$t('home.menu.userCares')}}</a></li> -->
                            <li><a href="javascript:void(0)" @click="goto('our-services')">{{$t('home.menu.services')}}</a></li>
                            <li><a href="javascript:void(0)" @click="goto('key-benefits')">{{$t('home.menu.benefis')}}</a></li>
                            <li><a href="javascript:void(0)" @click="goto('contact-us')">{{$t('home.menu.contact')}}</a></li>
                            <!-- <li><a href="javascript:void(0)" @click="goto('main-home')">{{$t('home.menu.resources')}}</a></li> -->
                        </ul>
                    </nav>
                    <!-- End menu-des -->
                    <div class="hamberger-menu" @click="menuClick">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <div class="signin col-6 col-md-4">
                        <!-- a target="_blank" href="https://www.facebook.com/giaiphaptichhop" title="Facebook" v-b-tooltip.hover>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 167.657 167.657" style="enable-background:new 0 0 167.657 167.657;" xml:space="preserve"><path style="fill:#010002;" d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154C167.657,37.881,130.125,0.349,83.829,0.349z"/></svg>
                        </a -->
                        <h3><a href="javascript:void(0)" @click="redirectLink('/login')">{{$t('label.login')}}</a></h3>
                        <div class="language-home">
                            <a href="javascript:void(0)" value="vi" class="mr-2" @click="changeLocale('vi')"  title="TiếngViệt" v-b-tooltip.hover>
                                <img src="../assets/img/icons/ensign/vi.png" alt="" class="border" style="height: 20px;">
                            </a>
                            <a href="javascript:void(0)" value="en" class="mr-2" @click="changeLocale('en')" title="English
                            (United States)" v-b-tooltip.hover>
                                <img src="../assets/img/icons/ensign/en.png" alt="" class="border" style="height: 20px;">
                            </a>
                            <!-- <a href="javascript:void(0)" value="ja" class="mr-2">
                                <img src="../assets/img/icons/ensign/thailand.png" alt="" class="border" style="height: 20px;" title="ไทย" v-b-tooltip.hover>
                            </a> -->
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        </div>
        <!-- End menu-top -->
        <main id="main-home" ref="main-home">
            <div class="main-home--backgroud">
            <div class="banner-home">
                <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-6 banner-home--left">
                    <div>
                        <h2>{{$t('home.banner.title')}}</h2>
                        <p>{{$t('home.banner.desc')}}</p>
                        <p style="left: 0; width: 315px; height: 4px; background: #fff; border-radius: 2px;">{{$t('home.banner.slogan')}}</p>
                        <div class="form-groud">
                            <div class="row">
                                <div class="col-12  mt-5 mb-3">
                                    <input class="form-control" type="text" v-model="contentForm.name" :placeholder="$t('label.fullName')" required >
                                    <div class="error-message-home w-100 clear text-left">
                                        {{ msg['fullName'] ? $t(msg['fullName']) : ''  }}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <input class="form-control" type="number" v-model="contentForm.phone" :placeholder="$t('label.phoneNumber')" required >
                                    <div class="error-message-home w-100 clear text-left">
                                        {{ msg['phone'] ? $t(msg['phone']) : ''  }}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <input class="form-control" type="email" v-model="contentForm.email" placeholder="Email" required >
                                    <div class="error-message-home w-100 clear text-left">
                                        {{ msg['email'] ? $t(msg['email']) : ''  }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <textarea class="form-control" cols="30" rows="1" v-model="contentForm.desc" :placeholder="$t('label.moreInformation')"></textarea>
                                </div>
                                <div class="col-12 text-right">
                                     <button class="btn button-form-home" @click="submitFormHome">{{ $t("label.submitHome") }} <font-awesome-icon icon="paper-plane" class="mb-1 ml-2"/></button>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-6 banner-home--right">
                    <img src="../assets/img/homePage/face-demo-new.png" alt="">
                    </div>
                </div>
                </div>
            </div>
            <!-- End banner-home -->
            <div class="container">
                <div id="our-services" class="our-services" ref="our-services">
                <div class="title">
                    <h3>{{$t('home.ourServices.ourServices')}}</h3>
                    <p>{{$t('home.ourServices.desc')}}</p>
                </div>
                <div class="our-services--list">
                    <div class="our-services--item row">
                    <div class="col-12 col-md-5 our-services--item__img text-center"><img src="../assets/img/homePage/access.png" alt=""></div>
                    <div class="col-12 col-md-7 our-services--item__content">
                        <h4>{{$t('home.ourServices.accessControl')}}</h4>
                        <p>{{$t('home.ourServices.accessControlDesc')}}</p>
                    </div>
                    </div>
                    <div class="our-services--item row">
                    <div class="col-12 col-md-5 our-services--item__img text-center"><img src="../assets/img/homePage/time-attendance.png" alt=""></div>
                    <div class="col-12 col-md-7 our-services--item__content">
                        <h4>{{$t('home.ourServices.timeAtendance')}}</h4>
                        <p>{{$t('home.ourServices.timeAtendanceDesc')}}.</p>
                    </div>
                    </div>
                    <div class="our-services--item row">
                    <div class="col-12 col-md-5 our-services--item__img text-center"><img src="../assets/img/homePage/vms.png" alt=""></div>
                    <div class="col-12 col-md-7 our-services--item__content">
                        <h4>{{$t('home.ourServices.vmsSystem')}}</h4>
                        <p>{{$t('home.ourServices.vmsSystemDesc')}}.</p>
                    </div>
                    </div>
                    <div class="our-services--item row">
                    <div class="col-12 col-md-5 our-services--item__img text-center"><img src="../assets/img/homePage/analytics.png" alt=""></div>
                    <div class="col-12 col-md-7 our-services--item__content">
                        <h4>{{$t('home.ourServices.analytics')}}</h4>
                        <p>{{$t('home.ourServices.analyticsDesc')}}.</p>
                    </div>
                    </div>
                    <div class="our-services--item row">
                    <div class="col-12 col-md-5 our-services--item__img text-center"><img src="../assets/img/homePage/integration.png" alt=""></div>
                    <div class="col-12 col-md-7 our-services--item__content">
                        <h4>{{$t('home.ourServices.integration')}}</h4>
                        <p>{{$t('home.ourServices.integrationDesc')}}</p>
                    </div>
                    </div>
                </div>
                <!-- end .our-services--list -->
                </div>
                <!-- End our-services -->
                <div id="key-benefits" ref="key-benefits">
                <div class="title">
                    <h3>{{$t('home.keyBenefits.title')}}</h3>
                    <p>{{$t('home.keyBenefits.desc')}}</p>
                </div>
                <div class="row key-benefits--list">
                    <div class="col-12 col-md-4">
                    <div class="key-benefits--item">
                        <img src="../assets/img/homePage/affordable.jpg" alt="">
                        <h3>{{$t('home.keyBenefits.affordable')}}</h3>
                        <p>{{$t('home.keyBenefits.affordableDesc')}}</p>
                    </div>
                    </div>
                    <div class="col-12 col-md-4">
                    <div class="key-benefits--item">
                        <img src="../assets/img/homePage/multi.jpg" alt="">
                        <h3>{{$t('home.keyBenefits.mutiTenant')}}</h3>
                        <p>{{$t('home.keyBenefits.mutiTenantDesc')}}</p>
                    </div>
                    </div>
                    <div class="col-12 col-md-4">
                    <div class="key-benefits--item">
                        <img src="../assets/img/homePage/api.jpg" alt="">
                        <h3>{{$t('home.keyBenefits.apisDriver')}}</h3>
                        <p>{{$t('home.keyBenefits.apisDriverDesc')}}</p>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-5 offset-md-1">
                    <div class="key-benefits--item">
                        <img src="../assets/img/homePage/timeline.jpg" alt="">
                        <h3>{{$t('home.keyBenefits.analytics')}}</h3>
                        <p>{{$t('home.keyBenefits.analyticsDesc')}}</p>
                    </div>
                    </div>
                    <div class="col-12 col-md-5">
                    <div class="key-benefits--item">
                        <img src="../assets/img/homePage/cloud.jpg" alt="">
                        <h3>{{$t('home.keyBenefits.cloudService')}}</h3>
                        <p>{{$t('home.keyBenefits.cloudServiceDesc')}}</p>
                    </div>
                    </div>

                </div>
                </div><!-- End key-benefits -->
            </div>
            </div>
            <div id="contact-us" ref="contact-us">
                <div class="container-fluid">
                    <div class="title">
                        <h3>{{$t('home.contactUs.title')}}</h3>
                        <p>{{$t('home.contactUs.desc')}}</p>
                    </div>
                    <div class="title">
                        <h3>{{$t('home.contactUs.slogan')}}</h3>
                    </div>
                    <div class="row contact-us--list">
                        <div class="col-12 col-md-4">
                            <div class="contact-us--item">
                                <img src="../assets/img/homePage/map.jpg" alt="">
                                <div>
                                    <p>{{$t('home.contactUs.address')}}</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="contact-us--item">
                                <img src="../assets/img/homePage/call.jpg" alt="">
                                <div><p>Hotline: <a href="tel:1900.98.98.68">1900.98.98.68</a></p></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="contact-us--item">
                                <img src="../assets/img/homePage/email.jpg" alt="">
                                <div>
                                    <p>Email: congtrinhviettel@viettel.com.vn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- End #main-home -->
        <footer style="height:100px">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-5 offset-lg-1">Copyright © 2021 Viettel Construction</div>
                    <div class="col-12 col-md-6 col-lg-5 text-right">
                        <ul>
                            <li><a target="_blank" href="https://viettelconstruction.com.vn/dieu-khoan/">{{$t('home.termscondition')}}</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </footer>
    <div :class="mobiMenu ? 'menu-mobi active': 'menu-mobi'">
        <div class="menu-mobi1">
            <div class="menu-mobi-close" @click="menuClick">
                <i>&times;</i>
            </div>
            <nav class="main-menu-mobi">
                <ul>
                    <li><a href="javascript:void(0)" @click="gotoMobi('main-home')">{{$t('home.menu.home')}}</a></li>
                    <!-- <li><a href="javascript:void(0)" @click="gotoMobi('main-home')">{{$t('home.menu.userCares')}}</a></li> -->
                    <li><a href="javascript:void(0)" @click="gotoMobi('our-services')">{{$t('home.menu.services')}}</a></li>
                    <li><a href="javascript:void(0)" @click="gotoMobi('key-benefits')">{{$t('home.menu.benefis')}}</a></li>
                    <li><a href="javascript:void(0)" @click="gotoMobi('contact-us')">{{$t('home.menu.contact')}}</a></li>
                    <li><a href="javascript:void(0)" @click="redirectLink('/login')">{{$t('label.login')}}</a></li>
                    <!-- <li><a href="javascript:void(0)" @click="gotoMobi('main-home')">{{$t('home.menu.resources')}}</a></li> -->
                </ul>
            </nav>
            <div class="language-home language-home-mobi">
                <a href="javascript:void(0)" value="vi" class="mr-2" @click="changeLocale('vi')"  title="TiếngViệt" v-b-tooltip.hover>
                    <img src="../assets/img/icons/ensign/vi.png" alt="" class="border" style="height: 20px;">
                </a>
                <a href="javascript:void(0)" value="en" class="mr-2" @click="changeLocale('en')" title="English
                (United States)" v-b-tooltip.hover>
                    <img src="../assets/img/icons/ensign/en.png" alt="" class="border" style="height: 20px;">
                </a>
                <!-- <a href="javascript:void(0)" value="ja" class="mr-2">
                    <img src="../assets/img/icons/ensign/thailand.png" alt="" class="border" style="height: 20px;" title="ไทย" v-b-tooltip.hover>
                </a> -->
            </div>
        </div>
    <div class="menu-mobi2" @click="menuClick"></div>
    </div><!-- End menu-mobi -->
  </div>
</template>

<script>
import Vue from 'vue';
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
/*
import VueFbCustomerChat from 'vue-fb-customer-chat';
import VueGtag from "vue-gtag";
import VueFacebookPixel from 'vue-analytics-facebook-pixel';

Vue.use(VueFacebookPixel);
Vue.analytics.fbq.init('394031181958936', {
  em: 'anhlt185@viettel.com.vn'
});

Vue.use(VueGtag, {
  config: { id: "G-T50NGVZ8W1" }
});

Vue.use(VueFbCustomerChat, {
  page_id: 305390919979137,
  theme_color: '#EB1836',
  locale: 'en_US',
});
*/
/*
!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');

//fbq.disablePushState = true; //not recommended, but can be done

fbq('init', '394031181958936');
fbq('track', 'PageView');
*/

export default {
    data() {
        return {
            mobiMenu: false,
            contentForm:[],
            msg:[],
        }
    },
    mounted() {

    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            let header = document.querySelector("#top-bar");
            if (window.scrollY > 1 && !header.className.includes('fixmenu')) {
                header.classList.add('fixmenu');
            } else if (window.scrollY < 1) {
                header.classList.remove('fixmenu');
            }
        },

        goto(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo({ top: top - 30, behavior: 'smooth' });

        },

        redirectLink(link){
            this.$router.replace(link)
        },

        menuClick(){
            this.mobiMenu = !this.mobiMenu;
        },
        gotoMobi(refName){
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo({ top: top - 30, behavior: 'smooth' });
            this.menuClick()
        },
        changeLocale(locale) {
            this.$store.commit("updateLocale", locale)
            location.reload()
        },
        async submitFormHome(){
            let res;
            try {
                res = await apiServer.post('/api/v1/contact/add', {
                    fullName: this.contentForm.name ,
                    email:  this.contentForm.email,
                    phone:  this.contentForm.phone,
                    content:  this.contentForm.desc
                })
            } catch (error) {
                if (error.response.status === 400) {
                this.msg = (error.response.data)
                } else if (error.response.status === 401) {
                alert(this.$t(error.response.data))
                }
            }
            if (res && res.data) {
                if(res.status === 200) {
                this.$notify({
                    group: 'bottomnotification',
                    title: this.$t('label.notification'),
                    text: this.$t(res.data.message),
                    position:'bottom-center'
                });
                this.contentForm = [];
                this.msg = [];
                }
            }


        },

    }

}
</script>
<style scoped lang="scss">
html,body{overflow-x:hidden;scroll-behavior: smooth;}
body,div,ul,li,ol,dl,dt,dd,input,h1,h2,h3,h4,h5,h6,span,strong,img,p{margin:0;padding:0;border:none}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{font-weight: bold;}
ul,li,ol,dl,dt,dd{list-style:none}
figcaption{
    text-align: center;
    padding: 8px 15px;
    font-style: italic;
}
img,figure{max-width:100%;height: auto;}
.postContent img{height: auto;}
a {
    text-decoration: none;
    color: inherit;
}
a:hover, a:focus{
    text-decoration: none;
    color: #FB6340;
    outline: none;
}
body{
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    position: relative;
    color: #282828;
    line-height: 1.5;
    width: 100vw !important;
}
@media screen and (min-width: 1200px){
    .container{
        width: 1200px;
        max-width: 1200px;
        padding: 0 15px;
        margin:auto;
    }
}
.menu-mobi{display: none;}
#top-bar{
    position: fixed;
    padding: 0 80px;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 99;
    transition: .5s all;
}
#top-bar.fixmenu{
    background: #fff;
    box-shadow: 0px 3px 5px 0px #0000003d;
}
.logo-top{
    padding: 10px 0;
}
.logo-top h1{
   line-height: 0;
}

.logo-top img{
    max-width: 150px;
}
.menu-des{height: 100%;}
.menu-des ul{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}
.menu-des ul a{
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    margin-right: 10px;
    font-weight: bold;
}
.menu-des ul a:hover{
    background: #ffffff30;
}
.menu-top>*{
    height: 100%;
}
.menu-top .signin{
    padding: 10px 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.menu-top .signin>a{
    border-left: 1px solid #fff;
    padding: 0px 20px;
    color: #fff;
    font-size: 25px;
}
.menu-top .signin>a svg{
    width: 25px;
}
.menu-top .signin>a svg path{
    fill: #fff !important;
}
.menu-top .signin h3>a{
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #0000003b;
    font-size: 14px;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
}
#top-bar.fixmenu .menu-des ul a {
    color: #282828;
}

#top-bar.fixmenu .menu-top .signin>a {
    border-left: 1px solid #ccc;
    color: #1778F2;
}

#top-bar.fixmenu .menu-top .signin h3>a {
    background: #FB6340;
    color: #fff;
}
#top-bar.fixmenu .menu-top .signin>a svg path{
    fill: #1778F2 !important;
}
main#main-home .main-home--backgroud{
    background-image: url(../assets/img/homePage/features-section-bg.jpg);
    background-repeat: repeat-y;
    background-position: 50%;
}
.banner-home{
    background: rgb(234,243,250);
    background: -moz-linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    background: -webkit-linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    background: linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eaf3fa",endColorstr="#ef002e",GradientType=1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 80vh;
    border-bottom-right-radius: 50%!important;
    display: flex;
    align-items: center;
    padding: 50px 80px;
    overflow: hidden;
}
.banner-home--right img{
    width: 98%;
}
.banner-home--left{
    display: flex;
    align-items: center;
    color: #fff;
}
.banner-home--left h2{
    font-weight: bold;
    font-size: 30px;
    color:#fff;
    line-height: 2.5;
}
.banner-home--left p{
    font-size: 26px;
}
.banner-home--left a{
    display: inline-block;
    box-shadow: 0 3px 1px -2px #00000020;
    background: #1d458a;
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    padding: 8px 40px;
    border-radius: 8px;
    margin-top: 25px;
}
.banner-home--left a:hover{
    background: #fff;
    color: #FB6340;

}
.title{
    margin-bottom: 30px;
    text-align: center;
}
.title h3{
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 5px;
}
.title p{
    font-size: 16px;
}
.our-services{
    padding: 70px 0;
}
.our-services--item__img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.our-services--item__img img{
    max-height: 200px;
    width: auto;
}
.our-services--item__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    h4{
        color: #fff;
        font-size: 20px;
    }
}

.our-services--item{
    height: 250px;
    margin-bottom: 25px;
    color: #fff;
    box-shadow: 0 3px 1px -2px #00000020;
    border-radius: 5px;
    background: linear-gradient(to right, rgb(178, 69, 146), rgb(241, 95, 121));
}
.our-services--list>div:nth-child(2){
    background: linear-gradient(to right, rgb(127, 127, 213), rgb(145, 234, 228));
}
.our-services--list>div:nth-child(3){
    background: linear-gradient(to right, rgb(17, 153, 142), rgb(56, 239, 125));
}
.our-services--list>div:nth-child(4){
        background: linear-gradient(to right, rgb(86, 20, 176), rgb(168, 128, 243));
}
.our-services--list>div:nth-child(5){
        background: linear-gradient(to right, rgb(255, 95, 109), rgb(255, 195, 113));
}
#key-benefits{
    padding-top: 50px;
}
.key-benefits--item{
    background: #fff;
    padding: 25px;
}
.key-benefits--item h3{
    font-size: 22px;
    padding: 30px 0 10px;
    text-transform: uppercase;
}
#key-benefits{
    padding-bottom: 50px;
}
#contact-us{
    padding: 50px 0;
}
.contact-us--list{
    padding: 0 50px;
}
.contact-us--list>div{
    padding: 0 50px;
}
.contact-us--item{
    padding: 15px;
    background: #fff;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important;
}
.contact-us--item img{
    height: 300px;
    width: auto;
}
.contact-us--item>div{
    height: 90px;
}
footer{
    color: #fff;
    padding-top: 40px;
}
footer .text-right ul{
    display: flex;
    justify-content: flex-end;
}
footer .text-right ul li:first-child{
    margin-right: 20px;
}
.login-mo{
    display: none;
}
.language-home{
    margin-left: 20px;
}
.button-form-home{
    display: inline-block;
    box-shadow: 0 3px 1px -2px #00000020;
    background: #fff;
    color: #EF002E;
    font-size: 17px;
    text-transform: uppercase;
    padding: 8px 40px;
    border-radius: 8px;
    margin-top: 25px;

}
footer{
    background: rgb(234,243,250);
    background: -moz-linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    background: -webkit-linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    background: linear-gradient(135deg, rgba(234,243,250,1) 0%, rgba(239,0,46,1) 33%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eaf3fa",endColorstr="#ef002e",GradientType=1);
}
.error-message-home{
    color: #fcff01;
}



/*ipad-pro*/
@media screen and (min-width: 968px) and (max-width: 1110px){
    .menu-des ul a{
        font-size: 13px;
        padding: 5px 8px;
        margin-right:5px;
    }
    .menu-top .signin > a svg{
        width: 17px;
    }
    .menu-top .signin > a{
        padding: 0px 12px;
    }
    #top-bar .menu-top .signin h3 > a{
        border-radius: 13px;
        box-shadow: 0px 1px 4px 0px #0000003b;
        font-size: 7px;
        padding: 8px 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
    }
    #top-bar{
        padding: 0 15px;
    }
    .contact-us--item img {
        height: 150px;
        width: auto;
    }
    .contact-us--list > div{
        padding: 0 20px;
    }
    .key-benefits--item h3{
        font-size: 20px;
    }
    .banner-home--left h2{
        line-height: 1.4;
        margin-bottom: 30px;
    }
    .banner-home[data-v-fae5bece] {
        height: 60vh;
        padding: 0 30px;
    }


}
/* end-ipad-pro*/

/*ipad*/
@media screen and (min-width: 768px) and (max-width: 967px){
    .menu-des ul a{
        font-size: 10px;
        padding: 5px 8px;
        margin-right:5px;
    }
    .menu-top .signin > a svg{
        width: 17px;
    }
    .menu-top .signin > a{
        padding: 0px 12px;
    }
    #top-bar .menu-top .signin h3 > a{
        border-radius: 13px;
        box-shadow: 0px 1px 4px 0px #0000003b;
        font-size: 7px;
        padding: 8px 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
    }
    #top-bar{
        padding: 0 15px;
    }
    .contact-us--item img {
        height: 150px;
        width: auto;
    }
    .contact-us--list > div{
        padding: 0 5px;
    }
    .key-benefits--item h3{
        font-size: 20px;
    }
    .banner-home--left h2{
        line-height: 1.4;
        margin-bottom: 30px;
        font-size: 36px;
    }
    .banner-home--left p{
        font-size: 20px;
    }
    .banner-home{
        height: 60vh;
        padding: 0 30px;
        border-bottom-right-radius: 40% !important;
    }
    .contact-us--list{
        padding: 0 15px;
    }
    .key-benefits--item{
        padding: 7.5px;
    }


}
/*end-ipad*/
/*mobile*/
@media screen and (max-width: 767px){
    body{padding-bottom: 70px;}
    /* menu mobile */
    .menu-des{display: none;}
    .hamberger-menu{
        display: block;
    }
    .hamberger-menu span{
        width: 20px;
        height: 2px;
        margin-bottom: 2px;
        background: #fff;
        display: block;
    }
    .menu-mobi{
        display: flex;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        height: 100%;
        right: 100%;
        top: 0;
        transition: .5s all;
        background: #fff;
        z-index: 999999;
    }

    .menu-mobi.active{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        transition: .5s all;
        background: #EF002Eb0;
        z-index: 999999;
    }
    .menu-mobi1{
        width: 80%;
        background: #c50026a8;
    }
    .menu-mobi2{
        width: 19.8%;
        height: 100%;
    }
    .menu-mobi-close i{
        padding: 5px 13px;
        font-style: normal;
        background: #fff;
        color: var(--key-coler);
        font-size: 26px;
        text-align: center;
        line-height: 1.5;
    }
    .main-menu-mobi{
        margin: 30px 0;
    }

    .main-menu-mobi>ul>li{
        border-bottom: 1px solid #ef002e;

    }
    .main-menu-mobi ul li a{
        color: #fff;
        padding: 12px 15px;
        display: block;

    }

    .menu-mobi-sosial ul{

        display: flex;

    }
    .main-menu-mobi ul ul{
     padding-left: 15px;
     display: none;
 }
 .main-menu-mobi ul ul li{
    border-top:1px dotted #8DC63F;
}

.main-menu-mobi ul ul li a {
    padding-left: 0;
}
.main-menu-mobi ul>li.menu-item-has-children{
    position: relative;
}
.arrrow{
    position: absolute;
    content: "+";
    top: 5px;
    right: 5px;
    z-index: 99;
    padding: 3px 8px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
.menu-mobi-sosial img{
    max-width: 50px;
}
.menu-mobi-nd{padding:15px;color: #fff;margin-bottom: 30px;}
.menu-mobi-nd h3{font-size: 18px;margin-bottom: 15px;}
.arrrow {
    position: absolute;
    content: "+";
    top: 5px;
    right: 5px;
    z-index: 99;
    padding: 3px 8px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}
/* End menu mobile */
.signin{
    display: none !important;
}
#top-bar{
    padding: 0 15px;
}
.logo-top img{
    max-width: 100px;
    margin-left: -15px;
}
.menu-top > *{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
#top-bar.fixmenu .hamberger-menu span{
    background: #FB6340;
}
.banner-home{
        padding: 110px 0 30px;
    min-height: 80vh;
    height: auto;
    border-bottom-right-radius: 30% !important;
}
.banner-home--left h2{
    font-size: 26px;
    line-height: 1.4;
    margin-bottom: 10px;
}
.banner-home--left p{
    font-size: 18px;
    margin: auto;
}
.banner-home--left>div{
    text-align: center;
    margin-bottom: 30px;
}
.login-mo{
    display: block;
}
.banner-home--left h4 a{
    background: #fff ;
    color: #FB6340;
    padding: 8px 32px;
    margin-top: 10px;
    font-weight: normal;
}
.our-services--item {
    margin: 0 0 25px;
    height: auto;
    padding: 30px 0;
}
.our-services--item__img img{
    max-height: 150px;
    width: auto;
    margin-bottom: 15px;
}
.key-benefits--item{
    padding: 25px 0;
}
.contact-us--list{
    padding: 0;
}
.contact-us--list > div{
    padding: 0 15px;
    margin-bottom: 15px;
}
.contact-us--item img{
    height: 200px;
}
footer{
    color: #fff;
    padding-top: 25px;
}
footer .text-right ul{
            justify-content: flex-start;
    margin-top: 5px;
}
.banner-home .form-groud .text-right{
    text-align: center !important;
}





}
@media screen and (min-width: 375px) and (max-width: 413px){

}
</style>
