import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { FormSelectPlugin, ButtonPlugin, TablePlugin, PaginationPlugin, SpinnerPlugin, ModalPlugin, DropdownPlugin, CollapsePlugin, FormPlugin, FormGroupPlugin, FormInputPlugin, FormCheckboxPlugin, FormFilePlugin, TooltipPlugin } from 'bootstrap-vue';
import './assets/scss/nucleo/css/nucleo.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faCoffee, faUsers, faListAlt, faPlus, faCalendarAlt, faUser, faPowerOff, faInfoCircle, faClipboardList, faCalendarDay, faBuilding, faUserCog, faDownload, faUpload, faCheck, faTimes, faCheckCircle, faPaperPlane, faEdit, faHome, faTachometerAlt, faFileSignature, faClock, faBook, faCalendarCheck, faCalendarTimes, faFilter, faList, faTrash, faCalendar, faFile, faCaretRight, faCaretDown, faExchangeAlt, faDesktop, faCodeBranch, faSpinner, faFileInvoice, faFileAlt, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueApexCharts from 'vue-apexcharts'
import Notifications from 'vue-notification'
import vSelect from 'vue-select'
import ToggleButton from 'vue-js-toggle-button'
import "vue-select/src/scss/vue-select.scss"
import VueComp from '@vue/composition-api'



Vue.config.productionTip = false
library.add(faUserSecret);
library.add(faCoffee);
library.add(faUsers);
library.add(faUser);
library.add(faPlus);
library.add(faDownload);
library.add(faHome);
library.add(faUpload);
library.add(faEdit);
library.add(faInfoCircle);
library.add(faBook);
library.add(faList);
library.add(faTrash);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faTachometerAlt);
library.add(faClock);
library.add(faFile);
library.add(faExchangeAlt);
library.add(faDesktop);
library.add(faCalendar);
library.add(faCodeBranch);
library.add(faSpinner);
library.add(faFileInvoice);
library.add(faFileAlt);
library.add(faFilter);
library.add(faCalendarCheck);
library.add(faCalendarTimes);
library.add(faCog);
library.add(faFileSignature);
library.add(faPaperPlane);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faTimes);
library.add(faPowerOff);
library.add(faUserCog);
library.add(faBuilding);
library.add(faCalendarDay);
library.add(faClipboardList);
library.add(faCalendarAlt);
library.add(faListAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('apexchart', VueApexCharts)
Vue.use(FormSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(FormInputPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormFilePlugin);
Vue.use(TooltipPlugin);
Vue.use(VueApexCharts);
Vue.use(Notifications);
Vue.use(ToggleButton);
Vue.use(VueComp);
Vue.config.productionTip = false
Vue.component('v-select', vSelect);


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')