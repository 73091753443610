<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <!-- <div class="card col-12">
      <div class="row info-user">
        <div class="col-12 col-md-9 ml-2 info-user-desc">
          <div style="width: 100%">
            <div class="info-user-desc--title">
              <h2>{{ nameUser }}</h2>
              <p style="margin-bottom: 0px">Tình trạng kết nối: <span class=" text-success"> Đang kết nối </span> </p>
              <p style="margin-bottom: 0px">
                IP: 192.168.101.30 {{ phoneNumberUser }}
              </p>
              <p style="margin-bottom: 0px">
                Tên máy: Máy MAIN {{ companyUser }}
              </p>
              <p>Loại máy chấm công: Ra {{ branchName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-12  col-xs-12">
              <div class="d-flex justify-content-between">
                <h1 class="page-title mt-2">
                  Chi tiết thiết bị
                </h1>
                <div class="text-right m-3" v-if="userType == '2'">
                  <!-- <button class="btn btn-primary mr-2" @click="downloadFileTemplate()">
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button> -->
                  <b-form-file style="width: auto; text-align: left;" :placeholder="'Đồng bộ vân tay từ thiết bị'"
                    drop-placeholder="Drop file here..." accept=".xlsx, .xls, .dat" ref="file"></b-form-file>
                  <b-button variant="btn btn-primary ml-2" @click="importCheckin()">{{ $t('label.import') }}
                  </b-button>
                </div>
              </div>
              <div class="info-user-desc--title">
                <!-- <h2>{{ nameUser }}</h2> -->
                <p style="margin-bottom: 0px">
                  Tên máy: {{ device_name }}
                </p>
                <p style="margin-bottom: 0px">Tình trạng kết nối: <span v-if="ConnectStatus == 1" class=" text-success">
                    Hoạt động </span>
                  <span v-if="ConnectStatus == 0" class=" text-danger"> Mất kết nối </span>
                </p>
                <p style="margin-bottom: 0px">
                  IP: {{ IP }}
                </p>

                <p v-if="device_type == 3" style="margin-bottom: 0px">Loại máy chấm công: VÀO / RA</p>
                <p v-if="device_type == 4" style="margin-bottom: 0px">Loại máy chấm công: VÀO</p>
                <p v-if="device_type == 5" style="margin-bottom: 0px">Loại máy chấm công: RA</p>
                <p>
                  {{ $t("label.totalNumberOfEmployeesInDevice") }} {{ totalEmployees }}
                </p>
              </div>
            </div>
            <!-- <div class="col-md-6 col-xs-12 text-right" v-if="userType == '2'">
              <div class="mt-4">
                <a
                  href="javascript:void(0)"
                  class="btn btn-danger bg-gradient-danger"
                  @click="redirectAddNew"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="aliasId" @input="onChangeCode" type="number"
                    :placeholder="$t('label.employeeCode')">
                  </b-form-input>
                  <ul v-show="isOpenCodeRes" class=" autocomplete-results">
                    <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text"
                    :placeholder="$t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <treeselect v-model="departmentid" :multiple="false" :options="departmentOption"
                :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.chooseDepartment')" />
            </div>
            <div class="col-xs-12 col-md-3">
              <treeselect v-model="userFilter" :multiple="false" :options="shiftOption" :default-expand-level="Infinity"
                :noChildrenText="$t('label.filterByuser')" :placeholder="$t('label.filterByuser')" />
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button variant="btn btn-info  btn-outline-primary w-100" @click="search()">{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row my-3">
            <div class="row col-md-6">
              <div class="col-xl-6 col-xs-12 mb-4" v-if="userType == '2'">
                <button class="btn btn-primary mr-2" @click="changeDevice()">
                  <font-awesome-icon icon="exchange-alt" />
                  {{ $t("label.changeEmployee") }}
                </button>
              </div>
              <!-- <div class="col-xl-6 col-xs-12 mb-4" v-if="userType == '2'">
                <button class="btn btn-secondary mr-2" @click="changeFace()">
                  <font-awesome-icon icon="exchange-alt" />
                  {{ $t("label.changeFace") }}
                </button>
              </div> -->
            </div>
            <!-- <div class="col-xl-6 col-xs-12 mb-4 text-right" v-if="userType == '2'">
              <button class="btn btn-danger mr-2" @click="showDeleteAll()">
                Xóa tất cả
              </button>
            </div> -->
            <!-- <div class="col-xl-6 col-xs-12 text-right pr-2" v-if="userType == '2'">
              <button
                class="btn btn-primary mr-2"
                @click="downloadFileTemplate()"
              >
                <font-awesome-icon icon="download" />
                {{ $t("label.downloadFileTemplate") }}
              </button>
              <b-form-file
                style="width: auto; text-align: left"
                :placeholder="$t('label.importUserFromFile')"
                drop-placeholder="Drop file here..."
                accept=".zip, .rar"
                ref="file"
              ></b-form-file>
              <b-button
                variant="btn btn-primary ml-2"
                @click="importUser()"
                >{{$t('label.import')}}
              </b-button>
            </div> -->
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table striped bordered class="default-table" hover :items="employees" :fields="fields" responsive
                    id="table-employee" @head-clicked="handleHeaderClick($event)" style="max-height: 500px;" show-empty>
                    <template #head(checkbox)="data">
                      <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox @click.native.stop :value="data.item.aliasID" v-model="userSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(avatar)="data">
                      <a href="javascript:void(0)" @click="showImage(data.item.Base64Image)">
                        <img v-if="data.item.type == '1'" width="43px" height="43px"
                          style="border-radius: 8px; max-width: 43px; padding-bottom: 5px;" :src="data.item.Base64Image"
                          :alt="data.item.username" />
                        <img v-else width="43px" height="43px" style="border-radius: 1rem; max-width: 43px;" class="p-2"
                          src="../../assets/img/finger.jpg" :alt="data.item.username" />
                      </a>
                    </template>
                    <template #cell(dateStartWork)="data">
                      {{ convertDate(data.item.dateStartWork) }}
                    </template>
                    <template #cell(fullName)="data">
                      {{ data.item.fullName }}
                    </template>
                    <template #cell(type)="data">
                      {{ data.item.type == 0 ? "Vân tay" : "Khuôn mặt" }}
                    </template>
                    <template #cell(function)="data" v-if="userType == '2'">
                      <!-- <button class="btn btn-warning btn-sm" @click="editUser(data.item.id)" v-b-tooltip.hover
                        :title="$t('label.edit')">
                        <font-awesome-icon icon="edit" />
                      </button>
                      <button class="btn btn-primary btn-sm" @click="showChangePassword(data.item.id)" v-b-tooltip.hover
                        :title="$t('label.changePassword')">
                        <font-awesome-icon icon="exchange-alt" />
                      </button> -->
                      <button class="btn btn-danger btn-sm" @click="showDelete(data.item.aliasID)" v-b-tooltip.hover
                        :title="$t('label.delete')">
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-employee" v-on:click.native="search()"></b-pagination>
                </div>
              </div>
            </div>
            <b-modal id="imageModal" fullscreen hide-footer centered hide-header modal-class="modal-image"
              v-if="selectedImage" @hidden="clearImage">
              <div class="d-block text-center">
                <img :src="selectedImage" class="modal-image-content" />
                <div class="mt-4 text-center">
                  <b-button @click="downloadImage" variant="primary">Tải về</b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image" size="lg">
      <div class="d-block text-center">
        <img :src="urlImage" />
      </div>
    </b-modal>
    <b-modal v-model="modalMoveDepart" id="modal-move-depart" hide-footer centered size="lg"
      :title="$t('label.changeEmployee')">
      <!-- <div class="row col-xl-12 text-center">
        <label> Từ máy </label>
        <treeselect v-model="movedValue" :multiple="false" :options="options" :default-expand-level="1"
          :placeholder="$t('label.chooseDevice')" @input="handleSelection" />
      </div> -->
      <div class="row col-xl-12 text-center mt-4">
        <label> Đến máy </label>
        <treeselect v-model="moveTo" :multiple="false" :options="optionsTo" :default-expand-level="1"
          :placeholder="$t('label.chooseDevice')" />
      </div>
      <div class="d-block text-center mt-5">
        <b-button class="btn btn-danger" @click="moveDevice()">{{ $t("label.save") }}
        </b-button>
        <b-button class="btn btn-default" @click="$bvModal.hide('modal-move-depart')">{{ $t("label.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal v-model="modalMoveFace" id="modal-move-face" hide-footer centered size="lg"
      :title="$t('label.changeFace')">
      <!-- <div class="row col-xl-12 text-center">
        <label> Từ máy </label>
        <treeselect v-model="movedValue" :multiple="false" :options="options" :default-expand-level="1"
          :placeholder="$t('label.chooseDevice')" @input="handleSelection" />
      </div> -->
      <div class="row col-xl-12 text-center mt-4" v-if="flagRegis">
        <label> Đến máy </label>
        <treeselect v-model="moveTo" :multiple="false" :options="optionsTo" :default-expand-level="1"
          :placeholder="$t('label.chooseDevice')" />
      </div>
      <div class="d-block text-center mt-5">
        <b-button class="btn btn-danger" @click="moveFace()">{{ $t("label.save") }}
        </b-button>
        <b-button class="btn btn-default" @click="$bvModal.hide('modal-move-face')">{{ $t("label.cancel") }}
        </b-button>
      </div>
    </b-modal>
    <b-modal v-model="modalChangePassword" :title="$t('label.addEdit')" hide-footer ref="change-password"
      header-bg-variant="secondary" centered size="md">
      <b-form ref="form">
        <b-form-group id="input-group-1" :label="$t('label.password')" label-for="input-1">
          <b-form-input id="input-1" type="password" v-model="newPassWord" required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{
              msg["password"] && msg["password"][0]
                ? $t(msg["password"][0])
                : ""
            }}
          </div>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('label.confirmPassword')" label-for="input-2">
          <b-form-input id="input-2" type="password" v-model="confirmPassword" required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{
              msg["confirmPassword"] && msg["confirmPassword"][0]
                ? $t(msg["confirmPassword"][0])
                : ""
            }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="changePassword(idChangePassword)">{{ $t("label.save") }}
          </b-button>
          <b-button class="btn btn-default" @click="hideModalChangePassword">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="modalAlert" :title="$t('label.notificationError')" hide-footer id="modal-alert"
      header-bg-variant="secondary" modal-class="modal-delete text-center" centered size="md">
      <div class="d-block text-center error-message">
        <div class="mt-3" v-for="(item, index) in msg">
          <span :key="index">{{ item }}</span>
        </div>
      </div>
      <div class="d-block text-center mt-3">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="$bvModal.hide('modal-alert')">
            {{ $t("label.close") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal change Password  -->
    <b-modal v-model="modalDelete" ref="modal-delete" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureDeleteMember") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="definitelyDelete()">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal delete  -->
    <b-modal v-model="modalDeleteAll" ref="modal-delete-all" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureDeleteMemberAll") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="definitelyDeleteAll()">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal delete  -->
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PulseLoader from "@/components/PulseLoader.vue";
import { h } from "vue";

export default {
  components: { Treeselect, PulseLoader },
  data() {
    return {
      placeAdmin: false,
      loading: false,
      urlImage: "",
      modalImage: false,
      modalChangePassword: false,
      modalDelete: false,
      selectedImage: null,
      idChangePassword: "",
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: "",
      idDelete: null,
      totalUser: 0,
      dataTable: [],
      optionsTo: [],
      idUser: "",
      modalDeleteAll: false,
      newPassWord: "",
      confirmPassword: "",
      currentPage: 1,
      newDateStartWork: "",
      imageAvatar: null,
      userName: null,
      branch: null,
      branchs: [],
      msg: [],
      modalAlert: false,
      fullName: null,
      perPage: 10,
      isOpenCodeRes: false,
      nameResults: [],
      codeResults: [],
      aliasId: '',
      isOpenNameRes: false,
      fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.image"),
          key: "avatar",
          class: "align-middle ",
          sortable: false,
        },
        {
          label: this.$t("label.employeeCode"),
          key: "aliasID",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fullName"),
          key: "fullName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "Loại",
          key: "type",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.jobPosition"),
          key: "positionName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.department"),
          key: "departmentName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      treeData: null,
      options: [],
      userSelected: [],
      allSelected: false,
      modalMoveDepart: false,
      modalMoveFace: false,
      movedValue: null,
      moveTo: null,
      flag: 'asc',
      userType: null,
      deviceId: null,
      detail: null,
      totalEmployees: null,
      totalRecords: null,
      employees: null,
      device_name: null,
      IP: null,
      device_type: null,
      device_code: null,
      ConnectStatus: null,
      flagRegis: false,
      departmentid: null,
      userFilter: null,
      departmentOption: [],
      aliasIdMove: null,
      ipUser: '',
      shiftOption: [{
        "id": null,
        "label": 'Tất cả'
      },
      {
        "id": 1,
        "label": 'Vân tay'
      },
      {
        "id": 2,
        "label": 'Khuôn mặt'
      }],
    };
  },
  created() {
    if (
      this.$store.getters.superAdmin !== true &&
      this.$store.getters.comAdmin !== true
    ) {
      this.placeAdmin = true;
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.ipUser = this.$store.getters.ipUser
    this.deviceId = this.$route.params.id;
    this.getDetailDevice();
    this.getEmployee();
    this.getDepartment();
  },
  methods: {
    showImage(base64Image) {
      this.selectedImage = base64Image;
      this.$bvModal.show('imageModal');
    },
    clearImage() {
      this.selectedImage = null;
    },
    downloadImage() {
      const link = document.createElement('a');
      link.href = this.selectedImage;
      link.download = 'image.jpg'; // or any other file name you want
      link.click();
    },
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {})
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    async handleSelection() {
      let res;
      this.loading = true;

      try {
        res = await apiServer.post("/api/v1/device/get-finger-from-device", {
          deviceCode: this.device_code,
          aliasID: this.userSelected
        });
      } catch (error) {
        this.loading = false;
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
        this.modalMoveDepart = false;
      }
      if (res && res.data) {
        this.flagRegis = true
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
      this.loading = false;

    },
    async downloadFileTemplate() {
      let res;
      try {
        res = await apiServer
          .get("/api/v1/user/download-template", {
            responseType: "blob",
          })
          .then((response) => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: "application/zip",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "import.zip");
            document.body.appendChild(link);
            link.click();
          });
      } catch (error) { }
    },
    async importUser() {
      let file = this.$refs.file.files[0];
      if (file == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.noSelectFile"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      }
      if (this.isSupperAdmin || this.isComAdmin) {
        this.modalImport = true;
      } else {
        await this.confirmImportUser();
      }
    },
    async confirmImportUser() {
      let formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post("api/v1/user/import-user", formData, config);
      } catch (error) {
        if (error.response.status === 400) {
          this.loading = false;
          this.msg = error.response.data;
          this.modalAlert = true;
          await this.getEmployee();
        }
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data),
          position: "bottom-center",
        });
        await this.getEmployee();
      }
    },
    handleHeaderClick(column) {
      this.getEmployee(column)
    },
    selectAll() {
      if (this.allSelected == true) {
        this.userSelected = this.employees.map((x) => x.aliasID);
      } else {
        this.userSelected = [];
      }
    },
    changeDevice() {
      if (this.userSelected == null || this.userSelected.length == 0) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t("validate.message.noUserSelected"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      } else {
        this.getDevice()
        this.modalMoveDepart = true;
      }
    },

    // showImage(urlImage) {
    //   this.urlImage = urlImage;
    //   this.modalImage = true;
    // },
    showChangePassword(id) {
      this.modalChangePassword = true;
      this.idChangePassword = id;
    },
    hideModalChangePassword() {
      this.$refs["change-password"].hide();
    },
    async getDetailDevice() {
      let res;
      try {
        res = await apiServer.post("api/v1/device/detail",
          {
            deviceCode: this.deviceId,
            currentPage: this.currentPage,
            rowPerPage: this.perPage,
            departmentId: this.departmentid,
            aliasID: this.aliasId,
            hadFinger: this.userFilter
          });
      } catch (error) { }
      if (res && res.data) {
        this.device_name = res.data.data.device.device_name;
        this.IP = res.data.data.device.IP;
        this.device_type = res.data.data.device.device_type;
        this.device_code = res.data.data.device.device_code;
        this.ConnectStatus = res.data.data.device.ConnectStatus;
        this.employees = res.data.data.employees;
        this.totalEmployees = res.data.data.device_totalEmployees
        this.totalRecords = res.data.data.record_totalEmployees;


      };
    },
    async getDeviceFinger() {
      let res;
      try {
        res = await apiServer.post("api/v1/device/get-registered-finger-device",
          {
            aliasID: this.userSelected,
          });
      } catch (error) { }
      if (res && res.data) {
        this.options = res.data.data.map(item => ({
          label: item.device_name,
          id: item.device_code
        }));;
      }
    },
    async getDevice() {
      let res;
      try {
        res = await apiServer.post("api/v1/get-devices",
          {
            deviceCode: this.device_code,
            ConnectStatus: 1
            // currentPage: 1,
            // rowPerPage: ''
          });
      } catch (error) { }
      if (res && res.data) {
        this.optionsTo = res.data.devices.map(item => ({
          label: item.device_name,
          id: item.device_code
        }));;
      }
    },
    async getInfo() {
      let res;
      try {
        res = await apiServer.post("api/v1/device/get-info-user",
          {
            aliasID: this.userSelected,
            deviceCode: this.device_code,
          });
      } catch (error) { }
      if (res && res.data) {
        console.log(res.data);
      }
    },
    search() {
      this.getDetailDevice();
    },
    async importCheckin() {
      this.dateRange = null;
      this.aliasId = null;
      this.departmentid = null
      let file = this.$refs.file.files[0];
      if (file == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.noSelectFile"),
          type: "error",
          position: "bottom-center",
        });
        return false;
      }
      await this.confirmImportCheckin();
    },
    async confirmImportCheckin() {
      let file = this.$refs.file.files[0];
      const filename = file.name;
      const fileExt = filename.substring(filename.lastIndexOf('.'), filename.length)
      let importEndpoint = "/api/v1/device/syncFinger";
      let formData = new FormData();
      formData.append("file", file);
      formData.append("ipUser", this.ipUser)
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post(importEndpoint, formData, config);
      } catch (error) {
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
      } finally {
        this.loading = false;
      }
      if (res && res.data) {
        // console.log(res.data)
        // const table = res.data.data.items
        // this.dataTable = [];
        // this.dataTableShow = [];
        // this.dataTable = [...table.slice(0, 500)];
        // this.dataTableShow = [...table.slice(0, 500)];
        // this.totalRecord = res.data.data.totalRecords;
        // this.device = res.data.data.deviceName;
        // this.totalEmployees = res.data.data.totalEmployees;


        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        await this.getDetailDevice();
      }
    },
    async getEmployee(column) {
      this.loading = true;
      let placeID = this.$store.getters.placeID;
      if (this.branch != null && this.branch.value != "") {
        placeID = [this.branch];
      }
      if (this.treeData && this.treeData.length == 0) {
        this.treeData = null;
      }
      this.dataTable = [];
      let username = this.userName != null ? this.userName.trim() : null;
      let res;
      this.flag = this.flag === 'asc' ? 'desc' : 'asc';
      try {
        res = await apiServer.post("api/v1/user/get-list-user", {
          placeID: placeID,
          department: this.treeData,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
          column: column,
          sort: this.flag,
          aliasid: this.aliasId

        });
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        // this.totalRecords = res.data.data.record_totalEmployees;
        this.loading = false;
      }
    },
    async moveDevice() {
      if (this.moveTo == null || this.moveTo == undefined) {
        this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: "Chưa chọn máy chuyển đến",
            position: "bottom-center",
          });
        return false;
      }
      
      let res;
      this.loading = true;
      try {
        res = await apiServer.post("api/v1/device/transfer-finger", {
          deviceCode: this.device_code,



          aliasID: this.userSelected,
          newDeviceCode: this.moveTo,
          ipUser: this.ipUser

        });
      } catch (error) {
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: (error.response.data.message),
            position: "bottom-center",
          });
        }
        this.loading = false;
        return false;
       }
      if (res && res.data) {
        this.modalMoveDepart = false;
        this.userSelected = false;
        this.moveTo = null
        this.loading = false;
        // this.userSelected = null
        await this.getEmployee();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Chuyển thành công",
          position: "bottom-center",
        });
      }
      this.userSelected = [];
    },
    async moveFace() {
      if (this.moveTo == null || this.moveTo == undefined) {
        this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: "Chưa chọn máy chuyển đến",
            position: "bottom-center",
          });
        return false;
      }
      let res;
      try {
        res = await apiServer.post("api/v1/device/register-finger", {
          deviceCode: this.moveTo,
          aliasID: this.userSelected,
          fullName: null
        });
      } catch (error) { }
      if (res && res.data) {
        this.modalMoveDepart = false;
        this.userSelected = false;
        this.moveTo = null
        // this.userSelected = null
        await this.getEmployee();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: "Chuyển thành công",
          position: "bottom-center",
        });
      }
      this.userSelected = [];
    },

    redirectAddNew() {
      this.$router.replace("/admin/user");
    },
    editUser(id) {
      this.$router.push("/admin/user/" + id);
    },
    hideModalDelete() {
      this.modalDelete = false;
      this.modalDeleteAll = false;
      this.$refs["modal-delete"].hide();
      this.$refs["modal-delete-all"].hide();
    },
    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.modalDelete = true;
    },
    showDeleteAll() {
      this.modalDeleteAll = true;
    },
    async definitelyDelete() {
      let response;
      this.loading = true;

      try {
        response = await apiServer.post("api/v1/device/remove-face", {
          deviceCode: this.device_code,
          aliasID: this.idDelete,
        });
      } catch (error) {
        if (error.response.data.ErrorMessage) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.ErrorMessage),
            position: "bottom-center",
          });
        }
        else {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: "Xóa thất bại",
            position: "bottom-center",
            type: "error"
          });
        }
        this.modalDelete = false;
      }
      if (response && response.status) {
        if (response.status === 200) {
          this.hideModalDelete();
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(response.data.message),
            position: "bottom-center",
          });
          await this.getDetailDevice();
        }
      }
      this.loading = false;

    },
    async definitelyDeleteAll() {
      let response;
      this.loading = true;
      try {
        response = await apiServer.post("api/v1/device/remove-all-face", {
          deviceCode: this.device_code,
        });
      } catch (error) { }
      if (response && response.status) {
        if (response.status === 200) {
          this.hideModalDelete();
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(response.data.message),
            position: "bottom-center",
          });
          await this.getDetailDevice();
        }
      }
      this.loading = false;

    },
    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setCodeResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenCodeRes = false;
    },
    setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenNameRes = false;
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
      this.isOpenNameRes = false;
    },
    async changePassword(id) {
      let res;
      this.msg = [];
      try {
        res = await apiServer.post("/api/v1/user/update-password", {
          id: id,
          password: this.newPassWord,
          confirmPassword: this.confirmPassword,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalChangePassword = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data),
              position: "bottom-center",
            });
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 401) {
          alert(this.$t(error.response.data));
        }
      }
    },
    async getListBranch() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {});
        if (res && res.data) {
          this.branchs.push({
            value: null,
            text: this.$t("label.allBranches"),
          });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    convertDate(inputDate) {
      if (inputDate == "" || inputDate == null) return;
      return moment(inputDate).format("DD/MM/yyyy");
    },
  },
};
</script>
<style lang="scss">
$mobile-width: 480px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

table {
  tr {
    color: #000;
  }
}

#main-default.container-fluid {
  padding: 15px 30px 30px 30px !important;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 400px;
}

.btn {
  font-weight: normal;
}

.default-table {
  thead {
    tr {
      background: #f1f1f1;

      th {
        text-transform: none;
        font-size: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        letter-spacing: 0;
      }
    }
  }

  td {
    font-size: 15px !important;
  }
}

.btn-sm {
  font-size: 15px !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle,
.btn-danger {
  background-color: #ec1a43 !important;
  border-color: #ec1a43 !important;
}

.table-hover tbody tr:hover {
  color: #000 !important;
  background-color: #ffefec !important;
}

.filter-asc,
.filter-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.filter-asc {
  margin-bottom: 1px;
  border-bottom: 5px solid #ccc;
}

.img-profile {
  width: 60px !important;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.table-small td {
  font-size: 11px !important;
  padding: 5px !important;
  vertical-align: middle !important;
}

.custom-modal .modal-dialog {
  max-width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
}

.custom-modal .modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image-content {
  max-height: 80vh;
  max-width: 100%;
  object-fit: contain;
}

.modal-image {
  img {
    max-width: 100%;
    height: auto;
  }


  @include mobile {
    .modal-dialog {
      width: 96vw;

      img {
        height: auto;
        width: 80vw;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
}

.vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
  padding: 9px !important;
}

.vue-treeselect__placeholder {
  padding: 7px;
}

.vue-treeselect--searchable .vue-treeselect__input-container {
  padding: 0;
}

.vue-treeselect--has-value .vue-treeselect__value-container {
  padding: 4px !important;
}

.vue-treeselect__single-value {
  line-height: 44px !important;
}
</style>
