import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isLogin: false,
    jwt: '',
    role: '',
    locale: 'vi',
    fullName: '',
    avatar: '',
    placeID: '',
    audio: true,
    superAdmin: false,
    comAdmin: false,
    userType: 0,
    ipUser: '',
    department: '',
    departmentId:'',
  },
  getters: {
    isLogin: state => state.isLogin,
    jwt: state => state.jwt,
    role: state => state.role,
    locale: state => state.locale,
    fullName: state => state.fullName,
    avatar: state => state.avatar,
    placeID: state => state.placeID,
    audio: state => state.audio,
    superAdmin: state => state.superAdmin,
    comAdmin: state => state.comAdmin,
    userType: state => state.userType,
    ipUser: state => state.ipUser,
    department: state => state.department,
    departmentId: state => state.departmentId,
  },
  mutations: {
    doLogin(state, payload) {
      state.isLogin = true;
      state.role = payload.role;
      state.fullName = payload.fullName;
      state.avatar = payload.avatar;
      state.placeID = payload.placeID;
      state.superAdmin = payload.supperAdmin;
      state.comAdmin = payload.comAdmin;
      state.userType = payload.userType;
      state.ipUser = payload.ipUser;
      state.department = payload.departmentName;
      state.departmentId = payload.departmentIdParent;

    },
    // setIp(state, payload) {
    //   console.log(payload);
    //   state.ipUser = payload.ipUser;
    // },
    saveJwt(state, token) {
      state.jwt = token
    },
    doLogout(state) {
      state.isLogin = false;
      state.jwt = '';
      state.role = '';
      state.fullName = '';
      state.avatar = '';
      state.placeID = '';
      state.superAdmin = false;
      state.comAdmin = false;
      state.userType = 0;
      state.ipUser = ''
      state.department = ''
      state.departmentId = ''
    },
    updateFullName(state, fullName) {
      state.fullName = fullName
    },
    updateAvatar(state, avatar) {
      state.avatar = avatar
    },
    updateAudio(state, value) {
      state.audio = value
    },
    updateLocale(state, locale) {
      state.locale = locale
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
