<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid ">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-12">
              <h1 class="page-title mt-2 mb-4">
                {{ $t('menu.reportTimeKeep') }}
              </h1>
            </div>
          </div>
        </div>
        <div class="col-xl-12 mb-4">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <a href="javascript:void(0)" @click="redirectAttendanceReport">
                  <div class="report-item">
                    <font-awesome-icon icon="file-alt" size="lg" />
                    <h5>{{ $t('label.report')}}</h5>
                  </div>
                  <!-- end .report-item -->
              </a>
            </div>
            <div class="col-xs-12 col-md-4">
              <a href="javascript:void(0)" @click="redirectClaimTimeSheetList">
                  <div class="report-item">
                    <font-awesome-icon icon="file-signature" size="lg" />
                    <h5>{{ $t('title.compensationWorkDay')}}</h5>
                  </div>
                  <!-- end .report-item -->
              </a>
            </div>
            <div class="col-xs-12 col-md-4">
              <a href="javascript:void(0)" >
                  <div class="report-item">
                    <font-awesome-icon icon="cog" size="lg" />
                    <h5>{{ $t('title.reportSetup')}}</h5>
                  </div>
                  <!-- end .report-item -->
              </a>
            </div>
          </div>
        </div>

        
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      
    }
  },
  mounted () {

  },
  methods: {
    redirectAttendanceReport() {
      this.$router.replace("/admin/attendance-report")
    },
    redirectClaimTimeSheetList() {
      this.$router.replace("/admin/claim-time-sheet-list")
    },
    

  }
}
</script>
<style  lang="scss" scoped>
.report-item{
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: .25rem;

  will-change: transform;
  border: 2px solid #f1f1f1;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  flex-direction: column;
  font-size: 26px;
  h5{
    margin-top: 10px;
    font-size: 16px;
  }
  svg{
    color: #FB6340;
  }
  &:hover{
    border: 2px solid #FB6340; 
    
  }
}
</style>
