<template>
  <div id="app">
        
  </div>
</template>

<script>
import Vue from 'vue';
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
export default {
    data() {
        return {
            
        }
    },
    mounted() {
        this.exportReport();
    },
    methods: {
    async exportReport(){
      let res;
      try {
        res = await apiServer.post('/api/v1/contact/download', {
            }, {
            responseType: 'blob'
            }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Contact')
            document.body.appendChild(link)
            link.click()
        });
        } catch (error) {
        }
        
        },
    }

}
</script>
<style lang="scss" scoped>

</style>