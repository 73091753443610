<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <div style="display: flex;">
                <div style="margin-left: 10%; text-align: center;">
                    <h4>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h4>
                    <h4>{{ departmentName ? `Đơn vị: ${departmentName}` : '' }}</h4>
                </div>
            </div>
            <h2 class="text-center text-uppercase">BẢNG TỔNG HỢP CA LÀM VIỆC {{ departmentName }}</h2>
            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in modifiedPreviewData" :key="i">
                        <template>
                            <td v-for="(cell, j) in row" v-if="j > 0" :key="j" class="max-width-column">{{ cell }}</td>
                        </template>
                    </tr>
                </table>
                <div style="display: flex; margin-left: 20%">
                    <div>
                        <h3>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h3>
                    </div>
                    <div style="margin-left: 40%; text-align: center;">
                        <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
                        <h3>TRƯỞNG ĐƠN VỊ</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        },
        departmentName: {
            type: String,
            required: true
        },
        day: {
            type: Number,
            required: true
        },
        month: {
            type: String,
            required: true
        },
        year: {
            type: Number,
            required: true
        }
    },
    computed: {
        modifiedPreviewData() {
            const modifiedData = JSON.parse(JSON.stringify(this.previewData));

            modifiedData?.splice(0, 3);
            modifiedData?.splice(-2);
            return modifiedData;
        },
    }
}
</script>
<style scoped>
#tbl-preview {
    border: none;
}

#tbl-preview tr:first-child td {
    font-weight: bold;
}


#tbl-preview tr.tbl-footer {
    border: none;
}

#tbl-preview tr.tbl-footer td {
    border: none;
}

/* #tbl-preview tr:last-child {
    font-weight: bold;
} */
</style>