<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid " style="padding-top:0px !important">
    <div class="row">
      <div class="col-12">

        <div>
          {{ $t('label.turnAudioOn') }}
          <toggle-button :value=audioOn color="#FB6340" :labels=labelsAudio @change="clickAudio" />
        </div>
        <div>
          <video style="display:none" controls="" id="audio">
            <source id="audio-source" src="../../assets/video/notification.mp3" type="audio/mpeg">
          </video>
        </div>
      </div>
      <div class="clear-fix"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <treeselect v-model="departmentid" :multiple="false" :options="departmentOption"
          :default-expand-level="Infinity" @input="changeDepartment" :noChildrenText="$t('label.noNodeChildren')"
          :placeholder="$t('label.chooseDepartment')" />
      </div>
      <div class="col-md-6">
        <flat-pickr v-model="dateRange" :config="configDateTimePicker" :placeholder="$t('label.chooseDate')"
          name="birthdate" class="form-control" @on-change="changeDate"></flat-pickr>
      </div>
    </div>
    <div class="mt-4">
      <!-- <p v-if="flagDate">Thống kê dữ liệu hôm nay</p>
          <p  v-if="!flagDate">Thống kê dữ liệu ngày: <span class="font-weight-bold">{{ dateRange }}</span></p> -->
      <p v-if="flagDate">Thống kê dữ liệu hôm nay</p>
      <p v-else>Thống kê dữ liệu ngày: <span class="font-weight-bold">{{ dateRange }}</span></p>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="redirectEmployeeManager" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.accountNumber }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.employee") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="users" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="detailStatistic('leave')" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.leave }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.leave") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="calendar" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="detailStatistic('noShift')" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.noShift ? this.noShift : 0 }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.noShift") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  
                  <font-awesome-icon icon="user" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="detailStatistic('checkedin')" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.checkedin }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.checkedin") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="calendar-check" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="detailStatistic('notcheckin')" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.notCheckin }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.notCheckin") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="calendar-times" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="col-12 col-md-4">
        <div class="card border-0">
          <a href="javascript:void(0)" @click="detailStatistic('late')" class="link-from-dashboard">
            <div class="card-body">
              <div class="row">
                <div class="col-8">
                  <span class="h2 font-weight-bold mb-0">{{ this.lateNumber }}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.late") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="clock" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div> -->
    </div>
    <h2 class="ml-4"> Danh sách nhân viên {{ typeName }} </h2>
    <div class="row col-12 mt-2 ml-1" v-show="showDetail">
      <div class="card w-100">
        <div class="card-body m-0 p-0 table-responsive">
          <b-table bordered="bordered" class=" default-table w-100" hover :items="dataTable" :fields="fields" responsive
             id="table-user" @head-clicked="handleHeaderClick($event)" style="max-height: 550px;" >
            <!-- <template #cell(avatar)="data">
              <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                <img width="75px" height="75px" style="border-radius:8px;max-width:75px;" :src="data.item.avatar"
                  :alt="data.item.personName" />
              </a>
            </template> -->
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right" size="sm"
            class="m-4" aria-controls="table-inouthistory" v-on:click.native="detailStatistic(type)"></b-pagination>
        </div>
        <div class="text-right">
          <a @click="closeDetail" href="#"><img src="../../assets/img/icons/common/close.png"></a>
        </div>
      </div>
    </div>
    <div class="page-checkin">
      <div>
        <video style="display:none" controls="" id="audio">
          <source id="audio-source" src="../../assets/video/notification.mp3" type="audio/mpeg">
        </video>
      </div>
      <div class="row mt--3 row-sm">
        <div class="item-grid col-lg-2 col-md-3 col-sm-6 col-xs-12 mt-3" v-for="user in users">
          <div class="item card mb-0">
            <a class="image">
              <img :src="user.detected_image_url" />
            </a>
            <div class="info">
              <label>{{ $t('label.nameEmployee') }}</label>: <span class="name w-100 clearfix">{{ user.personName
                }}</span>
              <label>{{ $t('label.timeDay') }}</label>: <span class="manv w-100 clearfix">{{ user.date }}</span>
              <label>{{ $t('label.camera') }}</label>: <span class="manv w-100 clearfix">{{ user.deviceName }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card col-12 pt-4">
        <div class="row">
          <div class="col-12 col-md-5">
            <div>
              <div class="card bg-gradient-warning border-success" style="min-height: 400px">
                <div class="text-center p-3">
                  <h5 class="h3 mb-0 text-white">
                    {{ $t("label.statisticsToday") }}
                  </h5>
                </div>
                <div class="card-body" style="">
                    <div class="row justify-content-center">
                      <div  v-for="(userData, index) in listDatauser" :key="index" class="card text-center col-md-5 mr-2">
                        <div class="card-body">
                          <p class="text-danger font-weight-bold p-0 m-0">
                            {{ userData?.employee_count }}</p>
                          <span>{{ userData?.shifts_name }}</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="card border-0">
              <div class="container">
                <apexchart height="389" :options="options" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <b-modal modal-class="modal-notification" v-model="popupTurnOn" size="sm" :title="$t('label.notification')">
        {{ $t('label.turnAudioOn') }}
        <template #modal-footer>
          <div class="w-100">
            <b-button variant="outline-secondary" class="float-right" @click="turnAudioOff()">
              {{ $t('label.cancel') }}
            </b-button>
            <b-button variant="primary" class="float-right mr-3" @click="turnAudioOn()">
              {{ $t('label.agree') }}
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

  </div>
</template>
<script>
import moment from "moment";
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import Treeselect from '@riophae/vue-treeselect'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import PulseLoader from "@/components/PulseLoader.vue";
import Vue from "vue";
import FlatpickrLanguages from "flatpickr/dist/l10n";
export default {
  components: {
    Treeselect,
    flatPickr
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      loading: false,
      accountNumber: null,
      checkedin: null,
      lateNumber: null,
      notCheckin: null,
      leave: null,
      noShift: null,
      totalRecord : 0,
      flagDate: true,
      users: [],
      dateRange: '',
      // dateRange: this.getNowDate(),
      popupTurnOn: false,
      audioOn: true,
      srcMp3: '',
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "single",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      labelsAudio: { checked: this.$t('label.on'), unchecked: this.$t('label.off') },
      departmentid: null,
      departmentOption: [],
      showDetail: false,
      dataTable: [],
      currentPage: 1,
      perPage: 25,
      fields: [
        // {
        //   label: this.$t('label.image'),
        //   key: 'avatar',
        //   class: 'align-middle',
        //   sortable: false
        // },
        {
          label: this.$t('label.employeeCode'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'fullName',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.jobPosition'),
          key: 'position',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.department'),
          key: 'department',
          class: 'align-middle',
          sortable: false
        }
      ],
      options: {
        chart: {
          id: "vuechart-example",
          type: 'bar',

          height: 300,
          width: '100%'
        },
        xaxis: {
          categories: [],
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }]
      },
      series: [],
      countInoutIINnfos: [],
      listDatauser: [],
      type: '',
      typeName: '',
    };
  },
  created() {
    this.audioOn = this.$store.getters.audio;
    this.getCountInout();
  },
  mounted() {
    this.getDepartment();
    // this.getStatistic();
    this.type = '';
    // this.getUser();
    this.dateRange = this.getNowDate()
    this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    document.getElementById('audio').click();
  },
  sockets: {
    connect: function () {
      console.debug('socket connected')
    },
    receiveMessage: function (user) {
      for (let i = 0; i < this.$store.getters.placeID.length; i++) {
        if (parseInt(this.$store.getters.placeID[i]) === parseInt(user.placeID)) {
          if ((this.audioOn) && (user.audio === true)) {
            const audioSrc = 'data:audio/mp3;base64,' + user.linkaudio
            document.getElementById('audio-source').src = audioSrc
            document.getElementById('audio').load()
            document.getElementById('audio').play()
          }
          this.users.unshift(user)
        }
      }
    }
  },
  methods: 
  {
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },

    convertTimestampSendDate(date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },

    getNowDate() {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy;
      return today;
    },
    async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
          department: this.departmentid,
          date: this.convertTimestampSendDate(this.dateRange),
        })
      } catch (error) {
        console.log(error);
      }
      if (res && res.data) {
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].title == "accountNumber") {
            this.accountNumber = data[i].countcol;
          } else if (data[i].title == "checkined") {
            this.checkedin = data[i].countcol;
          } else if (data[i].title == "lateNumber") {
            this.lateNumber = data[i].countcol;
          } else if (data[i].title == "notCheckin") {
            this.notCheckin = data[i].countcol;
          } else if (data[i].title == "leave") {
            this.leave = data[i].countcol;
          } else if (data[i].title == "noShift") {
            this.noShift = data[i].countcol;
          } else {
          }
        }
      }
    },
    async getUser() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/dashboard-user', {})
      } catch (error) { }
      if (res && res.data) {
        this.listDatauser = res.data
      }
    },
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {})
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
      }
      this.detailStatistic('noShift')

    },
    async detailStatistic(type) {
      this.typeName = ''
      if (!this.loading) {
        this.loading = true;
        this.showDetail = true;
        this.dataTable = [];
        this.type = type
        if (type === 'noShift') {
          this.typeName = 'chưa có ca'
        } else if (type === 'leave') {
          this.typeName = 'nghỉ phép'
        } else if (type === 'checkedin') {
          this.typeName = 'đã checkin'
        } else if (type === 'notcheckin') {
          this.typeName = 'chưa checkin'
        } else if (type === 'late') {
          this.typeName = 'đi muộn'
        } else {
          this.typeName = ''
        }
        let res;
        try {
          res = await apiServer.post('api/v1/dashboard/list', {
            currentPage : this.currentPage,
            perPage : this.perPage,
            criteria: this.type,
            date : this.convertTimestampSendDate(this.dateRange),
            department : this.departmentid
          });
        } catch (error) {
          console.log(error.response.data);
        }
        this.loading = false;
        if (res && res.data) {
          this.dataTable = res.data.results;
          this.totalRecord = res.data.totalRecord
          this.$forceUpdate();
        }
      }
    },
    async getCountInout() {
      let res
      try {
        res = await apiServer.get('api/v1/report/count-inout', {
        })
      } catch (error) {
      }
      // console.log(res)
      if (res && res.data) {
        this.countInoutIINnfos = res.data;
      }
    },
    async getStatistic() {
      let res
      try {
        res = await apiServer.post('api/v1/checkin/statistic', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        // console.debug(res.data);
        //this.series.push({names:'series-1'});
        let checkedIn = [];
        let checkinLate = [];
        let checkoutEarly = [];
        let leaved = [];
        let unCheckin = [];
        for (let v of res.data) {
          this.options.xaxis.categories.push(v.dateId);
          checkedIn.push(v.checkedin);
          checkinLate.push(v.checkin_late);
          checkoutEarly.push(v.checkout_early);
          leaved.push(v.leaved);
          unCheckin.push(v.un_checkin);
        }
        this.series.push({ name: this.$t('label.checkedin'), data: checkedIn });
        this.series.push({ name: this.$t('label.late'), data: checkinLate });
        this.series.push({ name: this.$t('label.comeBackBefore'), data: checkoutEarly });
        this.series.push({ name: this.$t('label.takeABreak'), data: leaved });
        this.series.push({ name: this.$t('label.notCheckin'), data: unCheckin });
        this.series.push({ name: this.$t('label.noShift'), data: noShift });
      }
    },
    clickAudio() {
      this.audioOn = !this.audioOn;
      this.$store.commit("updateAudio", this.audioOn);
    },
    changeDepartment() {
      setTimeout(() => { this.getSummary() }, 500);
    },
    changeDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yyyy = today.getFullYear();
      let todayString = dd + '-' + mm + '-' + yyyy;
      let _todayString = dd + '/' + mm + '/' + yyyy;
      if (todayString === this.dateRange || _todayString === this.dateRange) {
        this.flagDate = true;
      } else {
        this.flagDate = false;
      }
      setTimeout(() => { this.getSummary() }, 500);
    },
    closeDetail() {
      this.showDetail = false;
    },
    async redirectEmployeeManager() {
      if (!this.loading) {
        this.loading = true;
        this.showDetail = true;
        this.dataTable = [];
        this.typeName = ''
        // this.type = type
        let res;
        try {
          res = await apiServer.post('api/v1/user/get-list-user', {
            currentPage : this.currentPage,
            rowPerPage : this.perPage,
            // criteria: this.type,
            // date : this.convertTimestampSendDate(this.dateRange),
            department : this.departmentid
          });
        } catch (error) {
          console.log(error.response.data);
        }
        this.loading = false;
        if (res && res.data) {
          this.dataTable = res.data.data.results;
          this.totalRecord = res.data.data.totalRecord
          this.$forceUpdate();
        }
    }
    // this.typeName = 'nhân viên'
      // this.$router.replace("/admin/employee-manager")
    }
  },
};
</script>
<style scoped lang="scss">
.icon-dashdoard {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 40px;
}

.row-sm {
  margin-left: -5px;
  margin-right: -5px;
}

.item-grid {
  padding-left: 5px;
  padding-right: 5px;
}

.item {
  height: 100%;

  img {
    width: 100%;
    height: 250px;
  }

  .info {
    padding: 10px;
    font-size: 14px;

    label {
      font-weight: bold;
      margin-bottom: 0.2rem;
    }
  }
}

.card-body {
  padding: .5rem;
}

.full-width {
  width: 100%;
}
</style>
