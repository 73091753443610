import Vue from 'vue'
export const clickOutside = {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target) || (typeof event.target.className == 'string' && event.target.className.startsWith('user-nav-profile')))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
    /* document.body.addEventListener("touchstart", el.clickOutsideEvent) */
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    /* document.body.removeEventListener("touchstart", el.clickOutsideEvent) */
  }
}

Vue.directive('click-outside', clickOutside)
