export default {
  menu: {
    dashboard: "Dashboard",
    inOutHistory: "Entry and exit history",
    employeeManager: "Employee manager",
    attendanceManagement: "Attendance management",
    checkIn: "Time attendance management",
    showListShift: "Shift management",
    arrangeShifts: "Arrange shifts",
    managementLeave: "Management leave",
    dataManagement: "Data management",
    branchManagement: "Branch management",
    departmentManager: "Department manager",
    reportTimeKeep: "Time attendance report",
    overTime: "Over Time",
    dayOff: "Day off",
    systemSettings: "System settings",
    welcome: "Welcome",
    download: "Download",
    companyManager: "Company manager",
    leaveTypeManagement: "Leave type management",
    positionManagement: "Position management",
    approveLeave: "Approve leave",
    groupUserManagement: "Group user management",
    managementOT: "Management overtime",
    approveOvertime: "Approve overtime",
    shiftHistoryManagement: "Manage shift history",
    mealRequest: "Request meals",
    notifyMeal: "Nofity meals",
    deviceManagement: "Device management",
  },
  title: {
    login: "Login DRC Checkin",
    logout: "Logout",
    admin: "Dashboard DRC Checkin",
    InOutHistory: "Entry and exit history",
    attendanceManagement: "Attendance management",
    showListShift: "Shift management",
    arrangeWorkShift: "Arrange shifts",
    branchManagement: "Branch management",
    departmentManager: "Department manager",
    employeeManager: "Employee manager",
    leaveApplication: "Management leave",
    CheckIn: "Time attendance management",
    overtimeList: "List department overtime",
    user: "User",
    managementLeave: "List of employees with leave of absence",
    leaves: "List leave of absence",
    setHoliday: "Set holidays",
    compensationWorkDay: "Compensation work day",
    reportSetup: "Report setup",
    homePage:
      "DRC x Viettel Construction :: Camera AI | Smart Workplace Solutions",
    welcome: "DRC x Viettel Construction :: Smart reception Robot, AI Camera",
    companyManager: "Company manager",
    leaveType: "Leave type management",
    groupUserManagement: "Group user management",
    managementOvertime: "Management overtime",
    shiftHistoryManagement: "Manage shift history",
    deviceManagement: "Device management",
    uncheckin: "Uncheckin",
    checkinLate: "Checkin late",
    checkedin: "checkedin",
    waitingApproveLeave: "Wait approve application",
    waitingApproveOvertime: "Wait approve application",
    leaveNotice: "Leave notice",
    overtimeNotice: "Overtime notice",
    mealRequest: "Request meals",
    notifyMeal: "Notify meals",
  },

  validate: {
    message: {
      accountRequired: "Please enter your account",
      existPhone: "Number of phone has in system",
      placeIdRequired: "Branch code cannot be empty",
      placeAddressRequired: "Branch address cannot be empty",
      placeNameRequired: "Branch name cannot be empty",
      fullNameRequired: "Username cannot be empty",
      cardIDInvalid: "ID card is not in the correct format",
      phoneInvalid: "Phone number is not in the correct format",
      phoneRequired: "Phone number can not be left blank",
      emailRequired: "Email cannot be blank",
      startWorkRequired: "Work start date cannot be left blank",
      avatarRequired: "Profile photo can't be blank",
      placeRequired: "Branch cannot be empty",
      departmentRequired: "Departments cannot be left empty",
      aliasIdRequired: "Employee ID cannot be left blank",
      passwordRequired: "Password is not blank",
      confirmPassRequired: "Please enter your password",
      oldPasswordRequired: "Old password cannot be empty",
      staffRequired: "Staff cannot be left blank",
      leaveRequired: "Please enter holidays",
      leaveNameRequired: "Holiday name cannot be left blank",
      startLeaveRequired: "The start date of the leave cannot be left blank",
      endLeaveRequired: "The end date of the leave cannot be left blank",
      shiftNameRequired: "Shift name cannot be blank",
      shiftNameMaxLength: "Shift name must be less than 50 characters",
      workDayRequired: "The number of working days cannot be left blank",
      workDayInvalid: "The number of working days is not in the correct format",
      startWorkTimeRequired: "Shift start time cannot be left blank",
      endWorkTimeRequired: "Shift end time cannot be left blank",
      startRestTimeRequired: "Start break time cannot be left blank",
      endRestTimeRequired: "End break time cannot be left blank",
      leaveHalfRequired: "Please choose leave half shift",
      userRequired: "User cannot leave blank",
      compensationDateRequired: "Compensation date cannot be left blank",
      compensationReasonRequired: "Compensation reason cannot be left blank",
      userNameRequired: "Username cannot be left blank",
      companyNameRequired: "Company name cannot be left blank",
      companyAddressRequired: "Company address cannot be left blank",
      taxcodeRequired: "Tax code cannot be left blank",
      companyRequired: "Company is not empty",
      positionRequired: "Position is not empty",
      confirmPasswordRequired: "Please confirm password",
      approverRequired: "Approver is not empty",
      tokenHanetRequired: "Hanet token is not empty",
      noUserSelected: "Please choose employee",
      noDepartmentSelected: "Please choose department",
      branchIdRequired: "Please select branch",
      codeRequired: "Please type code",
      nameRequired: "Please type name",
      leaveTypeRequired: "Please select leave type",
      groupNameRequired: "Please type group name",
      dateIDRequired: "Please choose working date",
      checkinRequired: "Please choose checkin",
      checkoutRequired: "Please choose checkout",
      dayOfWeekRequired: "Please choose register date",
      quantityRequired: "Please type quantity",
      deviceCodeRequired: "Please select device",
      deviceNameRequired: "Please type device name",
      deviceTypeRequired: "Please select device type",
      userApproveRequired: "Please choose user approve",
      userApproveInvalid: "User approve is invalid",
      confirmPasswordNotMatch: "Confirm password not match",
      reasonRequired: "Please type reason",
      minuteOvertimeLimit: "Overtime minutes cannot exceed 200 minutes",
      minuteOvertimeRequired: "Please type minute overtime",
      minuteOvertimeInvalid: "Please choose is invalid",
      dateOvertimeRequired: "Choose date overtime",
      shiftIdRequired: "Please select shift",
      approveByRequired: "Please choose approver",
      quantityInvalid: "Quantity is invalid",
    },
  },

  error: {
    message: {
      accountOrPassIncorrect: "Incorrect account or password",
      dbError: "Database query error",
      general: "Error",
      uploadPicture: "Image upload error",
      imageInvalid:
        "Image invalid. Please make sure the image is clear, has only 1 people, full of eyes, nose and mouth, looking straight at the camera, not wearing a hat and mask",
      userNotExist: "Staff does not exist",
      tokenHanetNull: "Hanet token is null",
      invalidId: "Id is invalid",
      userExisted: "This person is already exist",
      aliasIdExisted: "Alias id already exist",
      userLimit:
        "Unable create more Face ID, this place in limited used 15/15 Face ID",
      passwordNotMatch: "Password incorrect",
      shiftNotExist: "The shift does not exist",
      leaveNotExist: "This leave does not exist",
      noUserFound: "There are no employees",
      dateRangeLessThan31: "The date range is not more than 31 days",
      shiftNameExisted: "The name of the shift is already in the system",
      contactNotExist: "Contact does not exist",
      aliasIDAlreadyExisted: "AliasID already existed",
      userNameExisted: "Username already existed",
      leaveTypeExist: "Leave type already existed",
      deviceExist: "Device already existed",
      groupExisted: "Group already existed",
      userApproveDuplicate: "User approve duplicate",
      userApproveEmpty: "User approve empty",
      noSelectLeave: "Please select at least 1 leave",
      noSelectOvertime: "Please select at least 1 overtime",
      noSelectExcelFile: "Please select excel file",
      noSelectFile: "Please select file",
      invalidFile: "Invalid file",
      userNotFound: "User not found",
      userNotInShift: "User not in shift",
      mealExists: "Meal Exist",
      workTimeInvalid: "Work time invalid",
      restTimeInvalid: "Rest time invalid",
    },
  },

  notify: {
    message: {
      success: "Success",
      addUserToShiftSuccess: "Successfully added staff to shift",
      removeUserShiftSuccess: "Delete employee in shift successfully",
      createdAccountSuccess: "Account successfully created",
      updatedSuccess: "Update successful",
      deletedSuccess: "Delete successful",
      changePassSuccess: "Change password successfully",
      deleteShiftSuccess: "Successfully deleted shift",
      addLeaveSuccess: "Successfully added holiday",
      updateLeaveSuccess: "Successfully edited holiday",
      deleteLeaveSuccess: "Successfully deleted the holiday",
      updateShiftSuccess: "Successful shift correction",
      addShiftSuccess: "New successful shift added",
      addBranchSuccess: "New successful branch added",
      updateBranchSuccess: "Successfully fixed branch",
      deleteBranchSuccess: "Delete branch successfully",
      addContactSuccess: "Successfully added contact",
      deleteContactSuccess: "Delete contact successfully",
      addCompensationSuccess: "Add success compensation",
      approveCompensationSuccess: "Browsing successful compensation",
      addCompanySuccess: "More successful companies",
      updateCompanySuccess: "Edit company information successfully",
      importUserSuccess: "Import user success",
      addDepartmentSuccess: "Add department success",
      editDepartmentSuccess: "Edit department success",
      deleteDepartmentSuccess: "Delete department success",
      moveDepartmentSuccess: "Moved department success",
      changeUserDepartment: "Change department of user success",
      addLeaveTypeSuccess: "Add leave type success",
      editLeaveTypeSuccess: "Edit leave type success",
      deleteLeaveTypeSuccess: "Delete leave type success",
      addPositionSuccess: "Add position success",
      editPositionSuccess: "Edit position success",
      deletePositionSuccess: "Delete position success",
      movePositionSuccess: "Moved position success",
      changeUserPosition: "Change position of user success",
      nodata: "No data",
      importUserToShiftSuccess: "Import user to shift success",
      addGroupUserSuccess: "Add group user success",
      editGroupUserSuccess: "Edit group user success",
      deleteGroupUserSuccess: "Delete group user success",
      addOvertimeSuccess: "Add request overtime success",
      importShiftSuccess: "Import shift success",
      addCheckinSuccess: "Add checkin success",
      updateCheckinSuccess: "Update checkin success",
      cannotApprove: "Approve does not success",
      approveOvertimeSuccess: "Approve overtime success",
      addMealsSuccess: "Add register meal success",
      updateMealsSuccess: "Update register meal success",
      deleteMealSuccess: "Delete register meal success",
      addDeviceSuccess: "Add device success",
      updateDeviceSuccess: "Update device success",
      deleteDeviceSuccess: "Delete device success",
      approveListLeaveSuccess: "Approve list leave success",
      approveListOvertimeSuccess: "Approve list overtime success",
      deleteOvertimeSuccess: "Delete overtime success",
      importLeaveSuccess: "Import leave success",
      importCheckinSuccess: "Import checkin success",
      employeeCheckinlateShiftDate:
        "Employee {name} checkin late shift {data} date {date}",
      employeeCheckedinShiftDate:
        "Employee {name} checkedin shift {data} date {date}",
      waitingForApproveLeave:
        "Application {name} of employee {data} waiting for approve",
      waitingForApproveOvertime:
        "Application {name} of employee {data} waiting for approve",
      yourLeaveApproved:
        "Application {name} of employee {data} has been approved",
      yourOvertimeApproved:
        "Application {name} of employee {data} has been approved",
      yourLeaveDenied: "Application {name} of employee {data} has been denied",
      yourOvertimeDenied:
        "Application {name} of employee {data} has been denied",
      updateSuccess: "Update success",
    },
  },
  home: {
    menu: {
      home: "Home",
      userCares: "User cares",
      services: "Services",
      benefis: "Benefis",
      contact: "Contact",
      resources: "Resources",
    },
    banner: {
      title: "Smart Workplace Solutions | AI Camera",
      desc: "Explore the world without touch",
      slogan: "Check-in by your way!",
    },
    ourServices: {
      ourServices: "Our services",
      desc: "We Provide Best Quality Service",
      accessControl: "Access Control",
      accessControlDesc:
        "Impose policy to access location and information for employee and visitor.",
      timeAtendance: "Time Atendance",
      timeAtendanceDesc:
        "Setting working time as a shift, Record working time of each employee.",
      vmsSystem: "VMS System",
      vmsSystemDesc:
        "Event Creation, Self-register form Creation, Reminder Event via Email, Generate QR Code for Self-register.",
      analytics: "Analytics",
      analyticsDesc:
        "Face Recognition Analytic, Access Control Analytic, Expiration Alert, Generate report in Excel File.",
      integration: "Integration",
      integrationDesc: "Integration with Third Party Platform.",
    },
    keyBenefits: {
      title: "Key benefits",
      desc: "Check out benefits you'll get",
      affordable: "Affordable",
      affordableDesc: "Enterprise solutions that made available for everyone.",
      mutiTenant: "Multi-Tenant",
      mutiTenantDesc:
        "Custom an access for each tenant to manage venue, employee, event.",
      apisDriver: "APIs driver",
      apisDriverDesc: "Integrated with 3rd party platform.",
      analytics: "Analytics",
      analyticsDesc:
        "View overall system daily, weekly, monthly in timeline, table and graph.",
      cloudService: "Cloud service",
      cloudServiceDesc:
        "Management service is on the cloud, Dashboard can be accessed from any where.",
    },
    contactUs: {
      title: "Contact us",
      desc: "We are here to help you",
      slogan: "Viettel Construction | Create A New Life",
      address: "No 06 Phạm Van Bach, Yen Hoa, Cau Giay, Ha Noi.",
    },
    termscondition: "Terms & Condition",
    privacyPolicy: "Privacy Policy",
  },

  label: {
    idEmployee: "Employee code",
    nameEmployee: "Name employee",
    employee: "Employee",
    camera: "Camera",
    image: "Image",
    time: "Time",
    all: "All",
    stranger: "Stranger",
    approver: "Approver",
    guest: "Guest",
    start: "Start",
    end: "End",
    filter: "Filter",
    control: "Control",
    removeFromShift: "Remove from shift",
    addToShift: "Add to shift",
    totalRecord: "Total record: ",
    descriptionemployeeManager:
      "Manage employees of all departments and branches",
    descriptionAttendanceManagement: "Timekeeping for employees",
    descriptionShowListShift: "Manage shifts during the day",
    descriptionArrangeShifts: "Shift management",
    descriptionBranchManagement: "Manage branches located in your business",
    descriptionDepartmentManager: "Manage departments within your business",
    addNew: "Add new",
    export: "Export ",
    import: "Import",
    employeeCode: "Employee code",
    totalNumberOfEmployees: "Total number of employees:",
    fullName: "Full name",
    branch: "Branch",
    department: "Department",
    adminCompany: "Company admin",
    adminBranch: "Branch admin",
    admin: "Admin",
    role: "Role",
    jobPosition: "Job position",
    dateOfEntry: " Date of entry",
    phoneNumber: "Phone number",
    totalWorkShifts: "Total work shifts",
    statisticsToday: "Statistics today",
    working: "Working",
    late: "Late",
    absent: "Absent",
    takeLeave: "Take leave",
    birthday: "Birthday",
    toWork: "Start time",
    sessionWork: "Session work",
    endWork: "End time",
    minuteLate: "Minute late",
    minuteEarly: "Minute early",
    name: "Name",
    workingTime: "Working time",
    breakTimeBetweenShifts: "Break time between shifts",
    timeToAllowTimeAttendance: "Time to allow time attendance",
    timeToAllowTimekeeping: "Time to allow time keeping",
    loadShiftsInSeries: " Load shifts in series",
    extraWorkShifts: "Extra work shifts",
    defaultShift: "Default shift",
    delete: "Delete",
    address: "Address",
    area: "Area",
    total: "Total",
    dateTime: "Date time",
    addEdit: "Add/edit",
    save: "Save",
    areYouSure: "Are you sure",
    sureDeleteTheBranch: "Are you sure delete the branch?",
    iAgree: "I agree",
    type: "Type",
    mainWorkShift: "Main work shift",
    overtimeShift: "Over time shift",
    numberOfWorkdays: "Unit of work/day",
    timeToStartShift: "Time to start shift",
    endTimeOfShift: "End time of shift",
    thereIsABreakBetweenShift: "There is a break between shift",
    timeToStartABreakInTheMiddleOfTheShift:
      "Time to start a break in the middle of the shift",
    timeToApply: "Time to apply",
    timeCheckinLate: "Time check in late (minute)",
    timeCheckoutEarly: "Time check out early (minute)",
    endHourBreakBetweenShift: "End hour break between shift",
    repeatShifts: "Repeat shifts",
    staffInShift: "Staff in shift",
    check: "Check",
    operationTimeOfShift: "Operation time of shift",
    sureDeleteShift: "Are you sure delete this shift?",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    date: "Date",
    turnAudioOn: "Turn audio on:",
    notification: "Notification",
    checkAddApprover: "check Add Approver",
    notificationError: "Notification error",
    agree: "Agree",
    cancel: "Cancel",
    object: "Object",
    selectCamera: "Select camera",
    titleLogin: "Face recognition time attendance solution",
    user: "User",
    password: "Password",
    changePassword: "Change password",
    confirmPassword: "Confirm password",
    login: "Login",
    forGotPassword: "For got password",
    descCompany: "Copyright © 2021 Viettel Construction",
    search: "Search",
    timeDay: "Time day",
    deleteSuccess: "Delete success",
    close: "Close",
    errorMessage: "Error message!",
    addBranchSuccess: "Add branch success",
    editBranchSuccess: "Edit branch success",
    office: "office",
    dateStartWork: "Date start work",
    selectTime: "Select time",
    asc: "Ascending",
    desc: "Decrease",
    company: "Company",
    staffInformation: "Staff information",
    mainInformation: "Main information",
    male: "Male",
    female: "Female",
    gender: "Gender",
    dateOfBirth: "Date of birth",
    idCard: "ID card",
    asACollaborator: "As a collaborator",
    yes: "Yes",
    no: "No",
    bankAccountNumber: "Bank account number",
    personalImage: "Personal image",
    avatar: "Avatar",
    update: "Update",
    upload: "Upload",
    addStaff: "Add staff",
    checkInCode: "Check in code",
    edit: "Edit",
    sureDeleteMember: "Are you sure delete member?",
    staffsName: "Staffs name",
    browser: "Browser",
    reason: "Reason",
    status: "Status",
    reportType: "Report type",
    filterBy: "Filter by",
    report: "Report",
    chooseDate: "Choose date",
    chooseTime: "Choose time",
    exportReport: "Export report",
    moreHolidays: "More holidays",
    holidayList: "Holiday list",
    holiday: "holiday",
    nameHoliday: "Name holiday",
    enterHolidayName: "Enter holiday name",
    waitingForApprove: "Waiting for approve",
    pending: "Pending",
    approved: "Approved",
    approval: "approval",
    sureLeave: "Are you sure you want to delete this leave application?",
    sureMeal: "Are you sure you want to delete this meal voucher?",
    sureLeaveType: "Are you sure you want to delete this leave type?",
    sureApproveLeave:
      "Are you sure you want to approve this leave application?",
    sureDevice: "Are you sure you want to delete this device?",
    totalLeave: "Total leave:",
    approvalSuccess: "Approval success",
    addLeaveApplication: "Add leave application",
    addOverTime: "Add Over Time",
    addClaimTime: "Add claim time",
    reasonForLeave: "Reason for leave",
    reasonForOvertime: "Reason for overtime",
    selectStaff: "Select staff",
    checkedin: "Checked in",
    notCheckin: "Not checkin",
    on: "On",
    off: "Off",
    workDayDetail: "Work day",
    workDetail: "Work detail",
    checkinByDay: "Checkin by day",
    checkinMoreDay: "Checkin some days",
    checkinDetail: "Check in detail",
    mealsInMonth: "Meals in month",
    detailCheckin: "Detail checkin",
    generalReport: "General report",
    selectDateRange: "Select date range",
    allBranches: "All branches",
    totalDailyShifts: "Total daily shifts",
    applyPublicity: "Apply workday",
    comeBackBefore: "Leaving early",
    takeABreak: "Leave of absence",
    repeatWeekly: "Repeat weekly",
    setAsDefault: "Set as default",
    applyOvertime: "Apply overtime",
    itAHoliday: "It's a holiday",
    pleaseEnterEmployeeName: "Please enter employee name...",
    to: "to",
    sender: "Sender",
    weekly: "Weekly",
    default: "Default",
    learnMore: "Learn more",
    moreInformation: "More information",
    submitHome: "Sign up for a trial",
    turnInOut: "Number of passes",
    refuse: "Refuse",
    feedbackForm: "Feedback form",
    browseApplications: "Browse applications",
    chooseTheAmountOfWork: "Choose the amount of work",
    halfWork: "Half work",
    oneWork: "One work",
    sureClaimTime: "Are you sure you want to delete this claim? ",
    totalClaimTime: "Total number of compensation applications",
    userName: "User name",
    titleListCheckin: "List Checkin",
    taxCode: "Tax code",
    logout: "Logout",
    description: "Description",
    addCompany: "Add company",
    totalCompany: "Total company",
    monthlyStatistics: "Monthly statistics",
    leaveRemain: "Leave remain",
    workNumber: "Work number",
    workspaceTitle: "Workspace for you",
    personal: "Personal",
    personalInformation: "Personal information",
    timekeeping: "Timekeeping",
    leaveDetails: "Leave details",
    inoutDetails: "In Out details",
    oldPassword: "Old password",
    downloadFileTemplate: "Download file template",
    importUserFromFile: "Import user from file",
    searchUser: "Employee name or employee code",
    leave: "Leave",
    reasonDenied: "Reason denied",
    personalPage: "Personal page",
    moreDepartments: "More departments",
    nameBranch: "Branch name",
    chooseDepartment: "Choose department",
    noNodeChildren: "No child option",
    changeDepartment: "Change department",
    leaveType: "Leave type",
    totalLeaveType: "Total leave type",
    leaveTypeName: "Name of leave type",
    leaveTypeCode: "Code of leave type",
    chooseMonth: "Choose month",
    chooseYear: "Choose year",
    positionName: "Name of position",
    addPosition: "Add position",
    choosePosition: "Choose position",
    preview: "Preview",
    exportPdf: "Export to Pdf",
    exportExcel: "Export to excel",
    groupUser: "Group user",
    totalGroup: "Total groups",
    addGroupUser: "Add group user",
    groupName: "Group name",
    note: "Note",
    sureDeleteGroup: "Are you sure want to delete this group?",
    pint: "Print",
    annualLeave: "Annual leave",
    approveStep: "Approve step",
    addApproveStep: "Add approve step",
    userType: "User type",
    manager: "Manager",
    chef: "Chef",
    minuteOvertime: " Minute overtime",
    offWorkFrom: "Choose date overtime",
    reasonForOT: "Reason for overtime",
    importShiftFromFile: "Import shift from file",
    totalOvertime: "Total overtime",
    checkin: "Checkin",
    shift: "Shift",
    finishAtOverday: "Finish at over day",
    chooseShift: "Choose shift",
    employeeAddedToShift: "Employee was added to shift {name}",
    employeeRemovedFromShift: "Employee was removed from shift {name}",
    action: "Action",
    dateRegister: "Date register",
    quantity: "Quantity",
    operationDayOfMeal: "Day of week register",
    register: "Register",
    leaveReport: "Report leave",
    shiftReport: "Report shift",
    totalDevice: "Total devices",
    deviceCode: "Device code",
    deviceName: "Device name",
    deviceType: "Device type",
    camCheckin: "Camera checkin",
    camCheckout: "Camera checkout",
    camCheckinout: "Camera checkin and checkout",
    approveList: " Approve list",
    sureOvertime: "Are you sure delete this overtime?",
    content: "Content",
    importCheckinFromFile: "Import checkin from file",
    importLeaveFromFile: "Import leave from file",
    notifyHeader: "You have {count} notifications",
    deleteAll: "Delete all",
    addDepartment: "Add department",
    departmentName: "Department name",
    sureApproveOvertime: "Are you sure approve this overtime",
    workShiftNumber: "Number work shift",
    autoApprove: "Auto approve",
    device: "Device",
    meal: "Meal",
    mealInfo: "Meal information",
    mealDetail: "Meal detail",
    totalMeal: "Total meal",
  },
  formula: {
    dbError: "Database query error",
  },
};
