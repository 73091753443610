<template>
  <div class="row">
      <div class="card-body m-0 p-0">
          <div>
              <h3>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h3>
              <h3>{{ departmentName ? `Đơn vị: ${departmentName}` : '' }}</h3>
          </div>
          <div class="table-responsive" style="overflow-x: auto;">
              <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                  <tr v-for="(row, i) in modifiedPreviewData" :key="i"
                      :class="i >= (previewData.length - 2) ? 'tbl-footer' : ''">
                      <td v-if="i === 0" :colspan="row.length">{{ row[1] }}</td>
                      <template v-else>
                          <td v-for="(cell, j) in row" :key="j" :class="getClasses(cell, j, i)"
                              :rowspan="getRowspan(cell)" v-if="j > 0">
                              {{ cell }}
                          </td>
                      </template>
                  </tr>
              </table>
          </div>
          <div>
              <div style="display: flex;">
                  <div style="margin-left: 20%;">
                      <h4>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h4>
                  </div>
                  <div style="margin-left: 15%;">
                      <h4>THỦ TRƯỞNG ĐƠN VỊ</h4>
                  </div>
                  <div style="margin-left: 15%; text-align: center;">
                      <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
                      <h4>NGƯỜI KIỂM TRA</h4>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
      previewData: {
          type: [Array, Object]
      },
      departmentName: {
          type: String,
          required: true
      },
      departmentUnit: {
          type: String,
      },
      day: {
          type: Number,
          required: true
      },
      month: {
          type: String,
          required: true
      },
      year: {
          type: Number,
          required: true
      }
  },
  computed: {
      modifiedPreviewData() {
          const modifiedData = JSON.parse(JSON.stringify(this.previewData));

          modifiedData?.forEach((row, rowIndex) => {
              if (rowIndex === 2) {
                  row?.forEach((cell, cellIndex) => {
                      if (cellIndex === 0 || cellIndex === 1 || cellIndex === 2 || cellIndex === 3 || cellIndex === row.length - 1) {
                          modifiedData[rowIndex][cellIndex] = '';
                      }
                  });
              }
          });

          return modifiedData;
      }
  },
  data() {
      return {
          rowspanCount: 1,
          skipNextCell: false
      };
  },
  methods: {
      getClasses(cell, columnIndex, rowIndex) {
          if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ và tên') || cell.includes('Tổng công cơm ca'))) {
              return '';
          }
          return 'max-width-column';
      },
      getRowspan(cell, columnIndex, rowIndex) {

          if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ và tên') || cell.includes('Tổng công cơm ca'))) {
              return 1;
          }
          return 1;
      }

  }
}
</script>

<style scoped>
.row {
  width: 100%;
}

#tbl-preview {
  border: none;
}

#tbl-preview tr:nth-child(1) td {
  text-align: center;
  border: none;
  font-weight: bold;
  font-size: 22px;
}

#tbl-preview tr:nth-child(3) td:nth-child(-n+3) {
  border-top: none;
}

#tbl-preview tr:nth-child(3) td:last-child {
  border-top: none;
}

#tbl-preview tr:nth-child(2) td:nth-child(-n+3) {
  border-bottom: none;
}

#tbl-preview tr:nth-child(2) td:last-child {
  border-bottom: none;
}

.deleted-cell {
  display: none;
}

#tbl-preview tr:last-child {
  font-weight: bold;
}

#tbl-preview tr td {
  align-items: center;
  justify-content: center;
}

/* #tbl-preview tr td:nth-child(n+4):not(:last-child) {
  max-width: 0.vw;
} */

.center {
  align-items: center;
  justify-content: center;
}
</style>
