<template>
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title mt-2 mb-2">
            {{ $t("menu.mealRequest") }}
          </h1>
          <p class="text-muted">
            {{ $t("label.totalMeal") }}:  {{dataTable.length}}
          </p>
          <p class="">

          </p>
        </div>
        <div class="col-xs-12 col-md-12 text-right">
          <b-button
            class="btn btn-danger bg-gradient-danger"
            @click="showAddMeals()"
            ><font-awesome-icon icon="plus" /> {{ $t("label.register") }}</b-button>
        </div>
        <div class="col-xl-12 mt-4">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <b-table
                striped
                bordered
                class="default-table"
                hover
                :items="dataTable"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                responsive
                id="table-request-meal"
              >
                <template #cell(date)="data">
                  <template v-if="data.item.is_weekly == 1">
                    <div>
                      <span v-if="data.item.monday == 1"
                        >{{ $t("label.monday") }},</span
                      >
                      <span v-if="data.item.tuesday == 1"
                        >{{ $t("label.tuesday") }},</span
                      >
                      <span v-if="data.item.wednesday == 1"
                        >{{ $t("label.wednesday") }},</span
                      >
                      <span v-if="data.item.thursday == 1"
                        >{{ $t("label.thursday") }},</span
                      >
                      <span v-if="data.item.friday == 1"
                        >{{ $t("label.friday") }},</span
                      >
                      <span v-if="data.item.saturday == 1"
                        >{{ $t("label.saturday") }},</span
                      >
                      <span v-if="data.item.sunday == 1">{{
                        $t("label.sunday")
                      }}</span>
                    </div>
                    <h3 class="title">{{ $t("label.weekly") }}</h3>
                  </template>
                  <template v-else>
                    {{ convertDate(data.item.register_specify_date) }}
                  </template>
                </template>
                <template #cell(button)="data">
                  <b-button
                    @click="showEditMeals(data.item)"
                    :title="$t('label.edit')"
                    class="btn btn-primary btn-sm"
                    variant="primary"
                    depressed
                    :disabled="checkEdit(data.item)"
                    elevation="2"
                    v-b-tooltip.hover
                    ><font-awesome-icon icon="edit"
                  /></b-button>
                  <b-button
                    class="btn btn-warning btn-sm"
                    :title="$t('label.delete')"
                    v-b-tooltip.hover
                    @click="showDelete(data.item.id)"
                    depressed
                    elevation="2"
                    ><font-awesome-icon icon="trash"
                  /></b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataTable.length"
                :per-page="perPage"
                align="right"
                size="sm"
                class="m-4"
                aria-controls="table-inouthistory"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <b-modal
            v-model="modalAddMeal"
            ref="modal-add-shift"
            :title="$t('label.addEdit') + ' ' + $t('label.meal')"
            header-bg-variant="secondary"
            hide-footer
            centered
            size="lg"
          >
            <div class="row">
              <div class="card col-12 p-2 mb-2 border">
                <p>
                  <b-form-checkbox
                    v-model="is_weekly"
                    @change="setWeekly($event)"
                    >{{ $t("label.repeatWeekly") }}</b-form-checkbox
                  >
                </p>
                <b-form-group
                  :label="$t('label.operationDayOfMeal')"
                  :disabled="!is_weekly"
                >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_monday"
                    >{{ $t("label.monday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_tuesday"
                    >{{ $t("label.tuesday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_wednesday"
                    >{{ $t("label.wednesday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_thursday"
                    >{{ $t("label.thursday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_friday"
                    >{{ $t("label.friday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_saturday"
                    >{{ $t("label.saturday") }}</b-form-checkbox
                  >
                  <b-form-checkbox
                    class="custom-control-inline"
                    v-model="meal_sunday"
                    >{{ $t("label.sunday") }}</b-form-checkbox
                  >
                  <div class="error-message w-100 clear text-left" v-if="is_weekly">
                    {{ msg["dayOfWeek"] ? $t(msg["dayOfWeek"]) : "" }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div v-if="!is_weekly" class="row">
              <div class="card col-12 p-2 mb-2 border">
                <b-form-group
                  id="input-group-10"
                  :label="$t('label.dateRegister')"
                  class="required-mess"
                >
                  <flat-pickr
                    v-model="dateRegister"
                    :config="configDateTimePicker"
                    :disabled="is_weekly"
                    class="form-control"
                  ></flat-pickr>
                  <div class="error-message w-100 clear text-left">
                    {{ msg["dayOfWeek"] ? $t(msg["dayOfWeek"]) : "" }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="card col-12 p-2 mb-2 border">
                <b-form-group :label="$t('label.quantity')" class="required-mess">
                  <b-form-input
                    v-model="quantity"
                    type="number"
                    class="form-control-sm"
                    min="1"
                    required
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg["quantity"] ? $t(msg["quantity"]) : "" }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="mt-3 text-center">
              <b-button class="btn btn-danger" @click="saveMeals()">{{
                $t("label.save")
              }}</b-button>
              <b-button class="btn btn-default" @click="hideModalAddMeal">{{
                $t("label.cancel")
              }}</b-button>
            </div>
          </b-modal>
          <b-modal
            v-model="modalDelete"
            ref="modal-delete"
            hide-header
            hide-footer
            header-bg-variant="secondary"
            modal-class="modal-delete text-center"
            centered
            size="md"
          >
            <div>
              <div class="modal-delete--warning">
                <div class="modal-delete--warning__content">!</div>
              </div>
              <div>
                <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
              </div>
              <div>{{ $t("label.sureMeal") }}</div>
              <div class="mt-3">
                <b-button variant="primary" @click="deleteMeals(idDelete)"
                  >{{ $t("label.iAgree") }}
                </b-button>
                <b-button variant="secondary" @click="hideModalDelete"
                  >{{ $t("label.cancel") }}
                </b-button>
              </div>
            </div> </b-modal
          ><!-- modal delete  -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from "moment";

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      id: null,
      is_weekly: false,
      meal_monday: false,
      meal_tuesday: false,
      meal_wednesday: false,
      meal_thursday: false,
      meal_friday: false,
      meal_saturday: false,
      meal_sunday: false,
      dateRegister: null,
      quantity: null,
      disAdd: false,
      dataTable: [],
      idDelete: null,
      modalDelete: false,
      showDeleteSuccess: false,
      fields: [
        {
          label: this.$t("label.dateRegister"),
          key: "date",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.department"),
          key: "department",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.quantity"),
          key: "quantity",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.control"),
          key: "button",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 25,
      modalAddMeal: false,
      configDateTimePicker: {
        minDate: "today",
        dateFormat: "d/m/Y",
        mode: "single",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      msg: [],
    };
  },
  created() {
    let userType = this.$store.getters.userType;
    if (userType != 1) {
      this.$router.replace("/login");
    }
    this.checkAdd();
    this.getMeals();
  },
  methods: {
    showAddMeals() {
      this.id = null;
      this.is_weekly = true;
      this.dateRegister = null;
      this.quantity = 1;
      this.modalAddMeal = true;
      this.msg = [];
    },
    setWeekly(evt) {
      if (evt === true) {
        this.dateRegister = null;
      } else {
        this.meal_monday = false;
        this.meal_tuesday = false;
        this.meal_wednesday = false;
        this.meal_thursday = false;
        this.meal_friday = false;
        this.meal_saturday = false;
        this.meal_sunday = false;
      }
    },
    hideModalAddMeal() {
      this.modalAddMeal = false;
      this.msg = [];
    },
    async checkAdd() {
      let res;
        try {
          res = await apiServer.get("api/v1/user/meals-check", {});
        } catch (error) {
          Swal.fire({
  title: "Good job!",
  text: "You clicked the button!",
  icon: "success"
});
        }
        if (res && res.data) {
          if (res.data.isNotRequestYet == true){
            let currentTime = new Date();
            let validTime = new Date(
            currentTime.getFullYear() +
              "-" +
              currentTime.getMonth() +
              "-" +
              currentTime.getDate() +
              " 09:00:00"
          );
          if (currentTime.getTime() < validTime.getTime()) {
            this.disAdd = true;
          } else {
            this.disAdd = false;
          }
          }
        } else {
          this.disAdd = true;
        }
    },
    checkEdit(item) {
      let currentTime = new Date();
      let currentDate = currentTime.setHours(0, 0, 0, 0);
      if (item.is_weekly == 1) {
        return true;
      } else if (
        moment(item.register_specify_date).isSame(moment(currentDate))
      ) {
        let validTime = new Date(
          currentTime.getFullYear() +
            "-" +
            currentTime.getMonth() +
            "-" +
            currentTime.getDate() +
            " 09:00:00"
        );
        if (currentTime.getTime() < validTime.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showEditMeals(item) {
      this.id = item.id;
      this.is_weekly = item.is_weekly == 1 ? true : false;
      this.dateRegister = this.convertDate(item.register_specify_date);
      this.quantity = item.quantity;
      this.modalAddMeal = true;
    },
    async getMeals() {
      let res;
      try {
        res = await apiServer.get("api/v1/user/get-meals", {});
      } catch (error) {
        alert(error.response.data);
      }
      if (res && res.data) {
        this.dataTable = res.data;
      }
    },
    async saveMeals() {
      let dow = [
        this.meal_monday,
        this.meal_tuesday,
        this.meal_wednesday,
        this.meal_thursday,
        this.meal_friday,
        this.meal_saturday,
        this.meal_sunday,
      ];
      let res;
      try {
        res = await apiServer.post("api/v1/user/meals", {
          id: this.id,
          quantity: this.quantity,
          dayOfWeek: dow,
          registerSpecifyDate: this.convertTimestampSendDate(this.dateRegister),
        });
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 401) {
          alert(error.response.data);
        }
      }
      if (res && res.data) {
        this.hideModalAddMeal();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getMeals();
        this.checkAdd();
      }
    },
    showDelete(idDelete){
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    hideModalDelete(){
      this.$refs["modal-delete"].hide();
    },
    async deleteMeals(itemid) {
    let res;
      try {
        res = await apiServer.delete("api/v1/user/meals/" + itemid, {});
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 401) {
          alert(error.response.data);
        }
      }
      if (res && res.data) {
        this.modalDelete = false;
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getMeals();
        this.checkAdd();
      }
    },
    convertTimestampSendDate(dateString) {
      if (dateString == null || dateString == undefined) return null;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
  },
};
</script>
