<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.claimTimeSheetList") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalClaimTime") }} : {{ dataTable.length }}
              </p>
            </div>
            <div class="col-md-6 col-xs-12 text-right" v-if="userType == '2'">
              <div class="mt-4">
                <a href="javascript:void(0)" @click="showModalClaimTime()" class="btn btn-danger bg-gradient-danger">
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input type="text" :placeholder="$t('label.staffsName')" class="form-control" v-model="userName" />
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <flat-pickr v-model="dateSearch" :config="configDateSearch" :placeholder='$t("label.chooseDate")'
                  name="reportDate" class="form-control"></flat-pickr>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <select v-model="status" class="form-control">
                  <option v-for="status in listStatus" v-bind:value="status.value">
                    {{ status.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button variant="btn btn-info  btn-outline-primary w-100"
                @click="search('search')">{{ $t('label.search') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table bordered="bordered" class="default-table" hover :items="dataTable" :fields="fields"
                    responsive :current-page="currentPage" :per-page="perPage" @head-clicked="handleHeaderClick($event)"
                    id="table-inouthistory">
                    <template #cell(infoUser)="data">
                      <div class="row">
                        <!-- <div class="col-2 p-0">
                          <img
                            :src="data.item.avatar"
                            alt=""
                            height="90px"
                            width="80px"
                          />
                        </div> -->
                        <div class="col-10">
                          <small class="text-muted">{{ data.item.personID }}</small>
                          <p class="m-0">{{ data.item.fullName }}</p>
                          <small class="text-muted">{{ data.item.email }}</small>
                        </div>
                      </div>
                    </template>
                    <template #cell(browser)="data">
                      <p class="m-0">{{ data.item.userApprove }}</p>
                    </template>
                    <template #cell(feedbackForm)="data">
                      <div class="m-0">{{ $t('label.numberOfWorkdays') }} : {{ data.item.work_day }}</div>
                      <small class="text-danger">{{ $t('label.timeDay') }}:
                        {{ convertDate(data.item.compensation_date) }}
                      </small>
                    </template>
                    <template #cell(reason)="data">
                      {{ data.item.compensation_reason | truncate(40, '...') }}
                    </template>
                    <template #cell(status)="data">
                      <div class="m-0 text-white">
                        <template v-if="data.item.status == '0'">
                          <span style="
                            background-color: rgb(251 99 64);
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          ">{{ $t('label.pending') }}</span>
                        </template>
                        <template v-else-if="data.item.status == '1'">
                          <span style="
                            background-color: rgb(45 189 76);
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          ">{{ $t('label.approved') }}</span>
                        </template>
                        <template v-else>
                          <span style="
                            background-color: #EC1A43;
                            border-radius: 10px;
                            line-height: 20px;
                            font-weight: bold;
                            padding: 3px 10px;
                            font-size: 13px;
                          ">{{ $t('label.refuse') }}</span>
                        </template>

                      </div>
                    </template>
                    <template #cell(function)="data" v-if="userType == '2'">
                      <button class="btn btn-primary btn-sm" @click="showEdit(data.item.id)" v-b-tooltip.hover
                        :title="$t('label.browseApplications')"
                        :disabled="data.item.status == '2' || data.item.status == '1'">
                        <font-awesome-icon icon="edit" />
                      </button>
                      <button class="btn btn-danger btn-sm" @click="showDelete(data.item.id)" v-b-tooltip.hover
                        :title="$t('label.delete')" :disabled="data.item.status == '1'">
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="dataTable.length" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-inouthistory"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalDelete" ref="modal-delete" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureClaimTime") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeave(idDelete)">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal><!-- modal delete  -->
    <b-modal v-model="modalApproval" ref="modal-approval" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">

      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureApproveLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="approval('1')">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="primary" @click="approval('2')">{{ $t("label.refuse") }}
          </b-button>
          <b-button variant="secondary" @click="hidenModalApproval">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal><!-- modal modalApproval  -->
    <b-modal v-model="modalClaimTime" ref="modal-add-claim-time" :title="$t('label.addClaimTime')" hide-footer
      header-bg-variant="secondary" centered size="md">
      <b-form ref="form">
        <b-form-group id="input-group-1" :label="$t('label.selectStaff')">
          <v-select v-model="idPerson" :options="optionsIdPerson" label="text" :filterable="false"
            @search="changeIdPerson">
            <template slot="no-options">
              {{ $t('label.pleaseEnterEmployeeName') }}
            </template>
            <template v-slot:option="option">
              <div class="row show-user-list">
                <div class="col-2"><img :src="option.avatar" alt=""></div>
                <div class="col-10">
                  <h5>{{ option.text }}</h5>
                  <p>{{ option.email }}</p>
                </div>
              </div>
            </template>
          </v-select>
          <div class="error-message w-100 clear text-left">
            {{ msg['userId'] && msg['userId'][0] ? $t(msg['userId']) : '' }}
          </div>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('label.chooseDate')" label-for="input-2">
          <flat-pickr v-model="dateSelect" :config="configDateTimePicker" :placeholder='$t("label.chooseDate")'
            name="reportDate" class="form-control"></flat-pickr>
          <div class="error-message w-100 clear text-left">
            {{ msg['compensationDate'] && msg['compensationDate'][0] ? $t(msg['compensationDate']) : '' }}
          </div>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('label.chooseTheAmountOfWork')" label-for="input-2">
          <input type="radio" id="one" value="0.5" v-model="workDay">
          <label for="one" style="margin-right:15px;margin-left:5px;">{{ $t('label.halfWork') }}</label>
          <input type="radio" id="two" value="1" v-model="workDay">
          <label for="two" style="margin-left:5px;">{{ $t('label.oneWork') }}</label>
          <div class="error-message w-100 clear text-left">
            {{ msg['workDay'] && msg['workDay'][0] ? $t(msg['workDay']) : '' }}
          </div>
        </b-form-group>
        <b-form-group id="input-group-2" :label="$t('label.reasonForCompensation')" label-for="input-2">
          <b-form-input id="input-2" :placeholder="$t('label.reasonForCompensation')" required
            v-model="reasonForLeave"></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{ msg['compensationReason'] && msg['compensationReason'][0] ? $t(msg['compensationReason']) : '' }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="addClaimTime">{{
            $t('label.save')
          }}
          </b-button>
          <b-button class="btn btn-default" @click="hidemodalClaimTime">{{
            $t('label.cancel')
          }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal addClaimTime  -->
  </div>
</template>
<script>
import apiClient from "@/axios"
import apiServer from "@/axiosServer"
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import FlatpickrLanguages from "flatpickr/dist/l10n"
export default {
  components: {
    flatPickr
  },
  data() {
    return {
      modalDelete: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      showApprovalSuccess: false,
      modalApproval: false,
      modalClaimTime: false,
      fields: [
        {
          label: this.$t("label.staffsName"),
          key: "infoUser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.browser"),
          key: "browser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.feedbackForm"),
          key: "feedbackForm",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "status",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      infoSenderDetail: [],
      editCompensation: '',
      addNewsuccess: false,
      addNewError: false,
      reasonForLeave: '',
      idPerson: '',
      optionsIdPerson: [],
      dateSelect: '',
      dateSearch: '',
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      configDateSearch: {
        dateFormat: 'd/m/Y',
        maxDate: "today",
        mode: 'range',
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },

      options: [],
      idBranch: null,
      branchs: [],
      status: null,
      listStatus: [
        { text: this.$t('label.status'), value: null },
        { text: this.$t('label.pending'), value: 0 },
        { text: this.$t('label.approved'), value: 1 },
        { text: this.$t('label.refuse'), value: 2 }
      ],
      userName: null,
      msg: [],
      userType: null,
      workDay: '',
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        text = text.substring(0, length);
        text = text.substring(0, text.lastIndexOf(' '));
        return text + suffix;
      } else {
        return text;
      }
    },
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.getListClaimTime();
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
      this.configDateSearch.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },

    hidenModalApproval() {
      this.$refs["modal-approval"].hide();
    },

    hidemodalClaimTime() {
      this.$refs["modal-add-claim-time"].hide();
    },

    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    showEdit(id) {
      this.editCompensation = id;
      this.modalApproval = true;

    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
    showModalClaimTime() {
      this.dateSelect = '';
      this.idPerson = '';
      this.reasonForLeave = '';
      this.optionsIdPerson = [];
      this.modalClaimTime = true;
      this.msg = []
    },

    async showListUser(search, loading) {
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-user', {
          placeID: this.$store.getters.placeID,
          keyword: search
        })
      } catch (error) {
      }
      if (res && res.data) {
        if (res.data.length > 0) {
          this.optionsIdPerson = [];
          for (let user of res.data) {
            this.optionsIdPerson.push({ value: user.id, text: user.fullName, email: user.email, avatar: user.avatar });
          }
          loading(false);
        }
      }
    },

    changeIdPerson(search, loading) {
      if (search.length) {
        loading(true);
        this.showListUser(search, loading);
      }
    },
    async getListClaimTime() {
      let res;
      let df = null, dt = null;
      if (this.dateSearch !== undefined && this.dateSearch !== '') {
        let dateArr = this.dateSearch.split(' ');
        df = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          dt = df;
        }
        else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      try {
        res = await apiServer.post('/api/v1/compensation/get-list', {
          userName: this.userName,
          status: this.status,
          fromDate: df,
          toDate: dt,
        });
        if (res && res.data) {
          this.dataTable = res.data
        }
      }
      catch (e) {
        console.log(e);
      }
    },

    async deleteLeave(id) {
      let res;
      try {
        res = await apiServer.post('/api/v1/compensation/delete', {
          id: id,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t('Xóa thành công'),
            position: 'bottom-center'
          });
          this.getListClaimTime();
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async approval(status) {
      let res;
      try {
        res = await apiServer.post('/api/v1/compensation/approve', {
          id: this.editCompensation,
          status: status,
        });
        if (res && res.data) {
          this.getListClaimTime();
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position: 'bottom-center'
          });
          this.modalApproval = false;
        }
      }
      catch (e) {
      }
    },

    async addClaimTime() {
      let res;
      let date = null;
      if (this.dateSelect != undefined & this.dateSelect != '') {
        date = this.convertTimestampSendDate(this.dateSelect);
      }
      let user_id = (this.idPerson != null && this.idPerson != "") ? this.idPerson.value : null;
      try {
        res = await apiServer.post('/api/v1/compensation/add', {
          userId: user_id,
          compensationDate: date,
          compensationReason: this.reasonForLeave,
          workDay: this.workDay,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalClaimTime = false;
            this.$notify({
              group: 'bottomnotification',
              title: this.$t('label.notification'),
              text: this.$t(res.data.message),
              position: 'bottom-center'
            });
            this.getListClaimTime();
          }

        }

      }
      catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(this.$t(error.response.data))
        }
      }

    },

    async getListBranch() {
      let res;
      try {
        res = await apiServer.post('/api/v1/company/get-all-branch', {});
        if (res && res.data) {
          this.branchs.push({ value: null, text: this.$t('label.allBranches') });
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    },

    search() {
      this.getListClaimTime();
    },

    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return '';
      return moment(inputDate).format("DD/MM/yyyy");
    },

    tookABreak(dateId) {
      let today = new Date()
      let dt = new Date(dateId);
      let dat2 = today.getTime() - dt.getTime();
      let dat3 = Math.ceil(dat2 / (1000 * 60 * 60 * 24));
      return dat3;
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD');
    },



  }
};
</script>

<style lang="scss" scoped>
.show-user-list {
  padding: 5px 0;

  .col-2 {
    img {
      height: 50px;
    }
  }

  .col-10 {
    padding: 0;

    h5 {
      margin-bottom: 0;
    }

    p {
      color: #333;
      margin-bottom: 0;
    }

    &:hover {
      h5 {
        color: #fff;
      }

      p {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }

  &:hover {
    outline: none;
  }
}

.table p {
  word-wrap: break-word;
  white-space: break-spaces;
}
</style>
