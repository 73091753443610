<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("menu.cancelMeal") }}
              </h1>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div class="mt-4">
                <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="cancelMeal">
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.cancelMeal") }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12 mt-2">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <treeselect v-model="departmentId" :multiple="false" :options="departmentOption"
                :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.chooseDepartment')" @input="getUnregisterMealList" />
            </div>
            <div class="col-xs-12 col-md-3">
              <b-form-group>
                <select v-model="employeeSelect" class="form-control">
                  <option :value="null" disabled selected hidden style="color: #6c757d !important">{{
                    $t('label.chooseEmployee') }}</option>
                  <option v-for="m in employeeList" :value="m">
                    {{ m.aliasID }} - {{ m.fullName }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <!-- <div class="col-xs-12 col-md-3">
              <flat-pickr v-model="dateSelect" :config="configDateTimePicker"
                :placeholder='$t("label.selectDateCancelMeal")' name="cancelDate" class="form-control"></flat-pickr>
            </div> -->
            <!-- <div class="col-md-3 col-xs-12">
              <b-button variant="btn btn-info  btn-outline-primary w-100" @click="unregisterMeal()">{{
                $t("label.confirm") }}
              </b-button>
            </div> -->
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <h2 class="text-center">{{ $t("label.cancelMealList") }}</h2>
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table striped bordered class="default-table" hover :items="dataTable" :fields="fields" responsive
                    id="table-employee" show-empty>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox @click.native.stop :value="data.item.id" v-model="userSelected">
                      </b-form-checkbox>
                    </template>
                    <!-- <template #cell(avatar)="data">
                      <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                        <img width="75px" height="75px" style="border-radius: 8px; max-width: 75px"
                          :src="data.item.avatar" :alt="data.item.username" />
                      </a>
                    </template> -->
                    <template #cell(dateStartWork)="data">
                      {{ convertDate(data.item.dateStartWork) }}
                    </template>
                    <template #cell(fullName)="data">
                      {{ data.item.fullName }}
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="totalUser" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-employee"
                    v-on:click.native="getUnregisterMealList()"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <b-modal v-model="modalCancelMeal" ref="modal-cancel-meal" :title="$t('label.cancelMeal')"
            header-bg-variant="secondary" hide-footer centered size="xl">
            <div class="row">
              <div class="col-xs-4 col-md-4">
                <treeselect v-model="departmentIdForm" :multiple="false" :options="departmentOption"
                  :default-expand-level="Infinity" :noChildrenText="$t('label.noNodeChildren')"
                  :placeholder="$t('label.chooseDepartment')" @input="getEmployee1()"/>
              </div>
              <div class="col-xs-4 col-md-4">
                <b-form-group>
                  <select v-model="employeeSelectForm" class="form-control" @change="getUnregisterMealsByUser()">
                    <option :value="null" disabled selected hidden style="color: #6c757d !important">{{
                      $t('label.chooseEmployee') }}</option>
                    <option v-for="m in employeeListForm" :value="m">
                      {{ m.aliasID }} - {{ m.fullName }}
                    </option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-xs-4 col-md-4">
                <flat-pickr v-model="dateSelectForm" :config="configDateTimePicker"
                  :placeholder='$t("label.selectDateCancelMeal")' name="cancelDate" class="form-control"></flat-pickr>
              </div>
              <!-- <div class="col-xs-12 col-md-12 text-center">
                <ColorCalendar />
              </div> -->

              <!-- <div class="col-xs-12 col-md-12">
                <flat-pickr v-model="dateSelect" :config="configDateTimePicker"
                  :placeholder='$t("label.selectDateCancelMeal")' name="cancelDate" class="form-control"></flat-pickr>
              </div> -->
            </div>
            <div class="d-block text-center mt-3">
              <div class="text-center">
                <b-button variant="btn btn-info  btn-outline-primary" @click="unregisterMeal()">{{
                    $t("label.confirm") }}
                  </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PulseLoader from "@/components/PulseLoader.vue";
import flatPickr from 'vue-flatpickr-component';
import FlatpickrLanguages from "flatpickr/dist/l10n"
import ColorCalendar from "@/components/ColorCalendar.vue";

export default {
  components: {
    Treeselect,
    PulseLoader,
    flatPickr,
    ColorCalendar,
  },
  data() {
    return {
      placeAdmin: false,
      loading: false,
      urlImage: "",
      modalImage: false,
      modalChangePassword: false,
      modalDelete: false,
      idChangePassword: "",
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: "",
      idDelete: "",
      totalUser: 0,
      dataTable: [],
      idUser: "",
      newPassWord: "",
      confirmPassword: "",
      currentPage: 1,
      newDateStartWork: "",
      imageAvatar: null,
      userName: null,
      branch: null,
      branchs: [],
      msg: [],
      modalAlert: false,
      perPage: 25,
      fields: [
        // {
        //   label: this.$t("label.image"),
        //   key: "avatar",
        //   class: "align-middle ",
        //   sortable: false,
        // },
        {
          label: this.$t("label.employeeCode"),
          key: "aliasID",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fullName"),
          key: "fullName",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.jobPosition"),
          key: "position",
          class: "align-middle",
          sortable: false,
        },
        // {
        //   label: "Email",
        //   key: "email",
        //   class: "align-middle",
        //   sortable: false,
        // },
        {
          label: this.$t("label.department"),
          key: "department",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.unregisterDate"),
          key: "unregister_date",
          class: "align-middle",
          sortable: false,
        },
      ],
      treeData: null,
      options: [],
      userSelected: [],
      allSelected: false,
      modalMoveDepart: false,
      movedValue: null,
      departmentOption: [],
      departmentId: null,
      department: null,
      positionId: null,
      positionOption: [],
      configDateTimePicker: {
        wrap: true,
        open: true,
        dateFormat: 'Y-m-d',
        mode: "multiple",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      dateSelect: [],
      _dateSelect: [],
      employeeList: [],
      employeeSelect: null,
      disable: [],
      modalCancelMeal: false,
      date: null,
      dateSelectForm: null,
      employeeSelectForm: null,
      departmentIdForm: null,
      employeeListForm: null,
    };
  },
  created() {
    if (
      this.$store.getters.superAdmin !== true &&
      this.$store.getters.comAdmin !== true
    ) {
      this.placeAdmin = true;
    }
  },
  mounted() {
    this.getDepartment();
    this.getUnregisterMealList();
    // this.getEmployee();
    //this.getListBranch();
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  methods: {
    async getDepartment() {
      this.departmentOption = []
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
        console.log(res.data)
      }
    },
    changeBranch() {
      this.getDepartment();
      this.departmentId = null;
      this.getPosition();
      this.positionId = null;
    },
    async getPosition() {
      let res
      try {
        res = await apiServer.post('api/v1/position/list', {
          placeId: this.placeId
        })
      } catch (error) { }
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    async getEmployee() {
      this.loading = true;
      if (this.departmentId && this.departmentId.length == 0) {
        this.departmentId = null;
      }
      let res;
      try {
        res = await apiServer.post("api/v1/user/get-list-users-v2", {
          department: this.departmentId,
        });
      } catch (error) {
        this.loading = false;
        console.error('Error calling API:', error);
      }
      if (res && res.data) {
        this.employeeList = res.data.items;
      }
      this.loading = false;
    },
    async getEmployee1() {
      this.loading = true;
      if (this.departmentIdForm && this.departmentIdForm.length == 0) {
        this.departmentIdForm = null;
      }
      let res;
      try {
        res = await apiServer.post("api/v1/user/get-list-users-v2", {
          department: this.departmentIdForm,
        });
      } catch (error) {
        this.loading = false;
        console.error('Error calling API:', error);
      }
      if (res && res.data) {
        this.employeeListForm = res.data.items;
      }
      this.loading = false;
    },
    async unregisterMeal() {
      this.loading = true;
      if (this.dateSelectForm.length > 0) {
        this._dateSelect = this.dateSelectForm.split(",");
        this._dateSelect = this._dateSelect.map(date => date.trim());
      }
      let res;
      try {
        res = await apiServer.post("/api/v1/unregister-meal", {
          department: this.departmentIdForm,
          aliasID: this.employeeSelectForm.aliasID,
          personID: this.employeeSelectForm.personID,
          dateID: this._dateSelect,
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.getUnregisterMealsByUser()
      this.dateSelectForm = null;
      this.getUnregisterMealList();
      this.loading = false;
    },
    async getUnregisterMealList() {
      console.log(this.departmentId)
      console.log(this.departmentOption)
      this.loading = true;
      this.getEmployee()
      if (this.branch != null && this.branch.value != "") {
        placeID = [this.branch];
      }
      if (this.departmentId && this.departmentId.length == 0) {
        this.departmentId = null;
      }
      this.dataTable = [];
      let res;
      try {
        res = await apiServer.post("api/v1/get-list-unregister-meals", {
          department: this.departmentId,
          aliasID: this.employeeSelect?.aliasID,
          personID: this.employeeSelect?.personID,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
        });
      } catch (error) {
        this.loading = false;
        console.error('Error calling API:', error);
      }
      if (res && res.data) {
        this.dataTable = res.data.items;
      }
      this.loading = false;
    },
    async getUnregisterMealsByUser() {
      this.loading = true;
      let res;
      try {
        res = await apiServer.post("api/v1/get-unregister-meal-date", {
          department: this.departmentIdForm,
          aliasID: this.employeeSelectForm.aliasID,
          personID: this.employeeSelectForm.personID,
        });
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
      if (res && res.data) {
        this.disable = res.data.items;
        this.configDateTimePicker = {
          ...this.configDateTimePicker,
          disable: res.data.items
        }
      }
      this.loading = false;
    },
    cancelMeal() {
      this.modalCancelMeal = true;
    }
  },
};
</script>
<style lang="scss">
$mobile-width: 480px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

table {
  tr {
    color: #000;
  }
}

#main-default.container-fluid {
  padding: 15px 30px 30px 30px !important;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 400px;
}

.btn {
  font-weight: normal;
}

.default-table {
  thead {
    tr {
      background: #f1f1f1;

      th {
        text-transform: none;
        font-size: 15px;
        padding-top: 18px;
        padding-bottom: 18px;
        letter-spacing: 0;
      }
    }
  }

  td {
    font-size: 15px !important;
  }
}

.btn-sm {
  font-size: 15px !important;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle,
.btn-danger {
  background-color: #ec1a43 !important;
  border-color: #ec1a43 !important;
}

.table-hover tbody tr:hover {
  color: #000 !important;
  background-color: #ffefec !important;
}

.filter-asc,
.filter-desc {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.filter-asc {
  margin-bottom: 1px;
  border-bottom: 5px solid #ccc;
}

.img-profile {
  width: 60px !important;
  border-radius: 10px;
  border: 1px solid #dedede;
}

.table-small td {
  font-size: 11px !important;
  padding: 5px !important;
  vertical-align: middle !important;
}

.modal-image {
  img {
    max-height: 60vh;
    width: auto;
  }

  @include mobile {
    .modal-dialog {
      width: 96vw;

      img {
        height: auto;
        width: 80vw;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
}

.vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
  padding: 9px !important;
}

.vue-treeselect__placeholder {
  padding: 7px;
}

.vue-treeselect--searchable .vue-treeselect__input-container {
  padding: 0;
}

.vue-treeselect--has-value .vue-treeselect__value-container {
  padding: 4px !important;
}

.vue-treeselect__single-value {
  line-height: 44px !important;
}
</style>
