<template>
  <!-- Page content -->
    <div id="main-default" class="container-fluid" style="padding:0px !important" >
      <div class="card col-xl-12">
        <div class="row">
          <div class="col-md-6 row mt-3"  v-if="userType == '2' || userType == '3'">
              <div class="col-xl-12">
              <h1 class="page-title mt-2">
                {{ $t('menu.workSchedule') }}
              </h1>
              </div>
 
            <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="departmentid" :multiple="false" 
                :options="departmentOption" :default-expand-level="Infinity"
                @input="changeDepartment"
                :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
              </div>
              <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="shiftid" :multiple="false" :options="shiftOption" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.filterByuser')" 
                          @input="changeDepartment"
                       />
                
              </div>
            <div class="col-xs-6 col-md-6">
                    <b-form-input v-model="aliasId" :placeholder=" $t('label.employeeCode')" @input="changeDepartment" type="text">
                    </b-form-input>
            </div>
            <div class="col-xs-6 col-md-6">
              <b-form-group>
                <div class="autocomplete" v-click-outside="handleClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder=" $t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>

          </div>
          <div class="col-md-12 row mt-3"  v-if="userType == 1">
              <div class="col-xl-12">
              <h1 class="page-title mt-2">
                {{ $t('menu.workSchedule') }}
              </h1>
              </div>
 
            <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="departmentid" :multiple="false" 
                :options="departmentOption" :default-expand-level="Infinity"
                @input="changeDepartment"
                :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
              </div>
              <div class="col-xs-12 col-md-6 my-2">
                <treeselect v-model="shiftid" :multiple="false" :options="shiftOption" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.filterByuser')" 
                          @input="changeDepartment"

                          />
                
              </div>
            <div class="col-xs-6 col-md-6">
                    <b-form-input v-model="aliasId" :placeholder=" $t('label.employeeCode')" @input="changeDepartment" type="text">
                    </b-form-input>
            </div>
            <div class="col-xs-6 col-md-6">
              <b-form-group>
                <div class="autocomplete" v-click-outside="handleClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder=" $t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
            </div>

          </div>
          <div class="col-md-6 mt-2" v-if="userType ==2 || userType == '3'">
            <h3 class="">Thiết lập ca</h3>

            <div class="row align-items-center justify-content-center">
              <div class="col-md-4 col-xs-12 mb-2">
                <label>Loại ca</label>
                <treeselect v-model="shiftsId" :multiple="false" :options="shiftsOptions" :flat="false"
                          :default-expand-level="1" :placeholder="$t('label.chooseShift')" 
                          />
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.start') }}</label>
                <flat-pickr v-model="startDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{ $t('label.end') }}</label>
                <flat-pickr v-model="endDateString" :config="configDateTimePicker" 
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              
              <div class="col-md-12 col-xs-12 my-2">
                  <div class="">
                      <div class="text-right" >
                        <a
                          href="javascript:void(0)"
                          class="btn btn-danger bg-gradient-danger"
                          @click="addWorkschedule()"
                          ><font-awesome-icon icon="plus" /> {{ $t("label.addNew") }}</a
                        >
                          <!-- <button class="btn btn-success bg-gradient-success btn-block" @click="addWorkschedule()">Thêm mới ca</button> -->
                      </div>
                      <!-- <div class="col-md-3">
                          <button class="btn btn-success btn-block">Lưu</button>
                      </div> -->
                      <!-- <div class="col-md-4">
                          <button class="btn btn-danger bg-gradient-danger btn-block">Xóa</button>
                      </div> -->
                  </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row mt-3">
            <div class=" col-xs-12 col-md-6">
                <div class="card">
                  <div class="card-body m-0 p-0 table-responsive">
                    <h2 class="ml-2">Danh sách nhân sự</h2>
                    <b-table
                      bordered="bordered"
                      style="max-height: 500px;"
                      class=" default-table"
                      hover
                      :items="dataTable"
                      :fields="fields"
                      responsive
                      id="table-staff"
                      @row-clicked="rowClickHandler"
                      @head-clicked="handleHeaderClick($event)"
                    >
                    <template #head(checkbox)="data" >
                      <b-form-checkbox
                        @click.native.stop
                        @change="selectAll(data)"
                        v-model="allSelected"
                      >
                      </b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="data">
                      <b-form-checkbox
                        @click.native.stop
                        :value="data.item.aliasID"
                        v-model="userSelected"

                      >
                      </b-form-checkbox>
                    </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      align="right"
                      size="sm"
                      class="m-4"
                      aria-controls="table-staff"
                      v-on:click.native="getEmployee()"
                    ></b-pagination>
                  </div>
                </div>
              </div>
              <div class=" col-xs-12 col-md-6">
                <div class="card">
                  <div class="card-body m-0 p-0 table-responsive">
                    <h2 class="ml-2">Lịch sử ca áp dụng {{nameEmployee}}</h2>
                    <b-table
                      bordered="bordered"
                      style="max-height: 500px;"
                      class="default-table"
                      hover
                      :items="dataTableHistory"
                      :fields="fieldsHisotry"
                      responsive
                      :current-page="currentPageHistory"
                      :per-page="perPage"
                      id="table-shifthistory"
                      show-empty
                      empty-text="Không có dữ liệu"
                      @head-clicked="handleHeaderClickDetails($event)"
                    >
                      <template #cell(created_date)="data">
                        {{convertDate(data.item.created_date)}}
                      </template>
                      <template #cell(action)="data">
                          <span>{{$t(data.item.action, { name: data.item.shifts_name })}}</span>
                      </template>
                     
                    </b-table>
                    <b-pagination
                      v-model="currentPageHistory"
                      :total-rows="dataTableHistory.length"
                      :per-page="perPageHistory"
                      align="right"
                      size="sm"
                      class="m-4"
                      aria-controls="table-inouthistory"
                      v-on:click.native="getShiftHistory(this.employeeId)"
                    ></b-pagination>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  </template>
  <script lang="js">
  import apiClient from '@/axios'
  import apiServer from '@/axiosServer'
  import moment from 'moment'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import PulseLoader from '@/components/PulseLoader.vue'
  import FlatpickrLanguages from "flatpickr/dist/l10n"
  import flatPickr from 'vue-flatpickr-component'
  import 'flatpickr/dist/flatpickr.css'
  
  export default {
    components: { Treeselect, PulseLoader,flatPickr },
    data () {
      return {
          userType: null,
          currentPageHistory: 1,
          perPageHistory: 25,
          loading: false,
          dataTable: [],
          perPage: 25,
          shiftsId: null,
          currentPage: 1,
          allSelected: false,
          totalRecord: 0,
          nameEmployee: '',
        fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
          {
            label: this.$t('label.idEmployee'),
            key: 'aliasID',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.fullName'),
            key: 'fullName',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.jobPosition'),
            key: 'position',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.department'),
            key: 'department',
            class: 'align-middle',
            sortable: false
          }
        ],
        userSelected: [],
        startDateString: '',
        endDateString: '',
        configDateTimePicker: {
        // minDate: "today",
        dateFormat: 'd/m/Y',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
        
      },
      configDateTimePickerEnddate: {
        // enableTime: true,

        dateFormat: 'd/m/Y',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
        
      },
      nameResults: [],
      fullName: null,
      isOpenNameRes: false,
        dataTableHistory: [],
        fieldsHisotry: [
        {
            label: this.$t('label.creatat'),
            key: 'date_created_at',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.effect'),
            key: 'date',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.shiftName'),
            key: 'shifts_name',
            class: 'align-middle',
            sortable: false
          }
          ],
        departmentid: null,
        shiftid: null,
        aliasId: null,
        departmentOption: [],
        shiftOption: [ {"id": null,
        "label": 'Tất cả'},
        {"id": 0,
        "label": 'Chưa có ca'},
        {"id": 1,
        "label": 'Đã có ca'} ],
        shiftsOptions : [],
        employeeId: null,
      }
    },
    mounted () {
      this.userType = this.$store.getters.userType
      this.getDepartment();
      this.getEmployee();
      this.getShiftoption();
      this.startDateString = this.getNowDate() 
      this.endDateString = this.getNowDate() 
      if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
    },
    methods: {
      async getDepartment() {
        let res
        try {
          res = await apiServer.post('api/v1/department/list', {})
        } catch (error) {}
        if (res && res.data) {
          this.departmentOption = res.data;
        }
      },
      selectAll() {
      if (this.allSelected == true) {
        this.userSelected = this.dataTable.map((x) => x.aliasID);
      } else {
        this.userSelected = [];
      }
    },
      async getShiftoption() {
        let res
        try {
          res = await apiServer.post('/api/v1/shifts/get', {})
        } catch (error) {}
        if (res && res.data) {
          this.shiftsOptions = res.data;
        }
      },
      getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    getTomorrowDate() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const dd = String(tomorrow.getDate()).padStart(2, '0');
      const mm = String(tomorrow.getMonth() + 1).padStart(2, '0');
      const yyyy = tomorrow.getFullYear();
      return `${dd}/${mm}/${yyyy}`;
    },
    convertTimestampSendDate (date) {
      if (date == undefined || date == null){
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
    handleHeaderClickDetails(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
      async getEmployee () {
        this.loading = true;
        let placeID = this.$store.getters.placeID;
        let dpid = (this.departmentid != null && this.departmentid != undefined) ?
        [this.departmentid] : null;
        this.dataTableHistory = [];
        this.dataTable = [];
        let res
        try {
          res = await apiServer.post('api/v1/user/get-list-user', {
            placeID: placeID,
            aliasID: this.aliasId,
            userName: this.userName,
            department: dpid,
            currentPage: this.currentPage,
            rowPerPage: this.perPage,
            hadAShift: this.shiftid
          })
        } catch (error) {
          this.loading = false;
        }
        if (res && res.data) {
            this.dataTable = res.data.data.results;
            this.totalRecord = res.data.data.totalRecord;
            this.loading = false;
        }
      },
      async addWorkschedule() {
        if (this.shiftsId == null || this.shiftsId == undefined) {
          alert(this.$t("validate.message.shiftNameRequired"));
          return false;
        }
        console.log(this.userSelected);
        if (this.userSelected.length == 0 || this.userSelected == undefined) {
          alert(this.$t("Chưa có nhân viên được chọn"));
          return false;
        }
        let res;
        try {
          res = await apiServer.post("api/v1/shifts/add-user-to-shift", {
            toDate:  this.convertTimestampSendDate(this.endDateString),
            fromDate: this.convertTimestampSendDate(this.startDateString),
            shiftId: this.shiftsId,
            aliasID: this.userSelected,
          });
        } catch (error) {
        }
        if (res && res.data) {
          this.userSelected = false;
          await this.getShiftHistory(this.employeeId);
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
        }
        this.userSelected = [];
    },
      async getShiftHistory(uid){
        this.dataTableHistory = [];
        let res
        try {
          res = await apiServer.post('api/v1/shift/history/get-by-user', {
            aliasID: uid,
            currentPage : this.currentPageHistory,
            perPage :this.perPageHistory
          })
        } catch (error) {}
        if (res && res.data) {
          this.dataTableHistory = res.data;
        }
      },
      handleClickOutside() {
      this.isOpenNameRes = false;
    },
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
      setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenNameRes = false;
      setTimeout(() => {this.getEmployee()}, 500);
    },
      changeDepartment(){
          setTimeout(() => {this.getEmployee()}, 500);
      },
      convertDate(inputDate) {
        if (inputDate == '' || inputDate == null) return;
        return  moment(inputDate).format("DD/MM/yyyy HH:mm:ss");
      },
      rowClickHandler(record, index) {
        this.nameEmployee = record.fullName
        this.employeeId = record.aliasID
        this.getShiftHistory(this.employeeId);
      }
    }
  }
  </script>