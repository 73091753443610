<template>
  <div class="container-login100">
    <div class="section section-shaped section-lg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="card shadow border">
              <div class="card-header  text-center">
                <img src="../assets/img/brand/logo.png" alt="..." class="navbar-brand-img">
              </div>
              <div class="card-body bg-secondary">
                <div class="text-center text-muted mb-4">{{$t('label.titleLogin')}}</div>
                  <div class="form-group">
                    <input type="text" class="form-control form-control-alternative" :placeholder="$t('label.user')" v-model="username" v-on:keyup.enter="signIn()"/>
                    <div class="error-message w-100 clear text-left">
                      {{ msg['username'] ? $t(msg['username'][0]) : ''  }}
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control form-control-alternative" v-model="password" v-on:keyup.enter="signIn()" :placeholder="$t('label.password')"/>
                    <div class="error-message w-100 clear text-left">
                      {{ msg['password'] ? $t(msg['password'][0]) : ''  }}
                    </div>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn btn-danger btn-block" v-on:click="signIn()">
                      {{$t('label.login')}}
                    </button>
                  </div>
                  <div class="mt-3 text-center">
                    <a href="#">
                      {{$t('label.forGotPassword')}}
                    </a>
                  </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 text-center">
                <div>
                  <div class="mb-2">
                    <a href="javascript:void(0)" value="vi" class="mr-3" @click="changeLocale('vi')">
                      <img src="../assets/img/icons/ensign/vi.png" alt="" class="border" style="width: 32px;"> Tiếng
                      Việt
                    </a>
                    <a href="javascript:void(0)" value="en" class="mr-3" @click="changeLocale('en')">
                      <img src="../assets/img/icons/ensign/en.png" alt="" class="border" style="width: 32px;"> English
                      (United States)
                    </a>
                    <!-- <a href="javascript:void(0)" value="ja" class="mr-3">
                      <img src="../assets/img/icons/ensign/thailand.png" alt="" class="border" style="width: 32px;"> ไทย
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 text-center">
              {{$t('label.descCompany')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from '@/axiosServer'
import axios from 'axios';
export default {
  data () {
    return {
      userToken : null,
      msg: [],
      username: '',
      password: '',
      ipUser: '',
    }
  },
  mounted () {
    this.$store.commit('doLogout')
  },
  created() {
    this.username = this.$route.query.userName;
    this.userToken = this.$route.query.userToken;
    this.getClientIP();
    this.beforeLogin();

  },
  methods: {
    async getClientIP() {
      try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        return this.ipUser = response.data.ip;
      } catch (error) {
        console.error('Error fetching client IP:', error);
        return null;
      }
    },
    async beforeLogin () {
      let res
      if (this.userToken){
        try {

        res = await apiServer.post('/api/v1/auth/login', {
          username: this.username,
          userToken: this.userToken
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } 
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
      if (res && res.data && res.data.status === 200) {
        this.$store.commit('saveJwt', res.headers.authorization)
        this.$store.commit('doLogin', {...res.data.userInfo, ipUser: this.ipUser})

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect.toString())
        } else {
          if (this.$store.getters.role !== undefined) {
          //   if (this.$store.getters.userType == 1 || (this.$store.getters.userType) == 2 ) {
          //   this.$router.push('/' + 'admin');
          // }
          //  if (this.$store.getters.userType == 0)  {
          //   this.$router.push('/' + 'employee');
          //   }
            this.$router.push('/' + this.$store.getters.role);
          } else {
            this.$router.push('/welcome');
          }
        }
      }
      }
      else {
        console.log("Before login: err");
      }
    },
    async signIn () {
      this.msg = []
      let res

      try {
        res = await apiServer.post('/api/v1/auth/login', {
          username: this.username,
          password: this.password
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } 
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
      if (res && res.data && res.data.status === 200) {
        this.$store.commit('saveJwt', res.headers.authorization)
        this.$store.commit('doLogin', {...res.data.userInfo, ipUser: this.ipUser})


        // console.log(this.$store.getters.userType);

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect.toString())
        } else {
          if (this.$store.getters.role !== undefined) {
          //   if (this.$store.getters.userType == 1 || (this.$store.getters.userType) == 2 ) {
          //   this.$router.push('/' + 'admin');
          // }
          //  if (this.$store.getters.userType == 0)  {
          //   this.$router.push('/' + 'employee');
          //   }
            this.$router.push('/' + this.$store.getters.role);
          } else {
            this.$router.push('/welcome');
          }
        }
      }
    },
    changeLocale(locale) {
      this.$store.commit("updateLocale", locale)
      location.reload()
    }
  }

}
</script>
<style scoped lang="scss">
  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .navbar-brand-img {
    width: 150px;
  }

</style>
