<template>
    <!-- Page content -->
    <div class="container-fluid mt-3">
        <div class="row">
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-md-6 col-xs-12">
                        <h1 class="page-title"><font-awesome-icon icon="book" /> {{ $t('menu.arrangeShifts') }}</h1>
                        <p>{{ $t("label.descriptionArrangeShifts") }}</p>
                    </div>
                    <div class="col-md-6 col-xs-12 text-right">
                        <div  class="mt-3">
                            <button class="btn btn-danger bg-gradient-danger"><font-awesome-icon icon="plus" />  {{ $t("label.loadShiftsInSeries") }}</button>
                            <a  href="#" class="btn  btn-success bg-gradient-success"><font-awesome-icon icon="book" /> {{ $t("menu.showListShift") }}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-md-2 col-xs-12">
                      <flat-pickr  v-model="nowWeek"  :config="configDateTimePicker" placeholder="Select a date"
                                  name="birthdate"
                                  class="form-control"></flat-pickr>
                    </div>

                    <div class="col-xs-12 col-md-3"><input type="text" placeholder="Tìm kiếm..." class="form-control  form-control-alternative"></div>
                    <div class="col-xs-12 col-md-6">
                      <div class="row">
                        <div class="col-xs-12 col-md-3">
                            <select class="form-control form-control-alternative" name="branch">
                                <option value="">Chọn chi nhánh</option>
                                <option value="option1">Option 1</option>
                                <option value="option1">Option 2</option>
                                <option value="option1">Option 3</option>
                                <option value="option1">Option 4</option>
                            </select>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <select class="form-control form-control-alternative" name="department">
                                <option value="">Chọn phòng ban</option>
                                <option value="option1">Option 1</option>
                                <option value="option1">Option 2</option>
                                <option value="option1">Option 3</option>
                                <option value="option1">Option 4</option>
                            </select>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <select class="form-control form-control-alternative" name="work">
                                <option value="">Chọn vị trí công việc</option>
                                <option value="option1">Option 1</option>
                                <option value="option1">Option 2</option>
                                <option value="option1">Option 3</option>
                                <option value="option1">Option 4</option>
                            </select>
                        </div>
                        <div class="col-xs-12 col-md-3">
                            <select class="form-control form-control-alternative" name="status">
                                <option value="">Trạng thái</option>
                                <option value="option1">Option 1</option>
                                <option value="option1">Option 2</option>
                                <option value="option1">Option 3</option>
                                <option value="option1">Option 4</option>
                            </select>

                        </div>
                      </div>
                    </div>
                    <div class="col-md-1 col-xs-12">
                      <button class="btn btn-info  bg-gradient-info w-100" @click="search('search')">{{$t('label.filter')}}</button>
                    </div>

                </div>
            </div>
            <div class="col-xl-12">
                <div class="row mt-3">
                    <div class=" col-xl-12">
                        <div class="card">
                            <div class="card-header bg-gradient-primary"><h5 class="card-title text-uppercase text-white mb-0">{{ $t('label.descriptionArrangeShifts') }}</h5></div>
                            <div class="card-body m-0 p-0 table-responsive">

                                <b-table
                                  striped bordered
                                  class="table table-small table-hover table-striped"
                                  head-variant="light"
                                  hover
                                  :items="dataTable"
                                  :fields="fields"
                                  responsive
                                  id="table-inouthistory"
                                >
                     				       <template #cell(name)="data">
                                    <ul class="list-unstyled m-0">
                                      <li class="bold">Lê Ngọc Tân</li>
                                      <li class="small">Mã nhân viên: C381-0001</li>
                                    </ul>
                                  </template>
                                  <template #cell(date-01-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-02-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-03-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-04-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-05-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-06-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>
                                  <template #cell(date-07-02-2021)="data">
                                    <b-dropdown id="dropdown-left" :text="$t('label.defaultShift')" variant="btn btn-sm btn-block btn-success w-100" class="m-2 w-75">
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="plus"/> {{ $t('label.extraWorkShifts') }}</span></b-dropdown-item>
                                      <b-dropdown-item href="#"><span class="small"><font-awesome-icon icon="trash"/> {{ $t('label.delete') }}</span></b-dropdown-item>
                                    </b-dropdown>
                                  </template>

                                </b-table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
export default {
  components: {
    flatPickr
  },
  data () {
    return {
      firstdayWeek: '',
      lastdayWeek: '',
      dataTable: [
          	{ name: 'Ca mặc định' },
        { name: 'Ca mặc định' },
        { name: 'Ca mặc định' },
        { name: 'Ca mặc định' },
        { name: 'Ca mặc định' },
        { name: 'Ca mặc định' }
      ],
      fields: [
        {
          label: '',
          key: 'name',
          sortable: false
        },
        {
          label: 'T2 - 01/02',
          key: 'date-01-02-2021',
          sortable: false
        },
        {
          label: 'T3 - 02/02',
          key: 'date-02-02-2021',
          sortable: false
        },
        {
          label: 'T4 - 03/02',
          key: 'date-03-02-2021',
          sortable: false
        },
        {
          label: 'T5 - 04/02',
          key: 'date-04-02-2021',
          sortable: false
        },
        {
          label: 'T6 - 05/02',
          key: 'date-05-02-2021',
          sortable: false
        },
        {
          label: 'T7 - 06/02',
          key: 'date-06-02-2021',
          sortable: false
        },
        {
          label: 'CN - 07/02',
          key: 'date-06-02-2021',
          sortable: false
        }

      ],
      nowWeek: '1',
      configDateTimePicker: {
        dateFormat: 'Y-m-d',
        mode: 'range'
      }

    }
  },

  mounted () {
    this.nowWeek = this.dayWeekDate()
  },
  methods: {
    dayWeekDate () {
      const curr = new Date()
      const first = curr.getDate() - curr.getDay() + 1
      const last = first + 6
      let lastday = new Date(curr.setDate(last))
      const dd = String(lastday.getDate()).padStart(2, '0')
      const mm = String(lastday.getMonth() + 1).padStart(2, '0')
      const yyyy = lastday.getFullYear()
      let firstday = new Date(curr.setDate(first))
      const fdd = String(firstday.getDate()).padStart(2, '0')
      const fmm = String(firstday.getMonth() + 1).padStart(2, '0')
      const fyyyy = firstday.getFullYear()
      firstday = fyyyy + '-' + fmm + '-' + fdd
      lastday = yyyy + '-' + mm + '-' + dd
      return firstday + ' to ' + lastday
    }

  }

}
</script>
<style lang="scss">
  .dropdown-menu.show {
    outline: none;
  }
</style>
