<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12 ">
      <div class="row">
        <div class="col-xl-12 mt-2">
          <h1 class="page-title">
            {{ $t('menu.companyManager') }}
          </h1>
          <p>{{ $t('label.totalCompany') }}: {{dataTable.length}}</p>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input
                type="text"
                v-model="branchName"
                :placeholder="$t('label.search')"
                class="form-control"
              />
            </div>
            <div class="col-md-2 col-xs-12">
              <b-button
                variant="btn btn-info btn-outline-primary w-100"
                @click="getCompany()"
                >{{$t('label.search')}}
              </b-button>
            </div>
            <div class="col-xs-12 col-md-7 text-right">
              <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="showAddCompany()"
              >
                <font-awesome-icon icon="plus"/>
                {{ $t('label.addNew') }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    striped
                    bordered
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    id="table-company"
                    show-empty
                  >
                  <template #cell(name)="data">
                    <p class="mb-1">{{data.item.name}}</p>
                    <small class="text-muted">{{$t('label.taxCode')}} : {{data.item.taxcode}}</small>
                  </template>
                  <template #cell(info)="data">
                    <p>{{$t('label.phoneNumber')}} : {{data.item.phone}}</p> <small class="text-muted">{{data.item.personID}}</small>
                    <p>Email : {{data.item.email}}</p>
                  </template>
                  <template #cell(address)="data">
                    {{ data.item.address | truncate(50, '...') }}
                  </template>
                    <template #cell(placeID)="data">
                      <button
                        class="btn btn-warning btn-sm"
                        @click="showEdit(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.edit')"
                      >
                        <font-awesome-icon icon="edit"/>
                      </button>
                      <button
                        class="btn btn-danger btn-sm d-none"
                        @click="showDelete(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                      >
                        <font-awesome-icon icon="trash"/>
                      </button>
                    </template>
                    <template #empty="scope">
                      {{$t('notify.message.nodata')}}
                    </template>
                  </b-table>
                  <!-- table  -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-company"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <b-modal
            v-model="modalEdit"
            ref="modal-edit"
            :title="$t('label.addEdit') + ' ' + $t('label.company')"
            hide-footer
            header-bg-variant="secondary"
            centered
            size="lg"
          >
            <div class="row">
              <div class="col-6">
                <b-form-group
                  id="input-group-1"
                  :label="$t('label.name')"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    :placeholder="$t('label.name')"
                    required
                    v-model="editCompany.name"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['name'] && msg['name'][0] ? $t(msg['name']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-2"
                  label="Email"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    placeholder="Email"
                    required
                    v-model="editCompany.email"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['email'] && msg['email'][0] ? $t(msg['email']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-3"
                  :label="$t('label.phoneNumber')"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    :placeholder="$t('label.phoneNumber')"
                    v-model="editCompany.phone"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-4"
                  :label="$t('label.address')"
                  label-for="input-4"
                >
                  <b-form-input
                    id="input-4"
                    :placeholder="$t('label.address')"
                    required
                    v-model="editCompany.address"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['address'] && msg['address'][0] ? $t(msg['address']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-5"
                  :label="$t('label.taxCode')"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    :placeholder="$t('label.taxCode')"
                    required
                    v-model="editCompany.taxcode"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['taxcode'] && msg['taxcode'][0] ? $t(msg['taxcode']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-6"
                  label="Token Hanet"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    placeholder="Token Hanet"
                    v-model="editCompany.token_hanet"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['tokenHanet'] ? $t(msg['tokenHanet']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  id="input-group-7"
                  :label="$t('label.description')"
                  label-for="input-6"
                >
                        <textarea
                          id="input-7"
                          class="form-control form-control-sm"
                          :placeholder="$t('label.description')"
                          v-model="editCompany.Description"
                          rows="3"
                        ></textarea>
                </b-form-group>
              </div>
            </div>
            <div class="d-block text-center">
              <div class="text-center">
                <b-button class="btn btn-danger" @click="editCompanyById(editCompany.id)">{{
                    $t('label.save')
                  }}
                </b-button>
                <b-button class="btn btn-default" @click="hideModalEdit">{{
                    $t('label.cancel')
                  }}
                </b-button>
              </div>
            </div>
          </b-modal>
          <!-- modal edit  -->
          <b-modal
            v-model="modalDelete"
            ref="modal-delete"
            hide-header
            hide-footer
            header-bg-variant="secondary"
            modal-class="modal-delete text-center"
            centered
            size="md"
          >
            <div >
              <div class="modal-delete--warning">
                <div class="modal-delete--warning__content">!</div>
              </div>
              <div>
                <h2 class="text-warning">{{ $t('label.areYouSure') }}?</h2>
              </div>
              <div>{{ $t('label.sureDeleteTheBranch') }}</div>
              <div class="mt-3">
                <b-button variant="primary" @click="definitelyDelete(idDelete)">{{
                    $t('label.iAgree')
                  }}
                </b-button>
                <b-button variant="secondary" @click="hideModalDelete">{{
                    $t('label.cancel')
                  }}
                </b-button>
              </div>
            </div>
          </b-modal><!-- modal delete  -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from '@/axiosServer'
export default {
  data () {
    return {
      dataTable: [],
      modalEdit: false,
      modalDelete: false,
      idDelete: '',
      currentPage: 1,
      perPage: 20,
      showDeleteSuccess:false,
      editCompany:[],
      fields: [
        {
          label: this.$t('label.name'),
          key: 'name',
          class: "align-middle",
          sortable: false
        },
        {
          label: 'Thông tin công ty',
          key: 'info',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.address'),
          class: "align-middle",
          key: 'address',
          sortable: false
        },
        {
          label: '',
          key: 'placeID',
          tdClass: 'text-center w-5',
          thClass: 'text-center',
          class: "align-middle text-center",
          sortable: true
        }
      ],
      branchName:'',
      msg:'',
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
        if (text.length > length) {
            text = text.substring(0, length);
            text = text.substring(0, text.lastIndexOf(' '));
            return text + suffix;
        } else {
            return text;
        }
    },
  },
  mounted () {
    this.getCompany()
  },
  methods: {
    showAddCompany () {
      this.modalEdit = true;
      this.resetForm();
    },
    showEdit (id) {
      this.msg = [];
      this.editCompany = this.dataTable.find(function (x) {return x.id == id});
      this.modalEdit = true;
    },
    showDelete (idDelete) {
      this.idDelete = idDelete
      this.showDeleteSuccess = false
      this.modalDelete = true
    },
    hideModalEdit () {
      this.$refs['modal-edit'].hide()
    },
    hideModalDelete () {
      this.$refs['modal-delete'].hide()
    },
    async getCompany () {
      let res
      try {
        res = await apiServer.post('/api/v1/company/get-list', {
          keyword: this.branchName
        })
      } catch (error) {
      }
      if (res && res.data) {
        if (res.data.length > 0) {
          this.dataTable = res.data
        }
      }
    },
    async editCompanyById (id) {
      let res
      try {
        res = await apiServer.post('api/v1/company/save', {
          id: this.editCompany.id,
          name: this.editCompany.name,
          email: this.editCompany.email,
          phone: this.editCompany.phone,
          address: this.editCompany.address,
          taxcode: this.editCompany.taxcode,
          tokenHanet: this.editCompany.token_hanet,
          description: this.editCompany.Description
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(error.response.data)
        }
      }
      if (res && res.data) {
        if(res.statusText == "OK"){
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getCompany ();
          this.modalEdit = false;
        }
      }
    },

    resetForm(){
      this.editCompany = [];
      this.msg = [];
      ;
    }
  }
}
</script>
<style lang="scss">
  .modal-delete {
    .modal-delete--warning {
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 1.3em;
      height: 1.3em;
      margin: 0.25em auto 0.375em;
      border: 0.06em solid transparent;
      border-radius: 50%;
      font-family: inherit;
      line-height: 1.3em;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-color: #28a745;
      color: #f8bb86;
      text-align: center;
      font-size: 3.6em;
    }
    .modal-delete--success {
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 1.3em;
      height: 1.3em;
      margin: 0.25em auto 0.375em;
      border: 0.06em solid transparent;
      border-radius: 50%;
      font-family: inherit;
      line-height: 1.3em;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-color: #28a745;
      color: #28a745;
      text-align: center;
      font-size: 3.6em;
    }

  }
</style>
