<template>
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.leaves") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalLeave") }} {{ dataTable.length }}
              </p>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div class="mt-4">
                <a
                  href="javascript:void(0)"
                  @click="showModalAddLeave()"
                  class="btn btn-danger bg-gradient-danger"
                >
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <b-form-group>
                <select v-model="status" class="form-control">
                  <option
                    v-for="status in listStatus"
                    v-bind:value="status.value"
                  >
                    {{ status.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100"
                @click="getListLeave()"
                >{{ $t("label.search") }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-leave"
                    @row-clicked="showInfoDetail"
                    show-empty
                  >
                    <template #cell(infoUser)="data">
                      <div class="row">
                        <div class="col-2 p-0">
                          <img
                            :src="data.item.avatar"
                            alt=""
                            height="90px"
                            width="80px"
                          />
                        </div>
                        <div class="col-10">
                          <small class="text-muted">{{
                            data.item.personID
                          }}</small>
                          <p class="m-0">{{ data.item.fullName }}</p>
                          <small class="text-muted">{{
                            data.item.email
                          }}</small>
                        </div>
                      </div>
                    </template>
                    <template #cell(browser)="data">
                      <p class="m-0">{{ data.item.userApprove }}</p>
                    </template>
                    <template #cell(reason)="data">
                      <div class="m-0">
                        {{ data.item.reason | truncate(40, "...") }}
                      </div>
                      <small class="text-danger"
                        >{{ $t("label.timeDay") }}:
                        <template
                          v-if="
                            data.item.off_work_from != data.item.off_work_to
                          "
                        >
                          {{
                            convertDate(data.item.off_work_from) +
                            " - " +
                            convertDate(data.item.off_work_to)
                          }}
                        </template>
                        <template v-else>
                          {{ convertDate(data.item.off_work_to) }}
                        </template>
                      </small>
                    </template>
                    <template #cell(status)="data">
                      <div class="m-0 text-white">
                        <template v-if="data.item.approved == 1">
                          <span
                            style="
                              background-color: rgb(45 189 76);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.agree") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approved == 2">
                          <span
                            style="
                              background-color: lightgreen;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.pending") }}</span
                          >
                        </template>
                        <template v-else-if="data.item.approved == 3">
                          <span
                            style="
                              background-color: red;
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.refuse") }}</span
                          >
                        </template>
                        <template v-else>
                          <span
                            style="
                              background-color: rgb(251 99 64);
                              border-radius: 10px;
                              line-height: 20px;
                              font-weight: bold;
                              padding: 3px 10px;
                              font-size: 13px;
                            "
                            >{{ $t("label.waitingForApprove") }}</span
                          >
                        </template>
                      </div>
                    </template>
                    <template #cell(function)="data">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="showDelete(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                        :disabled="editAble(data.item.approved)"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                    <template #empty="scope">
                      {{ $t("notify.message.nodata") }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-leave"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalDelete"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeave(idDelete)"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal delete  -->
    <b-modal
      v-model="modalAddLeave"
      ref="modal-add-leave"
      :title="$t('label.addLeaveApplication')"
      hide-footer
      header-bg-variant="secondary"
      centered
      size="xl"
    >
      <b-form ref="form" class="row">
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group class="required-mess" :label="$t('label.leaveType')">
            <select class="form-control" v-model="leaveType">
              <option v-for="lt in leaveTypes" v-bind:value="lt.id">
                {{ lt.name }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["leaveType"] ? $t(msg["leaveType"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-group
            id="input-group-2"
            class="required-mess"
            :label="$t('label.leaveDate')"
          >
            <flat-pickr
              v-model="dateSelect"
              :config="configDateTimePicker"
              :placeholder="$t('label.leaveDate')"
              name="reportDate"
              class="form-control"
              :invalid-feedback="dateSelectError"
            ></flat-pickr>
            <div class="error-message w-100 clear text-left">
              {{ msg["offWorkFrom"] ? $t(msg["offWorkFrom"]) : "" }}
            </div>
          </b-form-group>
        </div>

        <!-- <div class="col-lg-6 col-md-6 col-xs-12">
          <b-form-checkbox
            class="b-form-checkbox"
            v-model="leaveHalfShift"
            @change="setLeaveHalf($event)"
            >{{ $t("label.leaveHalfShift") }}</b-form-checkbox
          >
          <b-form-group
            :label="$t('label.chooseLeaveHalf')"
            v-show="leaveHalfShift"
          >
            <select class="form-control" v-model="leaveTypeHalf">
              <option value="1">{{ $t("label.leaveFirstHalf") }}</option>
              <option value="2">{{ $t("label.leaveSecondHalf") }}</option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["leaveHalf"] ? $t(msg["leaveHalf"]) : "" }}
            </div>
          </b-form-group>
        </div> -->
        <div class="col-lg-12 col-md-12 col-xs-12">
          <label>{{ $t('label.reasonForLeave') }}</label>
          <b-form-group
            id="input-group-2"
            class="required-mess"
          >
            <textarea
              class="form-control"
              id=""
              :placeholder="$t('label.reasonForLeave')"
              required
              v-model="reasonForLeave"
              rows="2"
            ></textarea>
            <div class="error-message w-100 clear text-left">
              {{ msg["reason"] ? $t(msg["reason"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-6">
          <label>{{ $t('label.extraLeaveDays') }}</label>

          <b-form-input v-model="extraLeaveDays" :placeholder=" $t('label.extraLeaveDays')" readonly type="text">
          </b-form-input>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-6">
          <label>{{ $t('label.regimeLeaveDay') }}</label>
          <b-form-input v-model="regimeLeaveDay" :placeholder=" $t('label.regimeLeaveDay')" readonly type="text">
          </b-form-input>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-6">
          <label>{{ $t('label.totalLeavedDays') }}</label>

          <b-form-input v-model="totalLeavedDays" :placeholder=" $t('label.totalLeavedDays')" readonly type="text">
          </b-form-input>
        </div>
        <div class="col-lg-3 col-md-3 col-xs-6">
          <label style="color: #E4A11B;">{{ $t('label.numberLeave') }}</label>
          <b-form-input v-model="numberLeave" :placeholder=" $t('label.numberLeave')" readonly type="text">
          </b-form-input>
        </div>
        <div class="row col-12" v-for="(approval, i) in approveList" :key="i">
          <div class="col-12">
            <h3>{{ $t("label.approveStep") }} {{ i + 1 }}</h3>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.jobPosition')">
              <treeselect
                v-model="approval.position"
                :multiple="false"
                :options="approval.positionOption"
                :default-expand-level="Infinity"
                @select="changePosition(i)"
                :noChildrenText="$t('label.noNodeChildren')"
                :placeholder="$t('label.choosePosition')"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('label.approver')">
              <select class="form-control" v-model="approval.user">
                <option
                  v-for="user in approval.listUser"
                  v-bind:value="user.id"
                >
                  {{ user.fullName }}
                </option>
              </select>
              <div class="error-message w-100 clear text-left">
                {{ msg["approveUser" + i] ? $t(msg["approveUser" + i]) : "" }}
              </div>
            </b-form-group>
          </div>
        </div>
        <div class="col-12">
          <b-button @click="addMoreApprove">{{
            $t("label.addApproveStep")
          }}</b-button>
        </div>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="addLeave"
            >{{ $t("label.create") }}
          </b-button>
          <b-button class="btn btn-default" @click="hidemodalAddLeave"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal addLeave  -->
    <b-modal
      v-model="modalInfoDetail"
      ref="modal-info-detail"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-info-detail text-center"
      centered
      size="lg"
    >
      <div>
        <div>
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.sender") }}
                </th>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.browser") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td>
                <p>{{ infoSenderDetail.fullName }}</p>
                <p>{{ infoSenderDetail.email }}</p>
              </td>
              <td>
                <table>
                  <tr>
                    <th>{{ $t("label.browser") }}</th>
                    <th>{{ $t("label.status") }}</th>
                    <th>{{ $t("label.approveStep") }}</th>
                    <th>{{ $t("label.content") }}</th>
                  </tr>
                  <tr v-for="(item, index) in infoSenderDetail.listApprover">
                    <td>{{ item.fullName }}</td>
                    <td>{{ getStatus(item.approve_status) }}</td>
                    <td>{{ item.approve_step }}</td>
                    <td>{{ item.approve_content }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="text-left">
              <td colspan="2">
                <b>{{ $t("label.status") }}: </b>
                <template v-if="infoSenderDetail.approved == 0">
                  <span
                    style="
                      background-color: rgb(251 99 64);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.pending") }}</span
                  >
                </template>
                <template v-else-if="infoSenderDetail.approved == 1">
                  <span
                    style="
                      background-color: rgb(45 189 76);
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.agree") }}</span
                  >
                </template>
                <template v-else>
                  <span
                    style="
                      background-color: red;
                      border-radius: 10px;
                      line-height: 20px;
                      font-weight: bold;
                      padding: 3px 10px;
                      font-size: 13px;
                      color: #fff;
                    "
                    >{{ $t("label.refuse") }}</span
                  >
                </template>
              </td>
            </tr>
            <thead class="thead-light">
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonForLeave") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.timeDay") }}:</strong>
                  <template
                    v-if="
                      infoSenderDetail.off_work_from !=
                      infoSenderDetail.off_work_to
                    "
                  >
                    {{
                      convertDate(
                        infoSenderDetail.off_work_from,
                        "dd/MM/yyyy"
                      ) +
                      " - " +
                      convertDate(infoSenderDetail.off_work_to, "dd/MM/yyyy")
                    }}
                  </template>
                  <template v-else>
                    {{
                      convertDate(infoSenderDetail.off_work_to, "dd/MM/yyyy")
                    }}
                  </template>
                </p>
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason }}
                </p>
              </td>
            </tr>
            <thead class="thead-light" v-if="infoSenderDetail.approved == 3">
              <tr>
                <th colspan="2" style="text-transform: none; font-size: 18px">
                  {{ $t("label.reasonDenied") }}
                </th>
              </tr>
            </thead>
            <tr class="text-left" v-if="infoSenderDetail.approved == 3">
              <td colspan="2">
                <p>
                  <strong>{{ $t("label.reason") }}:</strong>
                  {{ infoSenderDetail.reason_denied }}
                </p>
              </td>
            </tr>
          </table>
        </div>

        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalInfoDetail"
            >{{ $t("label.close") }}
          </b-button>
          
        </div>
      </div> </b-modal
    ><!-- modal show Info Detail  -->
  </div>
</template>

<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    flatPickr,
    Treeselect,
  },
  data() {
    return {
      modalDelete: false,
      modalInfoDetail: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      modalAddLeave: false,
      fields: [
        {
          label: this.$t("label.leaveType"),
          key: "type_name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.browser"),
          key: "browser",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.reason"),
          key: "reason",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "status",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      infoSenderDetail: [],
      id: null,
      addNewsuccess: false,
      addNewError: false,
      reasonForLeave: "",
      leaveHalfShift: false,
      leaveTypeHalf: null,
      dateSelect: "",
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "range",
        minDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
        onClose: function (selectedDates, dateStr, instance) {
          if (selectedDates.length == 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
          }
        },
      },
      options: [],
      msg: [],
      status: null,
      listStatus: [
        { text: this.$t("label.status"), value: null },
        { text: this.$t("label.waitingForApprove"), value: 0 },
        { text: this.$t("label.approved"), value: 1 },
        { text: this.$t("label.pending"), value: 2 },
        { text: this.$t("label.refuse"), value: 3 },
      ],
      admins: [],
      approveBy: null,
      leaveTypes: [],
      leaveType: null,
      listPosition: [],
      approveList: [],
      aliasID : '',
      numberLeave: '',
      totalLeavedDays: '',
      extraLeaveDays: '',
      regimeLeaveDay: '',
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        text = text.substring(0, length);
        text = text.substring(0, text.lastIndexOf(' '));
        return text + suffix;
      } else {
        return text;
      }
    }
  },
  created() {
    this.getListLeave();
    this.getApprover();
    this.getLeaveType();
    this.getPosition();
    this.aliasID = localStorage.getItem('aliasID');
    this.getnumberLeave();
    if (this.$store.getters.locale == "vi") {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
  },
  computed: {
    dateSelectError() {
      if (this.dateSelect && this.numberLeave && this.dateSelect > this.numberLeave) {
        return this.$t('errorMessages.dateGreaterThanLeave');
      }
      return null;
    },
  },
  methods: {
    async getnumberLeave() {
      let res;
      try {
        this.dataTable = [];
        res = await apiServer.post("/api/v1/user/remaining-leave", {
          aliasID: this.aliasID,
        });
        if (res && res.data) {
          console.log(res.data);
          this.numberLeave = res.data.data.numberLeave;
          this.regimeLeaveDay = res.data.data.regimeLeaveDay;
          this.extraLeaveDays = res.data.data.extraLeaveDays;
          this.totalLeavedDays = res.data.data.totalLeavedDays;  

        }
      } catch (e) {
        console.log(e);
      }
    },
    async getListLeave() {
      let res;
      try {
        this.dataTable = [];
        res = await apiServer.post("/api/v1/user/get-leave", {
          status: this.status,
        });
        if (res && res.data) {
          this.dataTable = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getApprover() {
      let res;
      try {
        res = await apiServer.get("/api/v1/user/get-approver");
        if (res && res.data) {
          this.admins = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getLeaveType() {
      let res;
      try {
        res = await apiServer.get("/api/v1/user/get-leave-type");
        if (res && res.data) {
          this.leaveTypes = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getPosition() {
      let res;
      try {
        res = await apiServer.post("api/v1/position/list-approved", {
          placeId: this.branchid,
        });
      } catch (error) {}
      if (res && res.data) {
        this.listPosition = [];
        if (res.data.length > 0) {
          this.listPosition = res.data;
          for (var i = 0; i < this.approveList.length; i++) {
            this.approveList.positionOption = this.listPosition;
          }
        }
      }
    },
    async getApproverByPosition(index) {
      let res;
      try {
        res = await apiServer.post("api/v1/user/search-list-approver", {
          position: this.approveList[index].position,
        });
      } catch (error) {}
      if (res && res.data) {
        this.approveList[index].listUser = [];
        if (res.data.length > 0) {
          this.approveList[index].listUser = res.data;
        }
      }
    },
    changePosition(index) {
      setTimeout(() => {
        this.getApproverByPosition(index);
      }, 500);
    },
    addMoreApprove() {
      this.approveList.push({
        positionOption: this.listPosition,
        position: null,
        listUser: [],
        user: null,
      });
    },
    hideModalDelete() {
      this.$refs["modal-delete"].hide();
    },

    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },

    hidemodalAddLeave() {
      this.$refs["modal-add-leave"].hide();
    },
    async showInfoDetail(item) {
      let res;
      try {
        res = await apiServer.post("/api/v1/leave/get-by-id", {
          leave_id: item.id,
        });
        if (res && res.data) {
          this.infoSenderDetail = res.data;
        }
      } catch (e) {
        console.log(e);
      }
      this.modalInfoDetail = true;
    },
    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.showDeleteSuccess = false;
      this.modalDelete = true;
    },
    showEdit(id) {
      this.id = id;
      let editItem = this.dataTable.find((x) => x.id == id);
      this.reasonForLeave = editItem.reason;
      this.approveBy = editItem.approve_by;
      this.dateSelect = moment(editItem.off_work_from).format("DD/MM/yyyy");
      if (editItem.off_work_to != editItem.off_work_from) {
        this.dateSelect +=
          " " +
          this.$t("label.to") +
          " " +
          moment(editItem.off_work_to).format("DD/MM/yyyy");
      }
      this.modalAddLeave = true;
    },
    showModalAddLeave() {
      this.dateSelect = "";
      this.approveBy = null;
      this.reasonForLeave = "";
      this.modalAddLeave = true;
      this.msg = [];
    },
    setLeaveHalf(evt) {
      if (evt === true) {
        this.dateSelect = null;
        this.configDateTimePicker.mode = "single";
      } else {
        this.configDateTimePicker.mode = "range";
        this.leaveTypeHalf = null;
      }
    },
    async deleteLeave(id) {
      let res;
      try {
        res = await apiServer.post("/api/v1/user/delete-leave", {
          id: id,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getListLeave();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async addLeave() {
      let res;
      let df = null,
        dt = null;
      if (this.dateSelect != undefined && this.dateSelect !== "") {
        let dateArr = this.dateSelect.split(" ");
        df = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          dt = df;
        } else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      var approveUser = [];
      for (var i = 0; i < this.approveList.length; i++) {
        approveUser.push(this.approveList[i].user);
      }
      if (approveUser.length === 0) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveEmpty"),
          type: "error",
        });
        return false;
      }
      if (this.hasDuplicates(approveUser)) {
        // alert(this.$t('error.message.userApproveDuplicate'));
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notificationError"),
          text: this.$t("error.message.userApproveDuplicate"),
          type: "error",
        });
        return false;
      }
      try {
        res = await apiServer.post("/api/v1/user/save-leave", {
          id: this.id,
          leaveType: this.leaveType,
          offworkfrom: df,
          offworkto: dt,
          leaveHalfShift: this.leaveHalfShift,
          leaveTypeHalf: this.leaveTypeHalf,
          reason: this.reasonForLeave.trim(),
          approveUsers: approveUser,
        });
        if (res && res.data) {
          if (res.status === 200) {
            this.modalAddLeave = false;
            this.$notify({
              group: "bottomnotification",
              title: this.$t("label.notification"),
              text: this.$t(res.data.message),
              position: "bottom-center",
            });
            this.getListLeave();
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data["message"]) {
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notificationError"),
            text: this.$t(this.msg["message"]),
            type: "error",
          });
        } else {
          console.log(error.response.data);
          // alert(this.$t(error.response.data.message));
        }
      }
    },
    deleteAble(status, dateId) {
      if (status != 0) {
        return true;
      } else {
        let today = new Date();
        let dt = new Date(dateId);
        let date2 = today.getTime() - dt.getTime();
        let date3 = Math.ceil(date2 / (1000 * 60 * 60 * 24));
        return date3 > 1;
      }
    },
    editAble(status) {
      return status != 0;
    },
    getStatus(status) {
      if (status == 1) {
        return this.$t("label.agree");
      } else if (status == 2) {
        return this.$t("label.pending");
      } else if (status == 3) {
        return this.$t("label.refuse");
      } else {
        return this.$t("label.waitingForApprove");
      }
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
    convertTimestampSendDate(date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    },
  },
};
</script>

<style scoped></style>
