<template>
    <div id="myCal"></div>
  </template>
  
  <script>
  import Calendar from "color-calendar";
  import "color-calendar/dist/css/theme-glass.css";
  
  export default {
    name: "ColorCalendar",
    mounted() {
      new Calendar({
        id: "#myCal",
        theme: "glass"
      });
    }
  };
  </script>
  
  <style scoped>
  #myCal {
    margin-top: 25px;
    margin-bottom: 65px;
  }
  </style>
  