<template>
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-12">
          <h1 class="page-title mt-2 mb-4">
            {{ $t("menu.notifyMeal") }}
          </h1>
        </div>
        <div class="col-xl-12 row">
          <div class="col-xs-12 col-lg-4 col-md-6 mt-3">
            <flat-pickr  v-model="dateRegister"  :config="configDateTimePicker" 
            :placeholder='$t("label.chooseDate")'
                         name="date_register" class="form-control"></flat-pickr>
          </div>
          <div class="col-xs-12 col-lg-4 col-md-6 mt-3">
            <b-button
              variant="btn btn-info  btn-outline-primary w-100 font-weight-bold"
              @click="getMeals()"
              >{{$t('label.search')}}
            </b-button>
          </div>
        </div>
        <div class="col-xl-12 mt-4">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <b-table
                striped
                bordered
                class="default-table"
                hover
                :items="dataTable"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                @row-clicked="showInfoDetail"
                responsive
                id="table-notify-meal"
              >
                <template #cell(date)="data">
                  <template v-if="data.item.is_weekly == 1">
                    <div>
                      <span v-if="data.item.monday == 1"
                        >{{ $t("label.monday") }},</span
                      >
                      <span v-if="data.item.tuesday == 1"
                        >{{ $t("label.tuesday") }},</span
                      >
                      <span v-if="data.item.wednesday == 1"
                        >{{ $t("label.wednesday") }},</span
                      >
                      <span v-if="data.item.thursday == 1"
                        >{{ $t("label.thursday") }},</span
                      >
                      <span v-if="data.item.friday == 1"
                        >{{ $t("label.friday") }},</span
                      >
                      <span v-if="data.item.saturday == 1"
                        >{{ $t("label.saturday") }},</span
                      >
                      <span v-if="data.item.sunday == 1">{{
                        $t("label.sunday")
                      }}</span>
                    </div>
                    <h3 class="title">{{ $t("label.weekly") }}</h3>
                  </template>
                  <template v-else>
                    {{ convertDate(data.item.register_specify_date) }}
                  </template>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataTable.length"
                :per-page="perPage"
                align="right"
                size="sm"
                class="m-4"
                aria-controls="table-notify-meal"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalInfoDetail"
      ref="modal-info-detail"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-info-detail text-center"
      centered
      size="lg"
    >
      <div>
        <div>
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.mealInfo") }}
                </th>
              </tr>
            </thead>
            <table class="w-100">
              <tr>
                <th>{{ $t("label.dateRegister") }}</th>
                <th>{{ $t("label.quantity") }}</th>
                <th>{{ $t("label.department") }}</th>
              </tr>
              <tr>
                <td v-if="infoDetail.is_weekly == 1"><div>
                  <span v-if="infoDetail.monday == 1"
                    >{{ $t("label.monday") }},</span
                  >
                  <span v-if="infoDetail.tuesday == 1"
                    >{{ $t("label.tuesday") }},</span
                  >
                  <span v-if="infoDetail.wednesday == 1"
                    >{{ $t("label.wednesday") }},</span
                  >
                  <span v-if="infoDetail.thursday == 1"
                    >{{ $t("label.thursday") }},</span
                  >
                  <span v-if="infoDetail.friday == 1"
                    >{{ $t("label.friday") }},</span
                  >
                  <span v-if="infoDetail.saturday == 1"
                    >{{ $t("label.saturday") }},</span
                  >
                  <span v-if="infoDetail.sunday == 1">{{
                    $t("label.sunday")
                  }}</span>
                  </div>
                  <h4 class="title">{{ $t("label.weekly") }}</h4></td>
                <td v-else>{{ convertDate(infoDetail.register_specify_date) }}</td>
                <td>{{ infoDetail.quantity }}</td>
                <td>{{ infoDetail.department }}</td>
              </tr>
            </table>
            <thead v-if="infoDetail.is_weekly == 1" class="thead-light">
              <tr>
                <th style="text-transform: none; font-size: 18px">
                  {{ $t("label.mealDetail") }}
                </th>
              </tr>
            </thead>
            <table v-if="infoDetail.is_weekly == 1" class="w-100">
              <tr>
                <th></th>
                <th>{{ $t("label.monday") }}</th>
                <th>{{ $t("label.tuesday") }}</th>
                <th>{{ $t("label.wednesday") }}</th>
                <th>{{ $t("label.thursday") }}</th>
                <th>{{ $t("label.friday") }}</th>
                <th>{{ $t("label.saturday") }}</th>
                <th>{{ $t("label.sunday") }}</th>
              </tr>
              <tr>
                <th>{{ $t("label.quantity") }}</th>
                <td>{{ infoDetail.monday }}</td>
                <td>{{ infoDetail.tuesday }}</td>
                <td>{{ infoDetail.wednesday }}</td>
                <td>{{ infoDetail.thursday }}</td>
                <td>{{ infoDetail.friday }}</td>
                <td>{{ infoDetail.saturday }}</td>
                <td>{{ infoDetail.sunday }}</td>
              </tr>
            </table>
          </table>
          
        </div>

        <div class="mt-3">
          <b-button variant="secondary" @click="hideModalInfoDetail"
            >{{ $t("label.close") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- modal show Info Detail  -->
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from "moment";

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      dateRegister: null,
      modalInfoDetail: false,
      infoDetail: [],
      dataTable: [],
      fields: [
        {
          label: this.$t("label.dateRegister"),
          key: "date",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.quantity"),
          key: "quantity",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.department"),
          key: "department",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        }
      ],
      currentPage: 1,
      perPage: 25,
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "single",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      msg: [],
    };
  },
  created() {
    let userType = this.$store.getters.userType;
    if (userType != 2) {
      this.$router.replace("/login");
    }
    this.getMeals();
  },
  methods: {
    async getMeals() {
      let res;
      try {
        res = await apiServer.post("api/v1/user/get-chef-meals", {
            dateSearch: this.convertTimestampSendDate(this.dateRegister)
        });
      } catch (error) {
        alert(error.response.data);
      }
      if (res && res.data) {
        this.dataTable = res.data;
      }
    },
    convertTimestampSendDate(dateString) {
      if (dateString == null || dateString == undefined) return null;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return "";
      return moment(inputDate).format("DD/MM/yyyy");
    },
    hideModalInfoDetail() {
      this.$refs["modal-info-detail"].hide();
    },
    showInfoDetail(item) {
      this.infoDetail = item
      this.modalInfoDetail = true;
    },
  }
}
</script>