<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <h1 class="page-title mt-2 mb-4">
              {{ $t("menu.attendanceManagement") }}
            </h1>
          </div>
        <div class="text-center section">
          <v-calendar
            class="custom-calendar max-w-full"
            :masks="masks"
            :attributes="attributes"
            :locale="language"
            @update:from-page="changepage"
            disable-page-swipe
            is-expanded
          >
            <template v-slot:day-content="{ day, attributes }">
              <div class="flex flex-col h-full z-10 overflow-hidden">
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="flex-grow overflow-y-auto overflow-x-auto">
                  <p
                    v-for="attr in attributes"
                    :key="attr.key"
                    class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
                    :class="attr.customData.class"
                    v-html="attr.customData.title"
                    @click="onDayClick(attr.key)"
                  >
                  </p>
                </div>
              </div>
            </template>
          </v-calendar>
        </div>
        </div>
      </div>
    </div>
    <div class="row">
       <b-modal
        v-model="modalDetail"
        ref="modal-info-detail"
        :title="$t('label.workDetail')"
        hide-footer
        header-bg-variant="secondary"
        modal-class="modal-info-detail text-center"
        centered
        size="lg"
      >
        <div class="row">
          <div class="col-12">
            <p v-html="itemDetail"></p>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiServer from '@/axiosServer'
import moment from 'moment'
export default {
  data() {
    return {
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
      data: [],
      itemDetail: null,
      modalDetail: false,
      language: this.$store.getters.locale
    }
  },
  mounted(){
    this.getWorkDay(null, null);
  },
  methods: {
    async getWorkDay (month, year) {
      this.attributes = [];
      let res;
      let today = new Date();
      if (month == null || month == undefined) {
        month = today.getMonth();
      }
      if (year == null || year == undefined) {
        year = today.getFullYear();
      }
      let firstDayOfMonth = new Date(year, month, 1);
      let lastDayOfMonth = new Date(year, month+1, 0);
      try {
        res = await apiServer.post('/api/v1/user/work-day', {
          fromdate: moment(firstDayOfMonth).format("YYYY-MM-DD"),
          todate: moment(lastDayOfMonth).format("YYYY-MM-DD")
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.data = res.data;
        console.log(this.data)
        while (firstDayOfMonth <= lastDayOfMonth) {
          let dateID = moment(firstDayOfMonth).format("YYYY-MM-DD");
          let itemInDate = this.data.filter(x => moment(x.dateID).format("YYYY-MM-DD") == dateID);
          let leaveText = "", shiftName = "";
          if (itemInDate.length > 0) {
          for (let item of itemInDate) {
            if (item.isLeave == true) {
              leaveText += this.$t("label.leave") + "<br/>";
            } else {
              shiftName += item.shifts_name + "; ";
            }
          }
          shiftName = shiftName.slice(0, -1); + "<br/>";
          let attr = {
            key: dateID,
            customData: {title: leaveText + shiftName, class: 'bg-red text-white vc-event'},
            dates: new Date(dateID)
          };
          this.attributes.push(attr);
          }

          firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }
      }
    },
    changepage(page) {
      if (page.month != undefined && page.year != undefined && page.day == undefined){
        this.getWorkDay(page.month - 1, page.year);
      }
    },
    onDayClick(dateID) {
      let itemInDate = this.data.filter(x => moment(x.dateID).format("YYYY-MM-DD") == dateID);
      let attrContent = "";
      for (let item of itemInDate) {
            if (item.isLeave == true) {
              attrContent += this.$t("label.leave") + "<br/>";
            } else {
              let checkin = item.checkin ? moment(item.checkin).format("HH:mm:ss") : "";
              let checkout = item.checkout ? moment(item.checkout).format("HH:mm:ss") : "";
              attrContent += "<b>" + item.shifts_name + "</b>: " + checkin + " - " + checkout + "<br/>";
            }
          }
      this.itemDetail = attrContent;
      this.modalDetail = true;
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
  & .bg-red {
    background: red;
  }
}
.vc-event {
  cursor: pointer;
}
</style>
