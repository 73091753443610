<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="col-xl-12">
        <h1 class="page-title mt-2">
          {{ $t("menu.fileManagement") }}
        </h1>
        <p class="text-muted">
          {{ $t("label.totalFile") }}: {{ dataTable.length }}
        </p>
      </div>
      <div class="col-12 text-right">
        <a
          href="javascript:void(0)"
          class="btn btn-danger bg-gradient-danger"
          @click="showAddFile()"
          ><font-awesome-icon icon="plus" /> {{ $t("label.upload") }}</a
        >
      </div>
      <div class="col-12 row">
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin">
          <b-form-group class="required-mess" :label="$t('label.company')">
            <select
              v-model="company"
              class="form-control"
              @change="changeCompany"
              required
            >
              <option v-for="cp in companies" v-bind:value="cp.value">
                {{ cp.text }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["company"] ? $t(msg["company"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin || isComAdmin">
          <b-form-group class="required-mess" :label="$t('label.branch')">
            <select
              v-model="placeId"
              class="form-control"
              :placeholder="$t('label.branch')"
            >
              <option v-for="branch in branchs" v-bind:value="branch.value">
                {{ branch.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin || isComAdmin">
          <b-button
            variant="btn btn-info btn-outline-primary w-100 font-weight-bold dv-search-btn"
            @click="getFile()"
            >{{ $t("label.search") }}</b-button
          >
        </div>
      </div>
      <div class="col-xl-12">
        <div class="row mt-3">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body m-0 p-0 table-responsive">
                <b-table
                  striped
                  bordered
                  class="default-table"
                  hover
                  :items="dataTable"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  responsive
                  id="table-file"
                  show-empty
                >
                  <template #cell(file_type)="data">
                    <template v-if="data.item.file_type == 1">
                      {{ $t("label.camCheckin") }}
                    </template>
                    <template v-else-if="data.item.file_type == 2">
                      {{ $t("label.camCheckout") }}
                    </template>
                    <template v-else>
                      {{ $t("label.camCheckinout") }}
                    </template>
                  </template>
                  <template #cell(button)="data">
                    <b-button
                      @click="editFile(data.item)"
                      :title="$t('label.edit')"
                      class="btn btn-primary btn-sm"
                      variant="primary"
                      depressed
                      elevation="2"
                      v-b-tooltip.hover
                      ><font-awesome-icon icon="edit"
                    /></b-button>
                    <b-button
                      class="btn btn-warning btn-sm"
                      :title="$t('label.delete')"
                      v-b-tooltip.hover
                      @click="showDelete(data.item.id)"
                      depressed
                      elevation="2"
                      ><font-awesome-icon icon="trash"
                    /></b-button>
                  </template>
                  <template #empty="scope">
                    {{ $t("notify.message.nodata") }}
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="dataTable.length"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  class="m-4"
                  aria-controls="table-file"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <b-modal
          v-model="modalAddfile"
          ref="modal-add-file"
          :title="$t('label.addEdit') + ' ' + $t('label.file')"
          header-bg-variant="secondary"
          hide-footer
          centered
          size="lg"
        >
          <div class="row">
            <div class="col-6" v-if="isSupperAdmin">
              <b-form-group class="required-mess" :label="$t('label.company')">
                <select
                  v-model="company"
                  class="form-control"
                  @change="changeCompany"
                  required
                >
                  <option v-for="cp in companies" v-bind:value="cp.value">
                    {{ cp.text }}
                  </option>
                </select>
                <div class="error-message w-100 clear text-left">
                  {{ msg["company"] ? $t(msg["company"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-6" v-if="isSupperAdmin || isComAdmin">
              <b-form-group class="required-mess" :label="$t('label.branch')">
                <select
                  v-model="placeId"
                  class="form-control"
                  @change="changeBranch"
                >
                  <option v-for="branch in branchs" v-bind:value="branch.value">
                    {{ branch.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <b-form-group
                class="required-mess"
                :label="$t('label.selectCamera')"
              >
                <select
                  class="form-control"
                  v-model="modelFile"
                  @change="changeCamera"
                  :disabled="isEdit"
                >
                  <option
                    v-for="file in hanetFiles"
                    v-bind:value="File.value"
                  >
                    {{ File.text }}
                  </option>
                </select>
                <div
                  class="error-message w-100 clear text-left font-weight-normal"
                >
                  {{ msg["fileCode"] ? $t(msg["fileCode"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                class="required-mess"
                :label="$t('label.fileType')"
              >
                <select v-model="fileType" class="form-control">
                  <option v-bind:value="1">{{ $t("label.camCheckin") }}</option>
                  <option v-bind:value="2">
                    {{ $t("label.camCheckout") }}
                  </option>
                  <option v-bind:value="0">
                    {{ $t("label.camCheckinout") }}
                  </option>
                </select>
                <div
                  class="error-message w-100 clear text-left font-weight-normal"
                >
                  {{ msg["fileType"] ? $t(msg["fileType"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                class="required-mess"
                :label="$t('label.fileCode')"
              >
                <b-form-input
                  v-model="fileCode"
                  class="form-control-sm"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group
                class="required-mess"
                :label="$t('label.fileName')"
              >
                <b-form-input
                  v-model="fileName"
                  class="form-control-sm"
                  required
                ></b-form-input>
                <div
                  class="error-message w-100 clear text-left font-weight-normal"
                >
                  {{ msg["fileName"] ? $t(msg["fileName"]) : "" }}
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="mt-3 text-center">
            <b-button class="btn btn-danger" @click="saveFile()">{{
              $t("label.save")
            }}</b-button>
            <b-button class="btn btn-default" @click="hideModalAddFile">{{
              $t("label.cancel")
            }}</b-button>
          </div>
        </b-modal>
      </div>
    </div>
    <b-modal
      v-model="modalDelete"
      id="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureFile") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteFile()"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('modal-delete')"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import moment from "moment";

export default {
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      dataTable: [],
      fields: [
        {
          label: this.$t("label.fileCode"),
          key: "file_code",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.FileName"),
          key: "file_name",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.fileType"),
          key: "file_type",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.control"),
          key: "button",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 25,
      modalAddFile: false,
      modalDelete: false,
      isEdit: false,
      totalRecords: 0,
      placeId: null,
      companies: [],
      branchs: [],
      id: null,
      fileCode: null,
      fileName: null,
      fileType: null,
      hanetFiles: [],
      modelFile: null,
      msg: [],
    };
  },
  created() {
    this.getFile();
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    } else {
      this.getListFileByPlace();
    }
  },
  methods: {
    async getFile() {
      this.dataTable = [];
      this.totalRecords = 0;
      let res;
      try {
        res = await apiServer.post("api/v1/get-files", {
          placeId: this.placeId,
        });
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          this.dataTable = res.data;
          this.totalRecords = res.data.length;
        }
      }
    },
    async addFile() {
      let res;
      try {
        res = await apiServer.post("api/v1/file", {
          fileCode: this.modelFile,
          fileName: this.fileName,
          fileType: this.fileType,
          placeId: this.placeId,
        });
        if (res && res.data) {
          this.hideModalAddFile();
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getFile();
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
    },
    async updateFile() {
      let res;
      try {
        res = await apiServer.put("api/v1/file", {
          id: this.id,
          fileCode: this.fileCode,
          fileName: this.fileName,
          fileType: this.fileType,
          placeId: this.placeId,
        });
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
      if (res && res.data) {
        this.hideModalAddFile();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getFile();
      }
    },
    async deleteFile() {
      let res;
      try {
        const itemId = this.id;
        res = await apiServer.delete("api/v1/file/" + itemId, {});
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 409) {
          alert(error.response.data);
        }
      }
      if (res && res.data) {
        // this.hideModalAddfile();
        this.hideModalDeleteFile();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getFile();
      }
    },
    async getCompany() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-list", {});
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id,
            });
          }
        }
      }
    },
    async getListBranch() {
      let res;
      this.branchs = [];
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {
          company: this.company,
        });
        if (res && res.data) {
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
          this.date = this.dateRange;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getListFileByPlace() {
      let res;
      try {
        res = await apiServer.post("api/v1/file/list", {
          companyId: this.company,
          placeId: this.placeId,
        });
      } catch (error) {}
      if (res && res.data) {
        const arrayData = res.data;
        // this.hanetfiles = [
        //   {
        //     value: "",
        //     text: this.$t("label.all"),
        //   },
        // ];
        for (let i = 0; i < arrayData.length; i++) {
          this.hanetFiles.push({
            value: arrayData[i].fileID,
            text: arrayData[i].fileName,
          });
        }
      } else {
        // this.hanetfiles = [
        //   {
        //     value: "",
        //     text: this.$t("label.all"),
        //   },
        // ];
        // this.isBusy = false;
      }
    },
    changeCompany() {
      this.placeId = null;
      this.getListBranch();
      this.getListFileByPlace();
    },
    changeBranch() {
      this.getListFileByPlace();
    },
    changeCamera() {
      this.FileCode = this.modelFile;
      this.FileName = this.modelFile;
    },
    showAddFile() {
      // (this.id = null), (this.fileCode = null);
      // this.msg = [];
      // this.modelfile = null;
      // this.fileName = null;
      // this.fileType = null;
      this.resetForm();
      this.modalAddFile = true;
      this.isEdit = false;
    },
    editFile(item) {
    this.resetForm();
      this.id = item.id;
      this.modelFile = item.file_code;
      this.fileCode = item.file_code;
      this.fileName = item.file_name;
      this.fileType = item.file_type;
      this.modalAddFile = true;
      this.isEdit = true;
    },
    showDelete(fileId) {
      this.id = fileId;
      this.modalDelete = true;
    },
    resetForm() {
      this.id = null;
      this.modelFile = null;
      this.fileCode = null;
      this.fileName = null;
      this.fileType = null;
      this.msg = [];
    },
    async saveFile() {
      if (this.id == null) {
        await this.addFile();
      } else {
        await this.updateFile();
      }
    },
    hideModalAddFile() {
      this.resetForm();
      this.modalAddFile = false;
    },
    hideModalDeleteFile() {
      this.modalDelete = false;
    },
  },
};
</script>
<style scoped lang="scss">
.dv-search-btn {
  position: absolute;
  bottom: 30px;
}
</style>
