<template>
  <div id="main-default" class="container-fluid " style="padding-top:0px !important">
    <div class="profile">
      <div class="row">
        <div class="col-12 col-md-5">
          <div class="card border-0">
            <div class="profile-item">
              <!-- <div class="profile-item__image"><img :src="userInfo.avatar" :alt="userInfo.fullName"></div> -->
              <div class="profile-item__content">
                <span class="h2 font-weight-bold mb-0">{{userInfo.fullName}}</span>
                  <p class="card-title text-muted mb-0">
                    {{userInfo.company}}
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics">
      <h2 class="mb-3">{{$t('label.monthlyStatistics')}} {{currentMonth}}</h2>
      <div class="row">
        <div class="col-6 col-md-3">
          <div class="card border-0">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8 desc-dashdoard">
                  <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.lateNumber}}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.late") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="clock" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8 desc-dashdoard">
                  <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.offwork}}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.takeLeave") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="calendar-day" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8 desc-dashdoard">
                  <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.leaveRemain}}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.leaveRemain") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="clipboard-list" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8 desc-dashdoard">
                  <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.workNumber}}</span>
                  <p class="card-title text-muted mb-0">
                    {{ $t("label.workShiftNumber") }}
                  </p>
                </div>
                <div class="col-4 icon-dashdoard">
                  <font-awesome-icon icon="calendar-check" style="color:#FB6340;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="workspace mt-4">
      <h2 class="mb-3">{{$t('label.workspaceTitle')}}</h2>
      <div class="row">
        <div class="col-6 col-md-3">
          <div class="card border-0 workspace-item">
            <a href="javascript:void(0)" class="link-from-dashboard" @click="redirectLink('/employee/personnel')" >
              <div class="card-body p-3">

                <div><font-awesome-icon icon="user" style="color:#FB6340;"/></div>
                <p class="h2 font-weight-bold mb-0 mt-4">{{$t('label.personal')}}</p>
                <p class="card-title text-muted mb-0">
                  {{ $t("label.personalInformation") }}
                </p>

              </div>
            </a>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0 workspace-item">
            <a href="javascript:void(0)" class="link-from-dashboard" @click="redirectLink('/employee/checkin')">
              <div class="card-body p-3">
                <div><font-awesome-icon icon="calendar-alt" style="color:#FB6340;" /></div>
                <p class="h2 font-weight-bold mb-0 mt-4">{{$t('label.timekeeping')}}</p>
                <p class="card-title text-muted mb-0">
                  {{ $t("label.workDayDetail") }}
                </p>
              </div>
            </a>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0 workspace-item">
            <a href="javascript:void(0)" class="link-from-dashboard" @click="redirectLink('/employee/leave')">
              <div class="card-body p-3">

                <div><font-awesome-icon icon="calendar-day" style="color:#FB6340;" /></div>
                <p class="h2 font-weight-bold mb-0 mt-4">{{$t('label.takeLeave')}}</p>
                <p class="card-title text-muted mb-0">
                  {{ $t("label.leaveDetails") }}
                </p>

              </div>
            </a>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="card border-0 workspace-item">
            <a  href="javascript:void(0)" class="link-from-dashboard" @click="redirectLink('/employee/in-out-history')">
              <div class="card-body p-3">
                <div><font-awesome-icon icon="list-alt" style="color:#FB6340;" /> </div>
                <p class="h2 font-weight-bold mb-0 mt-4">{{$t('menu.inOutHistory')}}</p>
                <p class="card-title text-muted mb-0">
                  {{ $t("label.inoutDetails") }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>




</template>

<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'
export default {
  data() {
    return {
      dataDashBoard: {},
      currentMonth:'',
      userInfo: [],
    };
  },
  mounted() {
      this.getStatistic();
      this.getNow();
      this.getUser();
  },
  methods:{
    async getStatistic() {
      let res
      try {
        res = await apiServer.get('/api/v1/statistic/by-user', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        let data = res.data;
        for(let i = 0; i < data.length; i++){
            if(data[i].title == "lateNumber") {
              this.dataDashBoard.lateNumber = data[i].countcol;
            } else if (data[i].title == "offwork") {
              this.dataDashBoard.offwork = data[i].countcol;
            } else if (data[i].title == "leaveRemain") {
              this.dataDashBoard.leaveRemain = data[i].countcol;
            } else if (data[i].title == "workNumber") {
              this.dataDashBoard.workNumber = data[i].countcol;
            } else if (data[i].title == "offwork") {
              this.dataDashBoard.offwork = data[i].countcol;
            } else {
              console.log(data[i].countcol);
            }
        }
      }
    },

    async getUser() {
      let res
      try {
        res = await apiServer.get('/api/v1/user/profile', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.userInfo = res.data;
        localStorage.setItem('aliasID', this.userInfo.aliasID);
      }
    },
    async info (id) {
      this.$router.push('/employee/info/' + id)
    },

    getNow() {
        const today = new Date();
        const month = moment(today).format("MM/yyyy");
        this.currentMonth = month;
    },

    redirectLink(link){
        this.$router.replace(link)
    },
  }
}
</script>

<style scoped lang="scss">
  .icon-dashdoard{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 40px;
  }
  .row-sm{
    margin-left: -5px;
    margin-right: -5px;
  }
  .item-grid{
    padding-left: 5px;
    padding-right: 5px;
  }
  .item{
    height: 100%;
    img{
      width: 100%;
      height: 250px;
    }
    .info{
      padding: 10px;
      font-size: 14px;
      label{
        font-weight: bold;
        margin-bottom: 0.2rem;
      }
    }
  }
.card-body{
  padding: .5rem;
}
.workspace-item{
  svg{
    font-size: 50px;
  }
}
.profile-item{
  display: flex;
  padding:15px;
  .profile-item__image{
    width: 60px;
    img{
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
  .profile-item__content{
    width: calc( 100% - 60px );
    padding:5px 15px 0;
    .text-muted{
      line-height: 1;
      margin-top: 7px;
      font-size: 14px;
    }
  }
}









@media screen and (max-width: 767px){
  #main-default.container-fluid, #main-default.container-sm, #main-default.container-md, #main-default.container-lg, #main-default.container-xl{
    padding: 15px 15px 15px 15px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .workspace{
    .row{
      margin: 0 -7.5px;
      .col-6{
        padding:0 7.5px;
        .workspace-item {
          svg{
            font-size: 30px;
          }
          p.h2{
            font-size: 18px;
            margin-top: 15px !important;
          }
          p{
            font-size: 14px;;
          }
        }
        .card{
          margin-bottom: 15px;
        }
      }

    }

  }
  .icon-dashdoard{
    font-size: 30px;
  }
  .desc-dashdoard{
    padding-right: 0;
    .text-muted{
      font-size: 12px;
    }
  }
  .statistics{
    &>.row{
      margin: 0 -7.5px;
      &>.col-6{
        padding: 0 7.5px;
        .card{
          margin-bottom: 15px;
        }
      }
    }
  }


}
</style>
