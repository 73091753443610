<template>
<!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row mt-3">
        <div class="col-xl-12">
        <h1 class="page-title mt-2">
          {{ $t('menu.shiftHistoryManagement') }}
        </h1>
      </div>
      </div>
      <div class="row mt-3">
          <div class="col-xs-12 col-md-6">
              <treeselect v-model="departmentid" :multiple="false" 
              :options="departmentOption" :default-expand-level="Infinity"
              @input="changeDepartment"
              :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
            </div>
      </div>
      <div class="row mt-3">
          <div class=" col-xs-12 col-md-6">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class=" default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    id="table-staff"
                    @row-clicked="rowClickHandler"
                  >
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRecord"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-staff"
                    v-on:click.native="getEmployee()"
                  ></b-pagination>
                </div>
              </div>
            </div>
            <div class=" col-xs-12 col-md-6">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class=" default-table"
                    hover
                    :items="dataTableHistory"
                    :fields="fieldsHisotry"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-shifthistory"
                  >
                    <template #cell(created_date)="data">
                      {{convertDate(data.item.created_date)}}
                    </template>
                    <template #cell(action)="data">
                        <span>{{$t(data.item.action, { name: data.item.shifts_name })}}</span>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTableHistory.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-inouthistory"
                  ></b-pagination>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PulseLoader from '@/components/PulseLoader.vue'

export default {
  components: { Treeselect, PulseLoader },
  data () {
    return {
        loading: false,
        dataTable: [],
        perPage: 25,
        currentPage: 1,
        totalRecord: 0,
      fields: [
        {
          label: this.$t('label.employeeCode'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'fullName',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.jobPosition'),
          key: 'position',
          class: 'align-middle',
          sortable: false
        }
      ],
      dataTableHistory: [],
      fieldsHisotry: [
        {
          label: this.$t('label.dateTime'),
          key: 'created_date',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.action'),
          key: 'action',
          class: 'align-middle',
          sortable: false
        }
        ],
      departmentid: null,
      departmentOption: []
    }
  },
  mounted () {
    this.getDepartment();
    this.getEmployee();
  },
  methods: {
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {})
      } catch (error) {}
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    async getEmployee () {
      this.loading = true;
      let placeID = this.$store.getters.placeID;
      let dpid = (this.departmentid != null && this.departmentid != undefined) ?
      [this.departmentid] : null;
      this.dataTableHistory = [];
      this.dataTable = [];
      let res
      try {
        res = await apiServer.post('api/v1/user/get-list-user', {
          placeID: placeID,
          userName: this.userName,
          department: dpid,
          currentPage: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
          this.dataTable = res.data.data.results;
          this.totalRecord = res.data.data.totalRecord;
          this.loading = false;
      }
    },
    async getShiftHistory(uid){
      this.dataTableHistory = [];
      let res
      try {
        res = await apiServer.post('api/v1/shift/history/get-by-user', {
            userid: uid
        })
      } catch (error) {}
      if (res && res.data) {
        this.dataTableHistory = res.data;
      }
    },
    changeDepartment(){
        setTimeout(() => {this.getEmployee()}, 500);
    },
    convertDate(inputDate) {
      if (inputDate == '' || inputDate == null) return;
      return  moment(inputDate).format("DD/MM/yyyy HH:mm:ss");
    },
    rowClickHandler(record, index) {
      this.getShiftHistory(record.id);
    }
  }
}
</script>