import { render, staticRenderFns } from "./MenuSideBarEmployee.vue?vue&type=template&id=790b4bfe&scoped=true"
import script from "./MenuSideBarEmployee.vue?vue&type=script&lang=js"
export * from "./MenuSideBarEmployee.vue?vue&type=script&lang=js"
import style0 from "./MenuSideBarEmployee.vue?vue&type=style&index=0&id=790b4bfe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790b4bfe",
  null
  
)

export default component.exports