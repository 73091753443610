<template>
    <div class="row">
        <div class="card-body m-0 p-0">
            <div style="display: flex;">
                <div style="margin-left: 10%; text-align: center;">
                    <h4>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h4>
                    <h4>{{ departmentName ? `Đơn vị: ${departmentName}` : '' }}</h4>
                </div>
            </div>

            <div class="table-responsive" style="overflow-x: auto;">
                <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
                    <tr v-for="(row, i) in modifiedPreviewData" :key="i" :class="getClassForRow(row)">
                        <td v-if="i === 0" :colspan="row.length">{{ row[1] }}</td>
                        <template v-else>
                            <td v-for="(cell, j) in row" v-if="shouldRenderCell(row, j)" :key="j"
                                :colspan="getColspanForCell(cell)"
                                :class="getClassForCell(cell)">
                                <span>{{ cell }}</span>
                            </td>
                        </template>
                    </tr>
                </table>
                <div style="display: flex; margin-left: 30%">
                    <div style="margin-left: 40%; text-align: center;">
                        <span>Đà Nẵng, ngày .... tháng .... năm ....</span>
                        <h4>TRƯỞNG ĐƠN VỊ</h4>
                    </div>
                </div>
                <h5>Ghi chú:</h5>
                <p> - Công tác: Đi công tác tại các địa phương khác không thể thực hiện chấm công.</p>
                <p> - Hội họp, Tập huấn: Đi tham gia hội họp, tập huấn tại Đà Nẵng không thể thực hiện chấm công.</p>
                <p> - Làm việc ngoài Công ty: Đi tham gia làm việc bên ngoài Công ty tại Đà Nẵng không thể chấm công đúng thời gian quy định.</p>
                <p> - Bù công: Người lao động được phép bù công theo Quy định của Công ty.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        previewData: {
            type: [Array, Object]
        },
        departmentName: {
            type: String,
        },
    },
    computed: {
        modifiedPreviewData() {
            let modifiedData = JSON.parse(JSON.stringify(this.previewData));
            modifiedData = modifiedData.slice(2, -7);
            return modifiedData;
        }
    },
    data() {
        return {
            maNVCells: [],
            hoTenCells: []
        };
    },
    mounted() {
        this.getMaNVandHoTenCells();
    },
    methods: {
        getClassForRow(row) {
            if (this.hasHoTen(row) || this.hasMaNV(row) || this.hasNgayTinhCong(row)) {
                return { 'bold-text': true };
            }
            return {};
        },
        getClassForCell(row) {
            if (this.hasHoTen(row) || this.hasMaNV(row) || this.hasNgayTinhCong(row)) {
                return { 'left-align': true };
            }
            return {};
        },
        hasMaNV(cell) {
            if (!cell) return false;
            if (cell && cell.includes && cell?.includes('Công tác; Hội họp; Tập huấn; Việc ngoài công ty; Bù công; ...')){
                return true
            }
        },
        hasHoTen(cell) {
            if (!cell) return false;
            if (cell && cell.includes && cell?.includes('Chế độ (Phép; Ốm; Thai sản; Nghỉ việc riêng có lương; Nghỉ việc riêng không lương;...)')){
                return true
            }
        },
        hasNgayTinhCong(cell) {
            if (!cell) return false;
            if (cell && cell.includes && cell?.includes('Các lý do khác')){
                return true
            }
        },
        getMaNVandHoTenCells() {
            this.previewData.forEach(row => {
                row.forEach(cell => {
                    if (typeof cell === 'string') {
                        if (cell?.includes('Mã NV: ')) {
                            this.maNVCells.push(cell);
                        } else if (cell?.includes('Họ và tên: ')) {
                            this.hoTenCells.push(cell);
                        }
                    }
                });
            });
        },
        getColspanForCell(cell) {
            if (typeof cell === 'string' && cell.includes('Công tác; Hội họp; Tập huấn; Việc ngoài công ty; Bù công; ...')) {
                return 4;
            }
            if (typeof cell === 'string' && cell.includes('Chế độ (Phép; Ốm; Thai sản; Nghỉ việc riêng có lương; Nghỉ việc riêng không lương;...)')) {
                return 4;
            }
            if (typeof cell === 'string' && cell.includes('Các lý do khác')) {
                return 4;
            }
            return 1;
        },
        shouldRenderCell(row, index) {
            for (let i = 0; i < row.length; i++) {
                if (typeof row[i] === 'string' && row[i].includes('Công tác; Hội họp; Tập huấn; Việc ngoài công ty; Bù công; ...')) {
                    if (index > i && index <= i + 3) {
                        return false;
                    }
                }
            }
            for (let i = 0; i < row.length; i++) {
                if (typeof row[i] === 'string' && row[i].includes('Chế độ (Phép; Ốm; Thai sản; Nghỉ việc riêng có lương; Nghỉ việc riêng không lương;...)')) {
                    if (index > i && index <= i + 3) {
                        return false;
                    }
                }
            }
            for (let i = 0; i < row.length; i++) {
                if (typeof row[i] === 'string' && row[i].includes('Các lý do khác')) {
                    if (index > i && index <= i + 3) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
}
</script>

<style scoped>
#tbl-preview {
    border: none;
    text-align: center;
}

#tbl-preview tr:first-child td {
    text-align: center;
    font-weight: bold;
    border: none;
    font-size: 18px;
}

#tbl-preview tr:nth-child(2) td {
    text-align: center;
    font-weight: bold;
}

#tbl-preview tr.tbl-footer {
    border: none;
}

#tbl-preview tr.tbl-footer td {
    border: none;
}

.no-border td {
    border: none;
}

.bold-text {
    font-weight: bold;
}

.left-align {
    text-align: left;
}

p{
    font-size: 12px;
}
</style>
