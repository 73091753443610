<template>
  <!-- Sidenav -->
  <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
       @mouseover="mouseoverMenu()"
       @mouseleave="mouseleaveMenu()">
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="javascript:void(0)">
          <img src="../assets/img/brand/logo.png" class="navbar-brand-img" alt="...">
        </a>
        <div class=" ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block active" @click.stop=checkMenu()>
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Nav items -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/admin/dashboard" :class="includeRoute(['admin/dashboard' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="home" size="xs" />
                <span class="nav-link-text">{{ $t('menu.dashboard') }}</span>
              </router-link>
            </li>

            <!-- <li class="nav-item">
              <router-link to="/welcome" class="nav-link">
                <font-awesome-icon icon="tachometer-alt" size="xs" />
                <span class="nav-link-text">{{ $t('menu.welcome') }}</span>
              </router-link>
            </li> -->
            <!-- <li class="nav-item">
              <router-link to="/admin/checkin" :class="includeRoute(['admin/checkin' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="users" size="xs" />
                <span class="nav-link-text">{{ $t('menu.checkIn') }}</span>
              </router-link>
            </li> -->
            
            <!-- li class="nav-item">
              <router-link to="/admin/employee-manager" :class="includeRoute(['admin/employee-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="users" size="xs" />
                <span class="nav-link-text">{{ $t('menu.employeeManager') }}</span>
              </router-link>
            </li -->
            
            <!-- li class="nav-item">
              <router-link to="/admin/leave-application" :class="includeRoute(['admin/leave-application' ], this.$route.path) ? 'nav-link active': 'nav-link'">
              <font-awesome-icon icon="clock" size="xs" />
              <span class="nav-link-text">{{ $t('menu.managementLeave') }}</span>
              </router-link>
            </li -->
            <li class="nav-item">
                <a v-b-toggle.collapse-3 class="nav-link">
                <i class="ni ni-planet text-orange"></i>
                <span class="nav-link-text">{{ $t('menu.timekeepingManagement') }}</span>
                </a>
                <b-collapse id="collapse-3" class="mt-2">
                  <ul id="sub-menu" class="nav nav-sm flex-column">
                    <li class="nav-item" v-if="isSuperAdmin || isComAdmin">
                      <router-link to="/admin/branch-management" :class="includeRoute(['admin/branch-management' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.branchManagement') }}</span>
                      </router-link>
                    </li>     
                    <li class="nav-item"  v-if="userType == 2 || userType == 3">
                      <router-link to="/admin/device-management" :class="includeRoute(['admin/device-management' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.deviceManagement') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" v-if="userType == 2">
                      <router-link to="/admin/attendance-data" :class="includeRoute(['admin/attendance-data' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.attendanceData') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item"  >
                      <router-link to="/admin/in-out-history" :class="includeRoute(['admin/in-out-history' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <!-- <i class="ni ni-planet text-orange"></i> -->
                        <span class="nav-link-text">{{ $t('menu.inOutHistory') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" v-if="userType == 2">
                      <router-link to="/admin/show-list-shift" :class="includeRoute(['admin/show-list-shift' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                          <!-- <font-awesome-icon icon="clock" size="xs" /> -->
                          <span class="nav-link-text">{{ $t('menu.showListShift') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/admin/work-schedule" :class="includeRoute(['admin/work-schedule' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <!-- <font-awesome-icon icon="tachometer-alt" size="xs" /> -->
                        <span class="nav-link-text">{{ $t('menu.workSchedule') }}</span>
                      </router-link>
                    </li>
                   
                  </ul>
                </b-collapse>
            </li>
            <li class="nav-item">
                <a v-b-toggle.collapse-1 class="nav-link">
                  <font-awesome-icon icon="clock" size="xs" />
                  <span class="nav-link-text">{{ $t('menu.timeManagement') }}</span>
                </a>
                <b-collapse id="collapse-1" class="mt-2">
                  <ul id="sub-menu1" class="nav nav-sm flex-column">
                    <li class="nav-item" v-if="isSuperAdmin || isComAdmin">
                      <router-link to="/admin/branch-management" :class="includeRoute(['admin/branch-management' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.branchManagement') }}</span>
                      </router-link>
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="/admin/show-list-shift" :class="includeRoute(['admin/show-list-shift' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                          <font-awesome-icon icon="clock" size="xs" />
                          <span class="nav-link-text">{{ $t('menu.showListShift') }}</span>
                      </router-link>
                    </li> -->
                    <li class="nav-item"  v-if="userType == 2 || userType == 3">
                      <router-link to="/admin/day-off" :class="includeRoute(['admin/day-off' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                          <!-- <font-awesome-icon icon="calendar-check" size="xs" /> -->
                          <span class="nav-link-text">{{ $t('menu.dayOff') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/admin/overtime-application" :class="includeRoute(['admin/overtime-application' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                          <span class="nav-link-text">{{ $t('menu.overTime') }}</span>
                      </router-link>
                    </li>

                      <li class="nav-item">
                        <router-link to="/admin/leave-application" :class="includeRoute(['admin/leave-application' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                          <span class="nav-link-text">{{ $t('menu.managementLeave') }}</span>
                        </router-link>
                      </li>
                    <li class="nav-item">
                      <router-link to="/admin/leave-type" :class="includeRoute(['admin/leave-type' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.leaveTypeManagement') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item" >
                      <router-link to="/admin/leave-manager" :class="includeRoute(['admin/leave-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.leaveManagement') }}</span>
                      </router-link>
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="/admin/file-upload" :class="includeRoute(['admin/file-upload' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.fileUpload') }}</span>
                      </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                      <router-link to="/admin/cancel-meal" :class="includeRoute(['admin/cancel-meal' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.cancelMeal') }}</span>
                      </router-link>
                    </li> -->
                  </ul>
                </b-collapse>
            </li>
            <li class="nav-item">
              <a v-b-toggle.collapse-4 class="nav-link">
                  <font-awesome-icon icon="file-invoice" size="xs" />
                  <span class="nav-link-text">{{ $t('menu.report') }}</span>
                </a>
                <b-collapse id="collapse-4" class="mt-2">
                  <ul id="sub-menu4" class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/admin/attendance-report" :class="includeRoute(['admin/attendance-report' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.reportTimeKeep') }}</span>
                      </router-link>
                    </li>

                    <!-- <li class="nav-item"  v-if="userType == '2' ">
                      <router-link to="/admin/claim-time-sheet-list" :class="includeRoute(['admin/claim-time-sheet-list' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.claimtimesheetlist') }}</span>
                      </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                      <router-link to="/admin/file-upload" :class="includeRoute(['admin/file-upload' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.fileUpload') }}</span>
                      </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                      <router-link to="/admin/cancel-meal" :class="includeRoute(['admin/cancel-meal' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.cancelMeal') }}</span>
                      </router-link>
                    </li> -->
                  </ul>
                </b-collapse>
              <!-- <router-link to="/admin/report-time-keep" :class="includeRoute(['admin/report-time-keep','attendance-report' ], this.$route.path) ? 'nav-link active': 'nav-link'" >
                <font-awesome-icon icon="file-invoice" size="xs" />
                <span class="nav-link-text">{{ $t('menu.reportTimeKeep') }}</span>
              </router-link> -->
            </li>
            <li class="nav-item" >
                <a v-b-toggle.collapse-2 class="nav-link">
                  <font-awesome-icon icon="book" size="xs" />
                  <span class="nav-link-text">{{ $t('menu.systemSettings') }}</span>
                </a>
                <b-collapse id="collapse-2" class="mt-2">
                  <ul id="sub-menu" class="nav nav-sm flex-column">
                    <li class="nav-item" v-if="isSuperAdmin || isComAdmin">
                      <router-link to="/admin/branch-management" :class="includeRoute(['admin/branch-management' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.branchManagement') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item"  v-if="userType == 2">
                      <router-link to="/admin/department-manager" :class="includeRoute(['admin/department-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.departmentManager') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item"  v-if="userType == 2">
                      <router-link to="/admin/position-management" :class="includeRoute(['admin/position-management' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.positionManagement') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/admin/employee-manager" :class="includeRoute(['admin/employee-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.employeeManager') }}</span>
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link to="/admin/device-schedule" :class="includeRoute(['admin/device-schedule' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.deviceSchedule') }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item"  v-if="userType == 2">
                      <router-link to="/admin/finger-manager" :class="includeRoute(['admin/finger-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.fingerManager') }}</span>
                      </router-link>
                    </li>
                    <!-- <li class="nav-item">
                      <router-link to="/admin/cancel-meal" :class="includeRoute(['admin/cancel-meal' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                        <span class="nav-link-text">{{ $t('menu.cancelMeal') }}</span>
                      </router-link>
                    </li> -->
                  </ul>
                </b-collapse>
            </li>

            <li class="nav-item" v-show="isSuperAdmin">
              <router-link to="/admin/company-manager" :class="includeRoute(['admin/company-manager' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                  <font-awesome-icon icon="building" size="xs" />
                  <span class="nav-link-text">{{ $t('menu.companyManager') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { CollapsePlugin } from 'bootstrap-vue'
export default {
  data () {
    return {
      isSuperAdmin: false,
      isComAdmin: false,
      userType: null,
    }
  },
  mounted () {
    this.userType = this.$store.getters.userType
    if(this.$store.getters.superAdmin == true){
      this.isSuperAdmin = true
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
  },
  name: 'MenuSideBar',
  methods: {
    checkMenu () {
      this.$emit('check-menu')
    },
    includeRoute (paths, route) {
      if (paths.includes(route)) {
        return true
      }
      for (const [index, path] of paths.entries()) {
        if (route.match(path)) {
          return true
        }
      }
      return false
    },
    mouseleaveMenu () {
      this.$emit('mouseleave-menu')
    },
    mouseoverMenu () {
      this.$emit('mouseover-menu')
    }
  }
}
</script>
<style lang="scss">
.navbar-inner {
    height: fit-content !important;
    overflow: hidden !important;
}
</style>
<style scoped lang="scss">
.navbar-nav{
  li{
    a{
      cursor:pointer;
      color: #000 !important;
      font-size: 15px !important;
      svg{
        font-size: 15px;
        margin-right: 15px;
        color: #000;
      }
      i{
        color: #000 !important;
      }
      &.active{
        color: #FB6340 !important;
        background: #FFEFEC !important;
        margin: 0rem !important;
        border-radius: 0 !important;
        i{
          color: #FB6340 !important;
        }
        svg{
          color: #FB6340 !important;
        }
      }
      &:hover{
        color: #FB6340 !important;
        background: #FFEFEC !important;
        i{
          color: #FB6340 !important;
        }
        svg{
          color: #FB6340 !important;
        }
      }
    }
  }
}
.g-sidenav-hidden{
  a{
    &.router-link-active{
      display: none !important;
    }
  }
}
#sub-menu , #sub-menu1 , #sub-menu3 ,#sub-menu4{
  li{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #FB6340;
      top: 50%;
      left: 35px;
      z-index: 99;
      transform: translateY(-50%);
    }
    &:first-child{
      a{
        position: relative !important;
      }
    }
  }
}
.g-sidenav-hidden:not(.g-sidenav-pinned) #sub-menu #sub-menu1 #sub-menu3 #sub-menu4 li::before{
    opacity: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active{
    padding-left: 1.5rem;
    padding-right: 1.5rem;

}

.nav-item .nav-link.collapsed:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.6);
  font-variant: normal;
  display: inline-block;
  margin-left: auto;
  content:"";
}
.nav-item .nav-link.not-collapsed:after {
  content:"";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.6);
  display: inline-block;
  margin-left: auto;
}
.overFlow{
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

</style>
