<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-md-6 row" >
          <div class="col-xl-12">
            <h1 class="page-title mt-2">
            Thiết lập máy chấm công
            </h1>
          </div>

          <div class="col-xs-12 col-md-6 mt-3">
            <treeselect v-model="departmentid" :multiple="false" :options="departmentOption"
              :default-expand-level="Infinity" @input="changeDepartment" :noChildrenText="$t('label.noNodeChildren')"
              :placeholder="$t('label.chooseDepartment')" />
          </div>
          <div class="col-xs-12 col-md-6 mt-3">
            <treeselect v-model="hadFinger" :multiple="false" :options="shiftOption" :flat="false"
              :default-expand-level="1" :placeholder="$t('label.filterByuser')" @input="changeDepartment" />
          </div>
          <div class="col-xs-6 col-md-6 mt-3">
            <b-form-input v-model="aliasId" :placeholder="$t('label.employeeCode')" @input="changeDepartment"
              type="text">
            </b-form-input>
          </div>
          <div class="col-xs-6 col-md-6 mt-3">
            <b-form-group>
              <div class="autocomplete" v-click-outside="handleClickOutside">
                <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder="$t('label.fullName')">
                </b-form-input>
                <ul v-show="isOpenNameRes" class="autocomplete-results">
                  <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                    class="autocomplete-result">
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
            </b-form-group>
          </div>

        </div>
        <div class="col-md-6" >
          <h3 class="">Đăng ký vân tay</h3>

          <div class="row ">
            <div class="col-md-12 col-xs-12 mb-2">
              <label>Chọn máy chấm công</label>
              <treeselect v-model="devicesId" :options="shiftsOptions" :placeholder="$t('label.chooseDevice')" />
            </div>
            <div class="col-md-12 col-xs-12">
              <div class="row justify-content-between pt-3">
                <div class="col-md-4">
                  <button @click="getFinger()" class="btn btn-primary btn-block">Vân tay 1</button>
                </div>
                <div class="col-md-4">
                  <button @click="getFinger()" class="btn btn-primary btn-block">Vân tay 2</button>
                </div>
                <div class="col-md-4" v-if="flagRegis">
                  <a href="javascript:void(0)" class="col-md-12 btn btn-danger bg-gradient-danger"
                    @click="addDeviceschedule()"><font-awesome-icon icon="plus" /> Đăng ký </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class=" col-xs-12 col-md-6">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <h2 class="ml-2">Danh sách nhân sự</h2>
              <b-table bordered="bordered" style="max-height: 500px;" class=" default-table" hover :items="dataTable"
                :fields="fields" responsive id="table-staff" @row-clicked="rowClickHandler"
                @head-clicked="handleHeaderClick($event)">
                <!-- <template #head(checkbox)="data">
                  <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                  </b-form-checkbox>
                </template> -->
                <template #cell(checkbox)="data">
                  <b-form-checkbox @click.native.stop :value="data.item" v-model="userSelected">
                  </b-form-checkbox>
                </template>
              </b-table>
              <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right" size="sm"
                class="m-4" aria-controls="table-staff" v-on:click.native="getEmployee()"></b-pagination>
            </div>
          </div>
        </div>
        <div class=" col-xs-12 col-md-6">
          <div class="card">
            <div class="card-body m-0 p-0 table-responsive">
              <h2 class="ml-2">Lịch sử đăng ký {{ nameEmployee }}</h2>
              <b-table bordered="bordered" style="max-height: 500px;" class="default-table" hover
                :items="dataTableHistory" :fields="fieldsHisotry" responsive :current-page="currentPageHistory"
                :per-page="perPage" id="table-shifthistory" show-empty empty-text="Không có dữ liệu"
                @head-clicked="handleHeaderClickDetails($event)">
                <template #cell(type)="data">
                  <p v-if="data.item.type == '0'"> Vân tay</p>
                  <p v-if="data.item.type == '1'"> Khuôn mặt </p>
                </template>
                <template #cell(device_type)="data">
                  <p v-if="data.item.device_type == '3'"> Máy vân tay Vào/Ra</p>
                  <p v-if="data.item.device_type == '4'"> Máy vân tay Vào </p>
                  <p v-if="data.item.device_type == '5'"> Máy vân tay Ra</p>
                </template>
              </b-table>
              <b-pagination v-model="currentPageHistory" :total-rows="dataTableHistory.length"
                :per-page="perPageHistory" align="right" size="sm" class="m-4" aria-controls="table-inouthistory"
                v-on:click.native="getShiftHistory(this.employeeId)"></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>

<script lang="js">
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import PulseLoader from '@/components/PulseLoader.vue'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: { Treeselect, PulseLoader, flatPickr },
  data() {
    return {
      userType: null,
      currentPageHistory: 1,
      perPageHistory: 25,
      loading: false,
      dataTable: [],
      perPage: 25,
      devicesId: null,
      currentPage: 1,
      allSelected: false,
      totalRecord: 0,
      nameEmployee: '',
      fields: [
        {
          label: "",
          key: "checkbox",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t('label.idEmployee'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'fullName',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.jobPosition'),
          key: 'position',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.department'),
          key: 'department',
          class: 'align-middle',
          sortable: false
        }
      ],
      userSelected: null,
      startDateString: '',
      endDateString: '',
      configDateTimePicker: {
        // minDate: "today",
        dateFormat: 'd/m/Y',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true

      },
      configDateTimePickerEnddate: {
        // enableTime: true,

        dateFormat: 'd/m/Y',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true

      },
      nameResults: [],
      fullName: null,
      isOpenNameRes: false,
      hadFinger: null,
      dataTableHistory: [],
      fieldsHisotry: [
        {
          label: "Ngày đăng ký",
          key: 'createdAt',
          class: 'align-middle',
          sortable: false
        },
        {
          label: "Loại",
          key: 'type',
          class: 'align-middle',
          sortable: false
        },
        {
          label: "Thiết bị",
          key: 'deviceCode',
          class: 'align-middle',
          sortable: false
        },
        {
          label: "Loại thiết bị",
          key: 'device_type',
          class: 'align-middle',
          sortable: false
        }
      ],
      departmentid: null,
      shiftid: null,
      aliasId: null,
      flagRegis: false,
      departmentOption: [],
      shiftOption: [{
        "id": null,
        "label": 'Tất cả'
      },
      {
        "id": 0,
        "label": 'Chưa đăng ký'
      },
      {
        "id": 1,
        "label": 'Đã đăng ký'
      }],
      shiftsOptions: [],
      employeeId: null,
    }
  },
  mounted() {
    this.userType = this.$store.getters.userType
    this.getDepartment();
    this.getEmployee();
    this.getShiftoption();
    this.startDateString = this.getNowDate()
    this.endDateString = this.getNowDate()
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {})
      } catch (error) { }
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    async getShiftoption() {
      let res
      try {
        res = await apiServer.post('/api/v1/get-devices', {
          ConnectStatus: 1,
          // currentPage: '',
          // rowPerPage : ''
        })
      } catch (error) { }
      if (res && res.data) {
        this.shiftsOptions = Array.from(new Set(res.data.devices.map(i => ({
          label: i.device_name,
          id: i.device_code
        }))));
      }
    },
    getNowDate() {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    getTomorrowDate() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const dd = String(tomorrow.getDate()).padStart(2, '0');
      const mm = String(tomorrow.getMonth() + 1).padStart(2, '0');
      const yyyy = tomorrow.getFullYear();
      return `${dd}/${mm}/${yyyy}`;
    },
    convertTimestampSendDate(date) {
      if (date == undefined || date == null) {
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },
    handleHeaderClick(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
    handleHeaderClickDetails(column) {
      this.clickedColumn = column;
      console.log('Bạn đã click vào header của cột:', column);
      // Thực hiện các thao tác khi click vào header của cột tương ứng
    },
    async getEmployee() {
      this.loading = true;
      let placeID = this.$store.getters.placeID;
      let dpid = (this.departmentid != null && this.departmentid != undefined) ?
        [this.departmentid] : null;
      this.dataTableHistory = [];
      this.dataTable = [];
      let res
      try {
        res = await apiServer.post('api/v1/user/get-list-user', {
          placeID: placeID,
          aliasID: this.aliasId,
          userName: this.userName,
          department: dpid,
          hadFinger: this.hadFinger,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
          hadAShift: this.shiftid
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        this.totalRecord = res.data.data.totalRecord;
        this.loading = false;
      }
    },
    async getFinger() {

      if (this.devicesId == null || this.devicesId == undefined) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: 'Không được để trống thiết bị',
          position: "bottom-center",
        });
        return false;
      }
      this.loading = true;

      let res;
      try {
        res = await apiServer.post("/api/v1/device/get-lastest-finger", {
          deviceCode: this.devicesId,

        });
      } catch (error) {
        this.loading = false;
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
      }
      if (res && res.data) {
        this.flagRegis = true
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
      this.loading = false;

    },
    async addDeviceschedule() {
      if (this.devicesId == null || this.devicesId == undefined) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: 'Không được để trống thiết bị',
          position: "bottom-center",
        });
        return false;
      }
      if (this.userSelected.length == 0 || this.userSelected == null) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: 'Chưa có nhân viên được chọn',
          position: "bottom-center",
        });
        return false;
      }
      let res;
      this.loading = true;

      try {
        res = await apiServer.post("/api/v1/device/register-finger", {
          deviceCode: this.devicesId,
          aliasID: this.userSelected.aliasID,
          fullName: this.userSelected.fullName
        });
      } catch (error) {
        this.loading = false;
        if (error.response.data.message) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notificationError'),
            text: this.$t(error.response.data.message),
            type: 'error',
            position: 'bottom-center'
          });
        }
      }
      if (res && res.data) {
        this.userSelected = null;
        this.flagRegis = false
        this.devicesId = null
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
      }
      this.loading = false;

    },
    async getShiftHistory(uid) {
      this.dataTableHistory = [];
      let res
      try {
        res = await apiServer.post('api/v1/device/history-register-finger-by-user', {
          aliasID: uid,
          currentPage: this.currentPageHistory,
          perPage: this.perPageHistory
        })
      } catch (error) { }
      if (res && res.data) {
        this.dataTableHistory = res.data.data;
      }
    },
    handleClickOutside() {
      this.isOpenNameRes = false;
    },
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenNameRes = false;
      setTimeout(() => { this.getEmployee() }, 500);
    },
    changeDepartment() {
      setTimeout(() => { this.getEmployee() }, 500);
    },
    convertDate(inputDate) {
      if (inputDate == '' || inputDate == null) return;
      return moment(inputDate).format("DD/MM/yyyy HH:mm:ss");
    },
    rowClickHandler(record, index) {
      this.nameEmployee = record.fullName
      this.employeeId = record.aliasID
      this.getShiftHistory(this.employeeId);
    }
  }
}
</script>