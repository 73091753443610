<template>
  <!-- Page content -->
  <div class="container-fluid" style="padding:0px !important">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <h1 class="page-title m-2">
              {{ $t("menu.inOutHistory") }}
            </h1>
          <div class="card-header border-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-4 col-xs-12 mb-4" v-if="isSupperAdmin || isComAdmin">
                <label>{{ $t('label.branch') }}</label>
                <select v-model="branchid" class="form-control">
                  <option v-for="branch in optionBranch" v-bind:value="branch.value">
                    {{ branch.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 col-xs-12 mb-4">
                <treeselect v-model="department" :multiple="true" :options="dpoptions" :flat="true"
                  :default-expand-level="1" :placeholder="$t('label.chooseDepartment')" />
              </div>
              <!-- <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.nameEmployee')}}, {{$t('label.employeeCode')}}, email</label>
                <input
                  type="text"
                  v-model="namePerson"
                  class="form-control"
                />
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <label>{{$t('label.object')}}</label>
                <select class="form-control" v-model="modelObject">
                  <option
                    v-for="optionsObjectItem in optionsObject"
                    v-bind:value="optionsObjectItem.value"
                  >
                    {{ optionsObjectItem.text }}
                  </option>
                </select>
              </div>-->
              <div class="col-md-4 col-xs-12 ">
                <b-form-group >
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="aliasId" @input="onChangeCode" type="number" :placeholder=" $t('label.employeeCode')">
                  </b-form-input>
                  <ul v-show="isOpenCodeRes" class=" autocomplete-results">
                    <li v-for="(result, i) in codeResults" :key="i" @click="setCodeResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
              </div>
              <div class="col-md-4 col-xs-12 ">
                <b-form-group >
                <div class="autocomplete" v-click-outside="onClickOutside">
                  <b-form-input v-model="fullName" @input="onChangeName" type="text" :placeholder=" $t('label.fullName')">
                  </b-form-input>
                  <ul v-show="isOpenNameRes" class="autocomplete-results">
                    <li v-for="(result, i) in nameResults" :key="i" @click="setNameResult(result)"
                      class="autocomplete-result">
                      {{ result.value }} - {{ result.text }}
                    </li>
                  </ul>
                </div>
              </b-form-group>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <flat-pickr v-model="dateRange" :config="configDateTimePicker" :placeholder="$t('label.selectDateRange')"
                name="date_apply" class="form-control"></flat-pickr>
              </div>
              <div class="col-md-4 col-xs-12 mb-2">
                <select class="form-control" v-model="modelDevice">
                  <option :value="null" disabled selected hidden>
                      <p style="color: #6c757d !important;">Chọn máy chấm công</p>
                  </option>
                  <option v-for="optionsDeviceItem in optionsDevice" v-bind:value="optionsDeviceItem.value">
                    {{ optionsDeviceItem.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-4  col-xs-12 mb-2">
                <b-button variant="btn btn-info btn-outline-primary w-100 " 
                  @click="search()">{{ $t('label.search') }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="number-records d-flex"><p>{{ $t('label.totalRecord') }}: <span class="font-weight-bold">{{ totalRecord }}</span></p>
            <!-- <button class="btn btn-sm btn-primary float-right mr-3 mb-2"
                    @click="exportInOutHistory()">
              <font-awesome-icon icon="download" />
              {{ $t('label.exportReport') }}
            </button> -->
          </div>
          <div class="row " >
            <div class="col-xs-12 col-md-6">
              <h2 >Danh sách nhân viên</h2>
              <div class="card" >
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered" class="default-table" hover :items="dataTable" :fields="fields2"
                    responsive id="table-staff" @row-clicked="rowClickHandler"   @head-clicked="handleHeaderClick($event)"
                    show-empty empty-text="Không có dữ liệu" style="max-height: 500px;">
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="totalRecord" :per-page="perPage" align="right"
                    size="sm" class="m-4" aria-controls="table-staff"
                    v-on:click.native="getCheckinByPlaceIdInTimestamp()"></b-pagination>
                </div>
              </div>
            </div>
            <div class=" col-xs-12 col-md-6">
              <h2>Lịch sử ra vào {{ nameEmployee }}</h2>
              <b-table striped bordered class="default-table" hover :items="dataTableHistory" :fields="fields"
                responsive id="table-inouthistory" :busy="isBusy"  show-empty  @head-clicked="handleHeaderClickDetails($event)"
                      empty-text="Không có dữ liệu" style="max-height: 500px;">

              </b-table>
              <b-pagination v-model="currentPageHistory" :total-rows="totalRecordHistory" :per-page="perPageHistory"
                align="right" size="sm" class="m-4" aria-controls="table-inouthistory"
                v-on:click.native="getShiftHistory(idEmployee)"></b-pagination>
            </div>
          </div>


        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image inouthistory" size="lg">
      <div class="btn-close"><span @click="hideImage()">x</span></div>
      <div class="d-block text-center">
        <img :src="urlImage" style="width:530px;padding:15px" />
      </div>
    </b-modal>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import PulseLoader from '@/components/PulseLoader.vue'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    flatPickr,
    PulseLoader,
    Treeselect
  },
  data() {
    return {
      loading: false,
      isSupperAdmin: false,
      isComAdmin: false,
      urlImage: '',
      modalImage: false,
      department: null,
      dpoptions: [],
      isBusy: true,
      modelObject: '',
      startDateString: '',
      endDateString: '',
      deviceString: '',
      sort: '',
      dateRange: '',
      from_date: null,
      to_date: null,
      fullName: null,
      nowDate: '',
      configDateTimePicker: {
        dateFormat: "d/m/Y",
        mode: "range",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true,
      },
      optionsObject: [
        {
          value: '',

          text: this.$t('label.all')
        },
        {
          value: '0',
          text: this.$t('label.employee')
        },
        {
          value: '1',
          text: this.$t('label.guest')
        },
        {
          value: '4',
          text: this.$t('label.stranger')
        }
      ],
      flag: 'asc',
      dataTableHistory: [],
      modelDevice: null,
      optionsDevice: [
        {
          value: '',
          text: this.$t('label.all')
        },
        {
          value: '0',
          text: this.$t('label.byHandle')
        },

      ],
      branchid: null,
      optionBranch: [],
      nameResults: [],
      codeResults: [],
      dataTable: [],
      isOpenCodeRes: false,
      isOpenNameRes: false,
      totalRecord: 0,
      nameEmployee: '',
      idEmployee: '',
      totalRecordHistory: 0,
      currentPageHistory: 1,
      currentPage: 1,
      perPage: 25,
      aliasId: '',
      perPageHistory: 25,
      placeId: 0,
      fields: [
        {
          label: this.$t('label.device'),
          key: 'deviceName',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'personName',
          class: "align-middle ",
          sortable: false
        },
        // {
        //   label: this.$t('label.inout'),
        //   key: 'inout',
        //   class: "align-middle text-center",
        //   sortable: false
        // },
        {
          label: this.$t('label.time'),
          key: 'checkinTime',
          class: "align-middle",
          sortable: true
        }
      ],
      fields2: [
        {
          label: this.$t('label.idEmployee'),
          key: 'aliasID',
          class: 'align-middle',
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'fullName',
          class: 'align-middle',
          sortable: false
          ,
          formatter: (value) => {
            return value ? value : 'Khách';
          }
        },
        {
          label: this.$t('label.department'),
          key: 'department',
          class: 'align-middle',
          sortable: false,
          formatter: (value) => {
            return value ? value : 'Quản trị viên';
          }
        }
      ],
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      namePerson: '',
    }
  },
  mounted() {
    this.isSupperAdmin = this.$store.getters.superAdmin;
    this.isComAdmin = this.$store.getters.comAdmin;
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getBranch();
    } else {
      // this.getListDeviceByPlace();
    }
    this.getListDeviceByPlace();
    this.getDepartmentTree();
    this.startDateString = this.getNowDate() 
    this.endDateString = this.getNowDate() 
    //this.getCheckinByPlaceIdInTimestamp()
    //this.getShiftHistory()
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    hideImage() {
      this.modalImage = false
    },
    showImage(urlImage) {
      this.urlImage = urlImage
      this.modalImage = true
    },
    async getCheckinByPlaceIdInTimestamp(column) {
      // this.loading = true;
      this.from_date = null;
      this.to_date = null;
      if (this.dateRange !== "") {
        let dateArr = this.dateRange?.split(" ");

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if (dateArr.length == 1) {
          this.to_date = this.from_date;
        } else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      this.nameEmployee = null;
      let res;
      if (this.department && this.department.length == 0) {
        this.department = null;
      }
      if(this.modelDevice == 'Tất cả'){
        this.modelDevice = null
      }
      try {
        this.dataTable = [];
        res = await apiServer.post('api/v1/user/get-list-user', {
          placeID: this.$store.getters.placeID,
          fromdate: this.from_date,
          todate: this.to_date,
          device: this.modelDevice,
          objectType: this.modelObject,
          branchId: this.branchid,
          department: this.department,
          column: column,
          aliasID: this.aliasId,
          userName: this.namePerson,
          currentPage: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        // this.dataTableHistory = res.data.inOutHistory;
        this.totalRecord = res.data.data.totalRecord;
        // this.totalRecordHistory = res.data.totalRecordHistory;
        this.isBusy = false;
        // this.loading = false;
      }
    },
    async getListDeviceByPlace() {
      let res
      try {
        res = await apiServer.post('api/v1/get-devices',
          {
            currentPage: 1,
            rowPerPage : 500

          })
      } catch (error) {
      }
      if (res && res.data) {
        const arrayData = res.data.devices
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          },   
          {
          value: '0',
          text: this.$t('label.byHandle')
        },
        ]
        for (let i = 0; i < arrayData.length; i++) {
          this.deviceString += arrayData[i].deviceID + ','
          this.optionsDevice.push({
            value: arrayData[i].device_code,
            text: arrayData[i].device_name
          })
        }
      } else {
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          },
          {
          value: '0',
          text: this.$t('label.byHandle')
        },
        ]
        this.isBusy = false
      }
    },
    async handleHeaderClick(column) {
      this.loading = true;
      this.nameEmployee = null;
      let res;
      if (this.department && this.department.length == 0) {
        this.department = null;
      }
      if(this.modelDevice == 'Tất cả'){
        this.modelDevice = null
      }
      this.flag = this.flag === 'asc' ? 'desc' : 'asc';
      try {
        this.dataTable = [];
        res = await apiServer.post('api/v1/user/get-list-user', {
          placeID: this.$store.getters.placeID,
          fromdate: this.from_date,
          todate: this.to_date,
          device: this.modelDevice,
          objectType: this.modelObject,
          branchId: this.branchid,
          department: this.department,
          column: column,
          sort: this.flag, 
          aliasID: this.aliasId,
          userName: this.namePerson,
          currentPage: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data.data.results;
        // this.dataTableHistory = res.data.inOutHistory;
        this.totalRecord = res.data.data.totalRecord;
        // this.totalRecordHistory = res.data.totalRecordHistory;
        this.isBusy = false;
        this.loading = false;
      }
    },
    async handleHeaderClickDetails(column) {
      let res;
      this.loading = true;
      this.flag = this.flag === 'asc' ? 'desc' : 'asc';
      this.dataTableHistory = [];
      try {
        res = await apiServer.post('api/v1/camera/get-detail-in-out-history', {
          fromdate: this.from_date,
          todate: this.to_date,
          device: this.modelDevice,
          objectType: this.modelObject,
          branchId: this.branchid,
          department: this.department,
          column: column,
          sort: this.flag, 
          aliasID: this.idEmployee,
          userName: this.namePerson,
          currentPage: this.currentPage,
          rowPerPage: this.perPage
        })
      } catch (error) {
        this.loading = false;
        
      }
      if (res && res.data) {
        if (this.flag === 'desc') {
          this.dataTableHistory = res.data.items.reverse()
        } else {
          this.dataTableHistory = res.data.items

        }
        this.totalRecordHistory = res.data.totalRecord;
        this.loading = false;

      }
    },
    async getBranch() {
      let res
      try {
        res = await apiServer.post('api/v1/company/get-all-branch', {
        })
      } catch (error) { }
      if (res && res.data) {
        this.optionBranch = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.optionBranch.push({
              text: res.data[i].branch_name,
              value: res.data[i].placeID
            })
          }
        }
      }
    },
    async getDepartmentTree() {
      let res
      try {
        res = await apiServer.get('api/v1/department/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.dpoptions = [res.data];
      }
    },
    async getShiftHistory(uid) {
      this.loading = true;

      this.dataTableHistory = [];
      let res
      try {
        res = await apiServer.post('api/v1/camera/get-detail-in-out-history', {
          aliasID: uid,
          department: this.department,
          fromdate: this.from_date,
          todate: this.to_date,
          device: this.modelDevice,
          currentPage: this.currentPageHistory,
          rowPerPage: this.perPageHistory,
        })
      } catch (error) { 
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTableHistory = res.data.items;
        this.totalRecordHistory = res.data.totalRecord
        this.loading = false;
      }
    },
    async exportInOutHistory() {
      this.loading = true;
      let res
      try {
        res = await apiServer.post('api/v1/camera/export-in-out-history', {
          placeID: this.$store.getters.placeID,
          toDate: this.convertTimestampSendDate(this.startDateString),
          fromDate: this.convertTimestampSendDate(this.endDateString),
          device: this.modelDevice,
          objectType: this.modelObject,
          sortTime: this.flag,
          userName: this.namePerson
        }, {
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'in-out-history')
          document.body.appendChild(link)
          link.click();
        });
      } catch (error) {
        this.loading = false;
      }
    },
    search() {
      this.idEmployee = this.aliasId;
   
      this.from_date = null;
        this.to_date = null;
        if (this.dateRange && this.dateRange !== "") {
          let dateArr = this.dateRange?.split(" ");

          this.from_date = this.convertTimestampSendDate(dateArr[0]);
          if (dateArr.length == 1) {
            this.to_date = this.from_date;
          } else {
            this.to_date = this.convertTimestampSendDate(dateArr[2]);
          }
        }
        this.getCheckinByPlaceIdInTimestamp()
        this.getShiftHistory(this.idEmployee)
    },
    async onChangeCode() {
      this.isOpenCodeRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.aliasId,
          searchType: "code"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.codeResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.codeResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    async onChangeName() {
      this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({ value: user.aliasID, text: user.fullName });
          }
        }
      }
    },
    setCodeResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenCodeRes = false;
    },
    setNameResult(result) {
      this.aliasId = result.value;
      this.fullName = result.text;
      this.isOpenNameRes = false;
    },
    onClickOutside() {
      this.isOpenCodeRes = false;
      this.isOpenNameRes = false;
    },
    getNowDate() {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    convertTimestampToDate(timestamp) {
      return moment(timestamp).format('DD/MM/yyyy HH:mm:ss')
    },
    convertTimestampSendDate(date) {
      if (date == undefined || date == null){
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, "$3-$2-$1");
      var date = new Date(changedDate);
      return moment(date).format("yyyy-MM-DD");
    },

    convertDateToTimestamp(date) {
      if (date == null) return ''
      const newDate = new Date(date)
      return newDate.getTime()
    },
    rowClickHandler(record, index) {
      this.nameEmployee = record.fullName
      this.idEmployee = record.aliasID
      this.getShiftHistory(record.aliasID);
    }
  }
}
</script>
<style lang="scss">
.number-records {
  padding-left: 1.25rem;
  padding-bottom: 0.25rem
}

.modal-image {
  img {
    width: 100%;
    height: 100%;
  }
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  z-index: 999;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  min-height: 1em;
  max-height: 18em;
  overflow: auto;
  background: #fff;
}
</style>
