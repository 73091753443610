<template>
  <div class="row">
    <div class="card-body m-0 p-0">
      <div style="display: flex;">
        <div style="margin-left: 5%; text-align: left;">
          <h2>CÔNG TY CỔ PHẦN CAO SU ĐÀ NẴNG</h2>
          <h2>{{ departmentName ? `Đơn vị: ${departmentName}` : '' }}</h2>
        </div>
        <div>
        </div>
      </div>
      <h1 class="text-center text-uppercase">BẢNG CHẤM CÔNG THÁNG {{ month }}/{{ year }}</h1>
      <h2 class="text-center">(Các đơn vị nộp Bảng chấm công về P. Nhân sự tiền lương chậm nhất là ngày 3 hàng tháng)</h2>
      <div class="table-responsive" style="overflow-x: auto;">
        <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
          <tr v-for="(row, i) in modifiedPreviewData" :key="i"
            :class="i >= (previewData.length - 2) ? 'tbl-footer' : ''">
            <!-- <td v-if="i === 0 || i === 1" :colspan="row.length">{{ row[1] }}</td> -->
            <template>
              <td v-for="(cell, j) in row" v-if="j > 0" :key="j" class="max-width-column" style="font-size: 20px;" :rowspan="getRowspan(cell)"
                :colspan="getColspan(cell)">{{ cell }}</td>
            </template>
          </tr>
        </table>
      </div>
      <div>
        <span style="text-decoration: underline;">Các kí hiệu:</span>
        <div style="display: flex;">
          <table class="sign-table" style="margin-right: 5%; width: 12%;">
            <tr v-for="(leaveType, index) in firstHalfLeaveTypes" :key="index">
              <td>{{ leaveType.value }} : </td>
              <td>{{ leaveType.text }}</td>
            </tr>
          </table>
          <table class="sign-table" style="margin-right: 35%; width: 12%;">
            <tr v-for="(leaveType, index) in secondHalfLeaveTypes" :key="index">
              <td>{{ leaveType.value }} : </td>
              <td>{{ leaveType.text }}</td>
            </tr>
          </table>
          <div style="display: flex; width: 60%;">
            <div>
              <h2>PHÒNG NHÂN SỰ TIỀN LƯƠNG</h2>
            </div>
            <div style="margin-left: 13%;">
              <h2>THỦ TRƯỞNG ĐƠN VỊ</h2>
            </div>
            <div style="margin-left: 13%; text-align: center;">
              <h4>Đà Nẵng, ngày .... tháng .... năm ....</h4>
              <h2>NGƯỜI KIỂM TRA</h2>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    previewData: {
      type: [Array, Object]
    },
    departmentName: {
      type: String,
    },
    departmentUnit: {
      type: String,
    },
    day: {
      type: Number,
    },
    month: {
      type: String,
    },
    year: {
      type: Number,
    },
    leaveTypes: {
      type: Array,
    }
  },
  computed: {
    firstHalfLeaveTypes() {
      return this.leaveTypes.slice(0, Math.ceil(this.leaveTypes.length / 2));
    },
    secondHalfLeaveTypes() {
      return this.leaveTypes.slice(Math.ceil(this.leaveTypes.length / 2));
    },
    modifiedPreviewData() {
      console.log(this.leaveTypes)

      const modifiedData = JSON.parse(JSON.stringify(this.previewData));

      modifiedData?.splice(0, 4);
      modifiedData?.splice(-(this.leaveTypes.length/2+3));
      modifiedData.forEach((row, rowIndex) => {
        if (rowIndex === 0) {
          let machineStampFound = false;
          let actualStampFound = false;
          let dayOffFound = false;
          row?.forEach((cell, cellIndex) => {
            if (cell && cell.includes('Máy chấm')) {
              if (!machineStampFound) {
                machineStampFound = true;
              } else {
                modifiedData[rowIndex].splice(cellIndex, 1);
              }
            }
            if (cell && cell.includes('Thực tế')) {
              if (!actualStampFound) {
                actualStampFound = true;
                modifiedData[rowIndex].splice(cellIndex, 1);
              } else {
                modifiedData[rowIndex].splice(cellIndex, 1);
              }
            }
            if (cell && cell.includes('Ngày nghỉ')) {
              if (!dayOffFound) {
                dayOffFound = true;
                modifiedData[rowIndex].splice(cellIndex, 5);
              } else {
                modifiedData[rowIndex].splice(cellIndex, 5);
              }
            }
          });
        }
        if (rowIndex === 1) {
          row.forEach((cell, cellIndex) => {
            if (cellIndex === 0 || cellIndex === 1 || cellIndex === 2 || cellIndex === 3 || cellIndex === 4 || cellIndex === 5) {
              delete modifiedData[rowIndex][cellIndex];
            }
          });
        }
      });

      return modifiedData;
    },

  },
  methods: {
    getClasses(cell, columnIndex, rowIndex) {
      if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ tên') || cell.includes('Tổng công cơm ca') || cell.includes('Hệ số') || cell.includes('HTNV'))) {
        return '';
      }
      return 'max-width-column';
    },
    getRowspan(cell, columnIndex, rowIndex) {
      if (cell && typeof cell === 'string' && (cell.includes('STT') || cell.includes('Mã NV') || cell.includes('Họ tên') || cell.includes('Tổng công cơm ca') || cell.includes('Hệ số') || cell.includes('HTNV'))) {
        return 1;
      }
      return 1;
    },
    getColspan(cell, rowIndex) {
      if (cell && typeof cell === 'string' && (cell === 'Máy chấm' || cell === 'Thực tế')) {
        return 2;
      } else if (cell && typeof cell === 'string' && cell === 'Ngày nghỉ') {
        return 6;
      }
      return 1;
    }
  }
}
</script>
<style scoped>
#tbl-preview {
  border: none;
}

#tbl-preview tr:first-child td {
  text-align: center;
  font-weight: bold;
  /* border: none; */
  /* font-size: 22px; */
}

#tbl-preview tr:nth-child(2) td {
  text-align: center;
  /* font-weight: bold; */
  /* border: none; */
}

#tbl-preview tr:nth-child(2) td:nth-child(-n+5) {
  border-top: none;
}

#tbl-preview tr:nth-child(1) td:nth-child(-n+5) {
  border-bottom: none;
}



/* #tbl-preview tr:nth-child(3) td {
  text-align: center;
  vertical-align: middle
} */

#tbl-preview tr:last-child {
  font-weight: bold;
}

.max-width-column {
  min-width: 10px;
}
</style>