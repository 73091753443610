<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12">
      <div class="col-xl-12 d-flex">
        <div class="col-6">
          <h1 class="page-title mt-2">
            {{ $t("menu.deviceManagement") }}
          </h1>
          <p class="text-muted">
            {{ $t("label.totalDevice") }}: {{ totalRecords }}
          </p>
        </div>
        <div class="col-6 text-right mt-4" v-if="userType == '2'">
          <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger"
            @click="showAddDevice()"><font-awesome-icon icon="plus" /> {{ $t("label.addNew") }}</a>
        </div>
      </div>

      <div class="col-12 row">
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin">
          <b-form-group class="required-mess" :label="$t('label.company')">
            <select v-model="company" class="form-control" @change="changeCompany" required>
              <option v-for="cp in companies" v-bind:value="cp.value">
                {{ cp.text }}
              </option>
            </select>
            <div class="error-message w-100 clear text-left">
              {{ msg["company"] ? $t(msg["company"]) : "" }}
            </div>
          </b-form-group>
        </div>
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin || isComAdmin">
          <b-form-group class="required-mess" :label="$t('label.branch')">
            <select v-model="placeId" class="form-control" :placeholder="$t('label.branch')">
              <option v-for="branch in branchs" v-bind:value="branch.value">
                {{ branch.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-md-3 col-xs-12" v-if="isSupperAdmin || isComAdmin">
          <b-button variant="btn btn-info btn-outline-primary w-100 font-weight-bold dv-search-btn"
            @click="getDevice()">{{ $t("label.search") }}</b-button>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="row mt-3">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body m-0 p-0 table-responsive">
                <b-table striped bordered class="default-table " hover :items="dataTable" :fields="fields" responsive
                  id="table-device " sticky-header style="max-height: 500px;" @head-clicked="handleHeaderClick($event)">
                  <template #cell(device_type)="data">
                    <template v-if="data.item.device_type == 0">
                      {{ $t("label.camCheckinout") }}
                    </template>
                    <template v-else-if="data.item.device_type == 3">
                      {{ $t("label.fingerCheckinout") }}
                    </template>
                    <template v-else-if="data.item.device_type == 4">
                      {{ $t("label.fingerCheckin") }}
                    </template>
                    <template v-else-if="data.item.device_type == 5">
                      {{ $t("label.fingerCheckout") }}
                    </template>
                    <template v-else>
                      {{ $t("label.noData") }}
                    </template>
                  </template>
                  <template #cell(ConnectStatus)="data">
                    <template v-if="data.item.ConnectStatus == 0">
                      <div class="bg-danger text-white text-center mt-1 rounded-lg">
                        <p>Mất kết nối</p>
                      </div>
                    </template>
                    <template v-if="data.item.ConnectStatus == 1">
                      <div class="bg-success text-white text-center mt-1 rounded-lg">
                        <p> Hoạt động </p>
                      </div>
                    </template>
                  </template>
                  <template #cell(button)="data" v-if="userType == '2'">
                    <b-button @click="detailDevice(data.item.device_code)" :title="$t('label.details')"
                      class="btn btn-primary btn-sm" variant="primary" depressed elevation="2"
                      v-b-tooltip.hover><font-awesome-icon icon="info-circle" /></b-button>
                    <b-button @click="editDevice(data.item)" :title="$t('label.edit')" class="btn btn-primary btn-sm"
                      variant="primary" depressed elevation="2" v-b-tooltip.hover><font-awesome-icon
                        icon="edit" /></b-button>
                    <b-button class="btn btn-warning btn-sm" :title="$t('label.delete')" v-b-tooltip.hover
                      @click="showDelete(data.item.id)" depressed elevation="2"><font-awesome-icon
                        icon="trash" /></b-button>
                  </template>
                  <template #empty="scope">
                    {{ $t("notify.message.nodata") }}
                  </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" align="right"
                  size="sm" class="m-4" aria-controls="table-device" v-on:click.native="getDevice()"></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <b-modal v-model="modalAddDevice" ref="modal-add-device" :title="$t('label.addEdit') + ' ' + $t('label.device')"
          header-bg-variant="secondary" hide-footer centered size="lg">
          <div class="row">
            <div class="col-6" v-if="isSupperAdmin">
              <b-form-group class="required-mess" :label="$t('label.company')">
                <select v-model="company" class="form-control" @change="changeCompany" required>
                  <option v-for="cp in companies" v-bind:value="cp.value">
                    {{ cp.text }}
                  </option>
                </select>
                <div class="error-message w-100 clear text-left">
                  {{ msg["company"] ? $t(msg["company"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-6" v-if="isSupperAdmin || isComAdmin">
              <b-form-group class="required-mess" :label="$t('label.branch')">
                <select v-model="placeId" class="form-control" @change="changeBranch">
                  <option v-for="branch in branchs" v-bind:value="branch.value">
                    {{ branch.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-group class="required-mess" :label="$t('label.deviceCode')">
                <b-form-input v-model="deviceCode" class="form-control" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="required-mess" :label="$t('label.deviceName')">
                <b-form-input v-model="deviceName" class="form-control" required></b-form-input>
                <div class="error-message w-100 clear text-left font-weight-normal">
                  {{ msg["deviceName"] ? $t(msg["deviceName"]) : "" }}
                </div>
              </b-form-group>
            </div>

            <!-- <div class="col-xs-12 col-md-6">
              <b-form-group class="required-mess" :label="$t('label.selectDevice')">
                <select class="form-control" v-model="modelDevice" @change="changeCamera" :disabled="isEdit">
                  <option v-for="device in hanetDevices" v-bind:value="device.value">
                    {{ device.text }}
                  </option>
                </select>
                <div class="error-message w-100 clear text-left font-weight-normal">
                  {{ msg["deviceCode"] ? $t(msg["deviceCode"]) : "" }}
                </div>
              </b-form-group>
            </div> -->
            <div class="col-md-6">
              <b-form-group class="required-mess" :label="$t('label.deviceType')">
                <select v-model="deviceType" class="form-control">
                  <option v-bind:value="3">
                    {{ $t("label.fingerCheckinout") }}
                  </option>
                  <option v-bind:value="4">
                    {{ $t("label.fingerCheckin") }}
                  </option>
                  <option v-bind:value="5">
                    {{ $t("label.fingerCheckout") }}
                  </option>
                </select>
                <div class="error-message w-100 clear text-left font-weight-normal">
                  {{ msg["deviceType"] ? $t(msg["deviceType"]) : "" }}
                </div>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group class="" label="Địa chỉ IP">
                <b-form-input v-model="deviceIp" class="form-control" required></b-form-input>
                <div class="error-message w-100 clear text-left font-weight-normal">
                  {{ msg["deviceName"] ? $t(msg["deviceName"]) : "" }}
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="mt-3 text-center">
            <b-button class="btn btn-danger" @click="saveDevice()">{{
              $t("label.save")
            }}</b-button>
            <b-button class="btn btn-default" @click="hideModalAddDevice">{{
              $t("label.cancel")
            }}</b-button>
          </div>
        </b-modal>
      </div>
    </div>
    <b-modal v-model="modalDelete" id="modal-delete" hide-header hide-footer header-bg-variant="secondary"
      modal-class="modal-delete text-center" centered size="md">
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureDevice") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteDevice()">{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('modal-delete')">{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import apiServer from "@/axiosServer";
import moment from "moment";

export default {
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      dataTable: [],
      deviceIp: null,
      fields: [
        {
          label: this.$t("label.deviceCode"),
          key: "device_code",
          tdClass: "text-center",
          thClass: "text-center",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.deviceName"),
          key: "device_name",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.deviceType"),
          key: "device_type",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.status"),
          key: "ConnectStatus",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: "Địa chỉ IP",
          key: "IP",
          class: "align-middle",
          thClass: "text-center",
          sortable: false,
        },
        {
          label: this.$t("label.control"),
          key: "button",
          tdClass: "text-center",
          class: "align-middle",
          thClass: "text-center",
        },
      ],
      currentPage: 1,
      perPage: 25,
      modalAddDevice: false,
      modalDelete: false,
      isEdit: false,
      totalRecords: 0,
      placeId: null,
      companies: [],
      branchs: [],
      id: null,
      deviceCode: null,
      deviceName: null,
      deviceType: null,
      hanetDevices: [],
      modelDevice: null,
      flag: 'asc',
      msg: [],
      userType: null,
    };
  },
  created() {
    this.getDevice();
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    // if (this.isSupperAdmin || this.isComAdmin) {
    // this.getListBranch();
    // } else {
    // this.getListDeviceByPlace();
    // }
  },
  mounted() {
    this.userType = this.$store.getters.userType
  },
  methods: {
    async getDevice(column) {
      let res;
      this.flag === 'asc' ? 'desc' : 'asc';
      try {
        res = await apiServer.post("api/v1/get-devices", {
          placeId: this.placeId,
          currentPage: this.currentPage,
          rowPerPage: this.perPage,
          column: column,
          sort: this.flag,

        });
        if (res && res.data) {
          this.dataTable = res.data.devices;
          this.totalRecords = res.data.totalDevice;
        } else {
          this.dataTable = [];
          this.totalRecords = 0;
        }
      } catch (error) {
        this.dataTable = [];
        this.totalRecords = 0;
      }

    },
    async addDevice() {
      let res;
      try {
        res = await apiServer.post("api/v1/device", {
          deviceCode: this.deviceCode,
          deviceName: this.deviceName,
          deviceType: this.deviceType,
          deviceIP: this.deviceIp,
        });
        if (res && res.data) {
          this.hideModalAddDevice();
          this.$notify({
            group: "bottomnotification",
            title: this.$t("label.notification"),
            text: this.$t(res.data.message),
            position: "bottom-center",
          });
          this.getDevice();
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
    },
    handleHeaderClick(column) {
      this.getDevice(column)
    },

    async updateDevice() {
      let res;
      try {
        res = await apiServer.put("api/v1/device", {
          id: this.id,
          deviceCode: this.deviceCode,
          deviceName: this.deviceName,
          deviceType: this.deviceType,
          deviceIP: this.deviceIp
        });
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        }
        if (error.response.data.message) {
          this.$notify({
            group: "bottomnotification",
            type: "error",
            title: this.$t("label.notificationError"),
            text: this.$t(error.response.data.message),
            position: "bottom-center",
          });
        }
      }
      if (res && res.data) {
        this.hideModalAddDevice();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getDevice();
      }
    },
    async deleteDevice() {
      let res;
      try {
        const itemId = this.id;
        res = await apiServer.delete("api/v1/device/" + itemId, {});
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = error.response.data;
        } else if (error.response.status === 409) {
          alert(error.response.data);
        }
      }
      if (res && res.data) {
        // this.hideModalAddDevice();
        this.hideModalDeleteDevice();
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.getDevice();
      }
    },
    async getCompany() {
      let res;
      try {
        res = await apiServer.post("/api/v1/company/get-list", {});
      } catch (error) { }
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id,
            });
          }
        }
      }
    },
    async getListBranch() {
      let res;
      this.branchs = [];
      try {
        res = await apiServer.post("/api/v1/company/get-all-branch", {
          company: this.company,
        });
        if (res && res.data) {
          for (let br of res.data) {
            this.branchs.push({ value: br.placeID, text: br.branch_name });
          }
          this.date = this.dateRange;
        }
      } catch (e) {
        console.log(e);
      }
    },
    changeCompany() {
      this.placeId = null;
      this.getListBranch();
      this.getListDeviceByPlace();
    },
    changeBranch() {
      this.getListDeviceByPlace();
    },
    changeCamera() {
      this.deviceCode = this.modelDevice;
      this.deviceName = this.modelDevice;
    },
    showAddDevice() {
      // (this.id = null), (this.deviceCode = null);
      // this.msg = [];
      // this.modelDevice = null;
      // this.deviceName = null;
      // this.deviceType = null;
      this.deviceIp = null
      this.resetForm();
      this.modalAddDevice = true;
      this.isEdit = false;
    },
    detailDevice(id) {
      // this.$router.push("/device-management/" + id);
      this.$router.push("/admin/device-management/" + id);

    },
    editDevice(item) {
      this.resetForm();
      this.id = item.id;
      this.modelDevice = item.device_code;
      this.deviceCode = item.device_code;
      this.deviceName = item.device_name;
      this.deviceType = item.device_type;
      this.deviceIp = item.IP
      this.modalAddDevice = true;
      this.isEdit = true;
    },
    showDelete(deviceId) {
      this.id = deviceId;
      this.modalDelete = true;
    },
    resetForm() {
      this.id = null;
      this.modelDevice = null;
      this.deviceCode = null;
      this.deviceName = null;
      this.deviceType = null;
      this.msg = [];
    },
    async saveDevice() {
      if (this.id == null) {
        await this.addDevice();
      } else {
        await this.updateDevice();
      }
    },
    hideModalAddDevice() {
      this.resetForm();
      this.modalAddDevice = false;
    },
    hideModalDeleteDevice() {
      this.modalDelete = false;
    },
  },
};
</script>
<style scoped lang="scss">
.dv-search-btn {
  position: absolute;
  bottom: 30px;
}

.table-container {
  max-height: 510px;
  overflow-y: auto;
}
</style>
