  <template>
    <!-- Topnav -->
    <nav class="navbar navbar-top navbar-expand navbar-dark bg-orange-new border-bottom p-2">
      <div class="container-fluid">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Navbar links -->
          <ul class="navbar-nav align-items-center ml-md-auto">
            <li class="nav-item d-xl-none">
              <!-- Sidenav toggler -->
              <div class="pr-3 sidenav-toggler sidenav-toggler-dark">
                <div class="sidenav-toggler-inner" @click.stop="checkMenu()">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="true" @click="showNotify()" id="navbarDropdownNotify">
                <i class="ni ni-bell-55"><span>{{ countNotify }}</span></i>
              </a>
              <div class="dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden"
                :class="isShowNotify ? 'show' : ''" aria-labelledby="navbarDropdownNotify">
                <!-- Dropdown header -->
                <div class="px-3 py-3">
                  <h6 class="text-sm text-muted m-0">{{ $t('label.notifyHeader', { count: countNotify }) }}</h6>
                </div>
                <!-- List group -->
                <div class="list-group list-group-flush">
                  <a v-for="(item, index) in notifies" @click="readNotify(item)"
                    class="list-group-item list-group-item-action" :class="item.mark_read == 1 ? '' : 'unread'">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <!-- Avatar -->
                        <img alt="" src="../assets/img/icons/common/message.png" class="notify-icon">
                      </div>
                      <div class="col ml--2">
                        <div class="notify-title">
                          <h4 class="mb-0 text-sm">{{ $t(item.title) }}</h4>
                        </div>
                        <div class="justify-content-between align-items-center">
                          <p v-if="item.type == 'ci'" class="text-sm mb-0 short-des">{{ $t(item.short_des,
                            {
                              name: item.aliasID + "_" + item.fullName, data: item.data, date:
                            convertDateToString(item.created_date)})}}</p>
                          <p v-else-if="item.type == 'wl' || item.type == 'wot'">{{ $t(item.short_des,
                            { name: item.data.split(';')[0], data: item.data.split(';')[1] }) }}</p>
                          <p v-else>{{ $t(item.short_des, { name: item.data, data: item.aliasID + "_" + item.fullName }) }}
                          </p>
                          <small>{{ convertDateTimeToString(item.created_date) }}</small>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- View all -->
                <a href="javascript:void(0)" style="display:none"
                  class="dropdown-item text-center text-primary font-weight-bold py-3">{{ $t('label.deleteAll') }}</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link" href="/welcome" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="ni ni-ungroup"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default  dropdown-menu-right ">
                <div class="row shortcuts px-4">
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-red">
                      <i class="ni ni-calendar-grid-58"></i>
                    </span>
                    <small>Calendar</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-orange">
                      <i class="ni ni-email-83"></i>
                    </span>
                    <small>Email</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i class="ni ni-credit-card"></i>
                    </span>
                    <small>Payments</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                      <i class="ni ni-books"></i>
                    </span>
                    <small>Reports</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-purple">
                      <i class="ni ni-pin-3"></i>
                    </span>
                    <small>Maps</small>
                  </a>
                  <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-yellow">
                      <i class="ni ni-basket"></i>
                    </span>
                    <small>Shop</small>
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav align-items-center user-nav-profile  ml-auto ml-md-0 ">
            <li class="nav-item dropdown">
              <a class="nav-link pr-0" href="javascript:void(0)" @click.stop="showProfile()">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="" src="../assets/img/avatarAdmin.png" style="width:36px;height:36px" ng>
                    <!-- <img alt="" :src="$store.getters.avatar" style="width:36px;height:36px"> -->
                  </span>
                  <div class="media-body  ml-2  d-none d-lg-block">
                    <span class="mb-0 text-sm  font-weight-bold" v-if="$store.getters.userType == '3'">{{ $store.getters.department }}</span>
                    <span class="mb-0 text-sm  font-weight-bold" v-else>{{ $store.getters.fullName }}</span>
                  </div>
                </div>
              </a>
              <div :class="profile ? 'dropdown-menu  dropdown-menu-right show' : 'dropdown-menu  dropdown-menu-right'"
                v-click-outside="hideProfile">
                <a v-if="checkUrlContain('/admin')" href="/employee/personnel" class="dropdown-item">
                  <font-awesome-icon icon="user" size="xs" />
                  <span>{{ $t('label.personalPage') }}</span>
                </a>
                <a v-if="checkUrlContain('/employee')" href="/admin/dashboard" class="dropdown-item">
                  <font-awesome-icon icon="user" size="xs" />
                  <span>{{ $t('label.admin') }}</span>
                </a>
                <a href="javascript:void(0)" class="dropdown-item" @click="logout()">
                  <img src="../assets/img/icons/common/logout.png" alt="" style="margin-right: 1rem">
                  <span>{{ $t('label.logout') }}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
<script>
import apiServer from "@/axiosServer";
import { clickOutside } from '@/directive/clickOutside.js';
import moment from "moment";

export default {
  name: 'TopHeaderAdmin',
  directives: {
    clickOutside
  },
  data() {
    return {
      profile: false,
      countNotify: 0,
      isShowNotify: false,
      notifies: [],
      timer: "",
      role: null
    }
  },
  directives: {
    clickOutside
  },
  mounted() {
    this.role = this.$store.getters.role
  },
  created() {
    this.getCountNotify();
    this.timer = setInterval(() => {
      this.getCountNotify();
    }, 60000);
  },
  methods: {
    checkMenu() {
      this.$emit('check-menu')
    },
    showNotify() {
      this.isShowNotify = !this.isShowNotify;
      if (this.isShowNotify == true) {
        this.getNotify();
      }
    },
  
    async getCountNotify() {
      let res;
      try {
        res = await apiServer.get("api/v1/notify/count-all", {
        });
      } catch (error) {
        console.log(error);
      }
      if (res && res.data) {
        if (res.data[0].unread != null) {
          this.countNotify = parseInt(res.data[0].unread);
        }
      }
    },
    async getNotify() {
      let res;
      try {
        res = await apiServer.get("api/v1/notify/get-all", {
        });
      } catch (error) {
        console.log(error);
      }
      if (res && res.data) {
        this.notifies = res.data;
      }
    },
    async readNotify(item) {
      let res;
      try {
        res = await apiServer.post("api/v1/user/read-notify", {
          notify_id: item.id
        });
        if (res && res.data) {
          const linkDetail = this.getLinkDetail(item.type);
          if (this.$route.path !== linkDetail) this.$router.push(linkDetail);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteAllNotify() {
      let res;
      try {
        res = await apiServer.delete("api/v1/user/delete-notify", {
        });
      } catch (error) {
        console.log(error);
      }
      if (res && res.data) {
        this.$notify({
          group: "bottomnotification",
          title: this.$t("label.notification"),
          text: this.$t(res.data.message),
          position: "bottom-center",
        });
        this.isShowNotify = false;
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    showProfile() {
      this.profile = !this.profile
    },
    hideProfile() {
      this.profile = false
    },
    checkUrlContain(text) {
      if (text == "/employee" && this.$store.getters.role != "admin") {
        return false;
      }
      return this.$route.fullPath.startsWith(text);
    },
    getLinkDetail(type) {
      if (type == 'ci') {
        return "/admin/in-out-history";
      } else if (type == 'wl' || type == 'l') {
        return "/admin/leave-application";
      } else if (type == 'wot' || type == 'ot') {
        return "/admin/overtime-application";
      }
    },
    convertDateToString(timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format('DD/MM/yyyy');
    },
    convertDateTimeToString(timestamp) {
      if (timestamp == null || timestamp == undefined) return null;
      return moment(timestamp).format('DD/MM/yyyy HH:mm:ss');
    },
    logout() {
      this.$store.commit('doLogout')
      this.$router.push('/login')
    }
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$mobile-width: 480px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

.main-content .navbar-top {
  position: fixed;
  z-index: 9;

  @include mobile {
    width: 100%;
  }

  top: 0;
  left: 62px;
  right: 0;
}

.navbar-vertical.navbar-expand-xs.fixed-left+.main-content {
  padding-top: 77px;
}

.list-group-flush {
  overflow-y: auto;
  max-height: 400px;
}

.list-group-item {
  padding: 2px 4px;
}

.unread {
  background-color: rgba(247, 118, 96, 0.17) !important;
}

.notify-icon {
  width: 32px;
}

.short-des {
  color: black;
}
</style>
