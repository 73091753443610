<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid" style="padding:0px !important">
    <div class="card col-xl-12 ">
      <div class="row">
        <div class="col-xl-12 mt-2">
          <h1 class="page-title">
            {{ $t('menu.departmentManager') }}
          </h1>
        </div>
        <div class="col-lg-12">
          <vue-tree-list
            @click="onClick"
            @change-name="onChangeName"
            @delete-node="onDel"
            @add-node="onAddNode"
            @drop="onDrop"
            :model="data"
            default-tree-node-name="new node"
            default-leaf-node-name="new leaf"
            v-bind:default-expanded="true"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <span>
                {{ slotProps.model.name }}
              </span>
            </template>
          </vue-tree-list>
        </div>
      </div>
      <div class="col-xl-12">
        <b-modal
      no-close-on-backdrop
      hide-header-close
      v-model="modalAddDep"
      id="modal-add-dep"
      :title="$t('label.addDepartment')"
      hide-footer
      header-bg-variant="secondary"
      centered
      size="md"
    >
      <b-form ref="form">
        <b-form-group
          id="input-group-4"
          :label="$t('label.departmentName')">
          <b-form-input
            v-model="addObj.name"
            type="text"
            class="form-control-sm"
            required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{ msg['name'] ? $t(msg['name']) : ''  }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger"  @click="addDepartment">{{
              $t('label.save')
            }}
          </b-button>
          <b-button class="btn btn-default" @click="cancelAdd">{{
              $t('label.cancel')
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  import apiClient from '@/axios'
  import apiServer from '@/axiosServer'
  export default {
    components: {
      VueTreeList
    },
    data() {
      return {
        newTree: {},
        data: new Tree([{}]),
        modalAddDep: false,
        addObj: {},
        msg: []
      };
    },
    mounted() {
      this.getDepartmentTree();
    },
    methods: {
      async getDepartmentTree(){
        let res
        try {
          res = await apiServer.get('api/v1/department/tree', {})
        } catch (error) {
        }
        if (res && res.data) {
          this.data = new Tree([res.data]);
        }
      },
      // Delete bracnh
      async onDel(node) {
        let res
        try {
          res = await apiServer.post('api/v1/department/delete', {
            id: node.id
          })
        } catch (error) {
        }
        if (res && res.data) {
          node.remove();
          this.getDepartmentTree();
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        }
      },
      // End Delete bracnh

      // Change name bracnh
      async onChangeName(params) {
        if(params.eventType == 'blur'){
          let res
          try {
            res = await apiServer.post('api/v1/department/update', {
              id: params.id,
              name: params.newName,
              description: params.newName
            })
          } catch (error) {
          }
          if (res && res.data) {
            this.$notify({
              group: 'bottomnotification',
              title: this.$t('label.notification'),
              text: this.$t(res.data.message),
              position:'bottom-center'
            });
          }
        }
      },
      // End Change name bracnh

      // Add bracnh
      async onAddNode(params) {
        let parentId = params.parent.id;
        if (!Number.isFinite(parentId)){
          parentId = 0;
        }
        this.addObj = params;
        this.addObj.parentId = parentId;
        this.modalAddDep = true;
        return false;
      },
      async addDepartment() {
        let res
        try {
          res = await apiServer.post('api/v1/department/add', {
            name: this.addObj.name,
            branchId: this.addObj.parent.branch_id,
            description: this.addObj.name,
            parentId: this.addObj.parentId
          })
        } catch (error) {
          if (error.response.status === 400) {
            this.msg = error.response.data;
          }
        }
        if (res && res.data) {
          this.addObj.id = res.data.id;
          this.addObj.addLeafNodeDisabled = true;
          this.modalAddDep = false;
          this.getDepartmentTree();
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        }
      },
      // End Add bracnh
      cancelAdd() {
        this.addObj.remove();
        this.$bvModal.hide('modal-add-dep');
      },
      async onDrop(node){
        const mnode = node.node;
        const target = node.target;
        if (mnode.id == target.id){
          return false;
        }
        let res
        try {
          res = await apiServer.post('api/v1/department/move', {
            id: mnode.id,
            parentId: target.id
          })
        } catch (error) {
        }
        if (res && res.data) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        }
      },

      onClick(params) {
        console.log(params)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .vtl {
    .vtl-drag-disabled {
      background-color: #d0cfcf;
      &:hover {
        background-color: #d0cfcf;
      }
    }
    .vtl-disabled {
      background-color: #d0cfcf;
    }
  }
  .icon {
    &:hover {
      cursor: pointer;
    }
  }

  .muted {
    color: gray;
    font-size: 80%;
  }
</style>
